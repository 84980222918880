import * as React from "react";
import styled from "styled-components";
import { RuleType } from "../../types/RuleType";
import { Input } from "../ui/Input";
import {
  Tier,
  isScheduleIntervalConfiguration,
  isScheduleDailyConfiguration,
  isScheduleTiersConfiguration,
  TierWithId
} from "../../types/Configuration";
import { TiersRulePanel } from "./TierRulePanel";
import { FiniteDurationPanel } from "../ui/FiniteDurationPanel";
import { RulesDetails } from "./AddRulePanel";
import { TierType } from "../../types/TierType";
import { DateField } from "../ui/DateField";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

interface Props {
  rulesDetails: RulesDetails;
  ruleType: RuleType;
  onChange: (key: string, value: any) => void;
  tiers: Array<TierWithId>;
  onUpdateTier: (id: string, key: string, value: any) => void;
  onAddTier: () => void;
  onRemoveTier: (tierId: string) => void;
  onChangeTierType: (id: string, type: TierType) => void;
  disabledButtonAdd: boolean;
}

const BuildRuleDetailPanel: React.FC<Props> = ({
  rulesDetails,
  ruleType,
  onChange,
  tiers,
  onUpdateTier,
  onAddTier,
  onRemoveTier,
  onChangeTierType,
  disabledButtonAdd
}) => {
  const ruleDetails = rulesDetails[ruleType];

  if (isScheduleIntervalConfiguration(ruleDetails)) {
    return (
      <Wrapper>
        <FiniteDurationPanel
          duration={{ length: ruleDetails.interval.length, unit: ruleDetails.interval.unit }}
          onChangeUnit={value => onChange("interval.unit", value)}
          onChangeLength={value => onChange("interval.length", value)}
        />
      </Wrapper>
    );
  }

  if (isScheduleDailyConfiguration(ruleDetails)) {
    return (
      <Wrapper>
        <DateField
          label="Daily at"
          onChange={e => onChange("dailyAt", e.currentTarget.value)}
          value={ruleDetails.dailyAt}
          wrapperStyle={{ width: "50%", paddingRight: 15 }}
          type="time"
        />
      </Wrapper>
    );
  }

  if (isScheduleTiersConfiguration(ruleDetails)) {
    return (
      <TiersRulePanel
        tiers={tiers}
        onAddTier={onAddTier}
        onRemoveTier={onRemoveTier}
        onUpdateTier={onUpdateTier}
        onChangeTierType={onChangeTierType}
        disabledButtonAdd={disabledButtonAdd}
      />
    );
  }

  return <div>Unknown rule type O_o</div>;
};

export { BuildRuleDetailPanel };
