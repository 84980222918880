import * as React from "react";
import client, { mutateData } from "../client";
import moment from "moment";
import { Dialog, DialogableProps } from "./ui/Dialog";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { PublisherRecord } from "../types/Publisher";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { editPublisherError, editPublisherSuccess } from "../messages";
import { trigger } from "swr";
import { useKeyDownEnter } from "../hooks/useKeyDownEnter";
import { DateField } from "./ui/DateField";

type Props = DialogableProps & {
  onSave: () => void;
  selectedPublisher: PublisherRecord;
};

const DialogEditPublisher: React.FC<Props> = ({ open, onClose, onSave, selectedPublisher }) => {
  const formattedPublishedAt = selectedPublisher.publishedAt
    ? moment(selectedPublisher.publishedAt).format("YYYY-MM-DD")
    : null;

  const [description, setDescription] = React.useState<string>(selectedPublisher.description);
  const [publishedAt, setPublishedAt] = React.useState<string | null>(formattedPublishedAt);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const save = async () => {
    const newPublishedAt = publishedAt ? `${publishedAt}T00:00` : null;

    try {
      setLoading(true);
      await mutateData("PUT", "/publishers", {
        ...selectedPublisher,
        description,
        publishedAt: newPublishedAt
      });
      onOpenSnackbar(editPublisherSuccess);
      trigger("/publishers");
    } catch (err) {
      onOpenSnackbar(editPublisherError, "error");
    }

    onSave();
  };

  useKeyDownEnter(save);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleDialog="Edit Publisher"
      buttonSection={
        <>
          <Button onClick={onClose} undo>
            Undo
          </Button>
          <Button onClick={save} style={{ marginLeft: 10 }} loading={loading}>
            Save
          </Button>
        </>
      }
    >
      <Input
        label="description"
        placeholder="Description here"
        value={description}
        onChange={e => setDescription(e.currentTarget.value)}
        wrapperStyle={{ marginBottom: 20 }}
      />
      <DateField
        label="published at"
        onChange={e => {
          setPublishedAt(e.currentTarget.value);
        }}
        value={publishedAt || ""}
        wrapperStyle={{ marginBottom: 30 }}
      />
    </Dialog>
  );
};

export { DialogEditPublisher };
