import * as React from "react";
import moment from "moment";
import { PublisherRecord } from "../types/Publisher";
import { Info } from "../styles";
import styled from "styled-components";

interface Props {
  publisherRecord: PublisherRecord;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const WrapperInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const GridInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, auto));
`;

const TitleKey = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
`;

const PublisherAdditionalInfo: React.FC<Props> = ({ publisherRecord }) => {
  return (
    <Wrapper>
      <div style={{ flexBasis: "50%", marginRight: 8 }}>
        <Info>Last patrol</Info>
        <WrapperInfoColumn>
          {Object.keys(publisherRecord.lastPatrol).map((lastP, i) => (
            <GridInfo key={i}>
              <TitleKey data-text={lastP}>{lastP}</TitleKey>
              <span>{moment(publisherRecord.lastPatrol[lastP]).format("DD/MM/YYYY, HH:mm:ss")}</span>
            </GridInfo>
          ))}
        </WrapperInfoColumn>
      </div>
      <div style={{ flexBasis: "50%", marginLeft: 8 }}>
        <Info>Last update</Info>
        <WrapperInfoColumn>
          {Object.keys(publisherRecord.lastUpdate).map((lastU, i) => (
            <GridInfo key={i}>
              <TitleKey data-text={lastU}>{lastU}</TitleKey>
              <span>{moment(publisherRecord.lastUpdate[lastU]).format("DD/MM/YYYY, HH:mm:ss")}</span>
            </GridInfo>
          ))}
        </WrapperInfoColumn>
      </div>
    </Wrapper>
  );
};

export { PublisherAdditionalInfo };
