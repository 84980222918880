import * as React from "react";
import styled from "styled-components";
import { ApiKeyToken } from "../types/Token";
import { Input } from "./ui/Input";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = ApiKeyToken & {
  updateValue: (key: string, value: any) => void;
};

const ApiKeyTokenEditPanel: React.FC<Props> = ({ apiKey, updateValue }) => {
  return (
    <Wrapper>
      <div style={{ width: "50%", paddingRight: 15 }}>
        <Input
          placeholder="Api key"
          label="api key"
          value={apiKey}
          onChange={(e) => updateValue("apiKey", e.currentTarget.value)}
        />
      </div>
    </Wrapper>
  );
};

export { ApiKeyTokenEditPanel };
