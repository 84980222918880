import * as React from "react";
import { LargePanelWrapper } from "../../../styles";
import { SelectPlaceholder } from "./SelectPlaceholder";
import { TextPlaceholder } from "./Placeholder";

export const AddPanelPlaceholder: React.FC = () => {
  return (
    <LargePanelWrapper style={{ opacity: "20%", display: "block" }}>
      <TextPlaceholder width={60} style={{ marginBottom: 35, marginTop: 5 }} />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingRight: 15 }} />
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingLeft: 15 }} />
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingRight: 15 }} />
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingLeft: 15 }} />
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingRight: 15 }} />
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingLeft: 15 }} />
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingRight: 15 }} />
        <SelectPlaceholder style={{ marginBottom: 25, width: "50%", paddingLeft: 15 }} />
      </div>
    </LargePanelWrapper>
  );
};
