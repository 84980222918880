import * as React from "react";
import { TimeUnit, FiniteDuration } from "../../types/Configuration";
import { SelectStyled } from "./Select";
import { Input } from "./Input";
import { Nullable } from "../../types/Nullable";

interface Props {
  duration: {
    length: number;
    unit: TimeUnit | null;
  };
  onChangeLength: (length: string) => void;
  onChangeUnit: (unit: TimeUnit | null) => void;
  required?: boolean;
}

const FiniteDurationPanel: React.FC<Props> = ({ duration, onChangeLength, onChangeUnit, required }) => {
  const { length, unit } = duration;

  return (
    <>
      <Input
        type="number"
        min="0"
        step="1"
        label="length"
        placeholder="Insert length"
        value={length}
        onChange={(e) => {
          onChangeLength(e.currentTarget.value);
        }}
        wrapperStyle={{ flexBasis: "50%", paddingRight: 15 }}
      />
      <SelectStyled
        required={required}
        label="unit"
        placeholder="Select unit"
        options={Object.values(TimeUnit).map((tu) => ({ label: tu, value: tu }))}
        value={unit && { label: unit, value: unit }}
        onChange={(item) => onChangeUnit(item ? (item.value as TimeUnit) : null)}
        style={{ flexBasis: "50%", paddingLeft: 15 }}
        isClearable
      />
    </>
  );
};

export { FiniteDurationPanel };
