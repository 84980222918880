import * as React from "react";
import { Platform } from "../types/Platform";
import { Order } from "../components/ui/IconOrderBy";

interface PublisherFilterState {
  description: string;
  platform: Platform | null;
  order: Order;
  orderByLabel: string | null;
}

interface Callbacks {
  setDescription: (value: string) => void;
  setPlatform: (value: Platform | null) => void;
  setOrder: (order: Order) => void;
  setOrderByLabel: (orderBy: string | null) => void;
  clearFilter: () => void;
}

const initialState: PublisherFilterState = {
  description: "",
  platform: null,
  order: "asc",
  orderByLabel: "description"
};

const callbacks: Callbacks = {
  setDescription: () => {},
  setPlatform: () => {},
  setOrder: () => {},
  setOrderByLabel: () => {},
  clearFilter: () => {}
};

const PublisherFilterContext = React.createContext<PublisherFilterState & Callbacks>({ ...initialState, ...callbacks });

const PublisherFilterProvider: React.FC = ({ children }) => {
  const [description, setDescription] = React.useState("");
  const [platform, setPlatform] = React.useState<Platform | null>(null);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderByLabel, setOrderByLabel] = React.useState<string | null>("description");

  React.useEffect(() => {
    setOrder("asc");
  }, [orderByLabel]);

  const clearFilter = () => {
    setDescription("");
    setPlatform(null);
    setOrder("asc");
    setOrderByLabel("description");
  };

  return (
    <PublisherFilterContext.Provider
      value={{
        description,
        setDescription,
        platform,
        setPlatform,
        order,
        setOrder,
        orderByLabel,
        setOrderByLabel,
        clearFilter
      }}
    >
      {children}
    </PublisherFilterContext.Provider>
  );
};

export { PublisherFilterContext, PublisherFilterProvider };
