import * as React from "react";
import styled from "styled-components";
import { capitalize } from "lodash";

interface Props {
  label?: string;
  color?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

const Label = styled.span<{ color?: string }>`
  color: ${props => (props.color ? props.color : props.theme.text.dida)};
  padding-right: 8px;
  font-size: 10px;
  font-family: ${props => props.theme.fontFamily.monospace};
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 1px;
`;

const HrStyled = styled.hr<{ color?: string }>`
  width: 100%;
  border: none;
  border-bottom: ${props => (props.color ? `1px solid ${props.color}` : `1px solid ${props.theme.text.dida}`)};
`;

export const Divider: React.FC<Props> = ({ label, labelStyle, color, style }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", ...style }}>
      {label && (
        <Label color={color} style={labelStyle}>
          {capitalize(label)}
        </Label>
      )}
      <HrStyled color={color} />
    </div>
  );
};
