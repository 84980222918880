import * as React from "react";
import styled from "styled-components";
import { Label, LargePanelWrapper, ContentLargePanel } from "../styles";
import { Platform, toStringPlatform } from "../types/Platform";
import { Input } from "./ui/Input";
import { TitleAndClose } from "./ui/TitleAndClose";
import { Chip } from "./ui/Chip";
import { Checkbox } from "./ui/Checkbox";
import { useLocation, useHistory } from "react-router";
import { useKeyUpEsc } from "../hooks/useKeyUpEsc";
import { Button } from "./ui/Button";
import { trigger } from "swr";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { createPublisherSuccess, createPublisherError } from "../messages";
import { useKeyDownEnter } from "../hooks/useKeyDownEnter";
import { DateField } from "./ui/DateField";
import { mutateData } from "../client";

const WrapperChip = styled.div`
  & > div:nth-child(odd) {
    padding-right: 8px;
  }
  & > div:nth-child(even) {
    padding-left: 8px;
  }

  & > div {
    flex-basis: 50%;
    margin-bottom: 10px;
  }

  & > div:last-child {
    margin-bottom: 25px;
  }
`;

const AddPublisherPanel: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const [checkCreateAnother, setCheckCreateAnother] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [publisherId, setPublisherId] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [platform, setPlatform] = React.useState<Platform | null>(null);
  const [publishedAt, setPublishedAt] = React.useState<string>("");

  const onClose = () =>
    history.push({
      pathname: location.pathname
    });

  const create = async () => {
    const newPublisher = {
      publisherId,
      description,
      platform,
      publishedAt: `${publishedAt}T00:00`
    };

    try {
      setLoading(true);

      await mutateData("POST", "/publishers", newPublisher);

      onOpenSnackbar(createPublisherSuccess);
      trigger("/publishers");

      if (checkCreateAnother) {
        setPublisherId("");
        setDescription("");
        setPlatform(null);
        setPublishedAt("");
        setLoading(false);
      } else {
        onClose();
      }
    } catch (err) {
      onOpenSnackbar(createPublisherError, "error");
    }
  };

  useKeyUpEsc(onClose);
  useKeyDownEnter(create);

  const disabledButton = !publisherId || !description || !platform || !publishedAt;

  return (
    <LargePanelWrapper>
      <TitleAndClose title="Create Publisher" onClose={onClose} />
      <ContentLargePanel>
        <div style={{ display: "flex", marginBottom: 25 }}>
          <div style={{ flexBasis: "50%", paddingRight: 15 }}>
            <Input
              label="Publisher id"
              placeholder="Publisher id here"
              value={publisherId}
              onChange={e => setPublisherId(e.currentTarget.value)}
            />
          </div>
          <div style={{ flexBasis: "50%", paddingLeft: 15 }}>
            <Input
              label="description"
              placeholder="Description here"
              value={description}
              onChange={e => setDescription(e.currentTarget.value)}
            />
          </div>
        </div>
        <div data-testid="platform-selection" style={{ display: "flex", flexDirection: "column" }}>
          <Label>Platform</Label>
          <WrapperChip
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap"
            }}
          >
            {Object.keys(Platform).map((p: Platform) => (
              <div key={p}>
                <Chip selected={p === platform} onClick={() => setPlatform(p)}>
                  {toStringPlatform(p)}
                </Chip>
              </div>
            ))}
          </WrapperChip>
        </div>
        <div style={{ display: "flex" }}>
          <DateField
            label="published at"
            onChange={e => {
              setPublishedAt(e.currentTarget.value);
            }}
            value={publishedAt}
            wrapperStyle={{ width: "50%", paddingRight: 15 }}
          />
        </div>
      </ContentLargePanel>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-end",
          position: "fixed",
          bottom: 0,
          marginBottom: 20
        }}
      >
        <Checkbox
          checked={checkCreateAnother}
          onClick={() => setCheckCreateAnother(!checkCreateAnother)}
          label="Create another"
        />
        <Button onClick={create} style={{ marginLeft: 10 }} disabled={disabledButton} loading={loading}>
          Add publisher
        </Button>
      </div>
    </LargePanelWrapper>
  );
};

export { AddPublisherPanel };
