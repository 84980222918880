import { Platform } from "./Platform";
import { RuleType } from "./RuleType";
import { TierType } from "./TierType";

export enum ReqType {
  REALTIME_METRICS = "realtimeMetrics",
  INSIGHTS = "insights",
  CONTENT_DISCOVERY = "contentsDiscovery",
  STORIES_DISCOVERY = "storiesDiscovery",
  CONTENT_DATA = "contentData",
  STORY_DATA = "storyData",
  COMMENTS = "comments",
  CAMPAIGN_DATA = "campaignData",
  ADSET_DATA = "adSetData",
}

export enum TimeUnit {
  NANOSECONDS = "nanoseconds",
  MICROSECONDS = "microseconds",
  MILLISECONDS = "milliseconds",
  SECONDS = "seconds",
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
}

export interface FiniteDuration {
  length: number;
  unit: TimeUnit;
}

// ScheduleConfiguration

export interface ScheduleConfiguration {
  request: ReqType;
}

export interface ScheduleIntervalConfiguration extends ScheduleConfiguration {
  interval: FiniteDuration;
}

export interface ScheduleDailyConfiguration extends ScheduleConfiguration {
  // HH:MM:SS
  dailyAt: string;
}

export interface ScheduleTiersConfiguration extends ScheduleConfiguration {
  tiers: Array<ContentIntervalConfiguration | ContentChunkConfiguration | ContentDailyConfiguration>;
}

// ContentTierConfiguration

export type Tier = ContentIntervalConfiguration | ContentChunkConfiguration | ContentDailyConfiguration;

export type TierWithId = Tier & { id: string };

export interface ContentTierConfiguration {
  contentLifeSince: FiniteDuration;
  contentLifeUntil: FiniteDuration;
}

export interface ContentIntervalConfiguration extends ContentTierConfiguration {
  interval: FiniteDuration;
}

export interface ContentChunkConfiguration extends ContentIntervalConfiguration {
  chunkInterval: FiniteDuration;
  maxAvailableInterval: FiniteDuration | { length: number; unit: null };
}

export interface ContentDailyConfiguration extends ContentTierConfiguration {
  // HH:MM:SS
  dailyAt: string;
  chunkInterval: FiniteDuration;
  maxAvailableInterval: FiniteDuration;
}

// Configuration

export type Rule = ScheduleIntervalConfiguration | ScheduleDailyConfiguration | ScheduleTiersConfiguration;

export type ScheduleTiersConfigurationWithId = ScheduleTiersConfiguration & { id: string };

export type RuleWithId = (
  | ScheduleIntervalConfiguration
  | ScheduleDailyConfiguration
  | ScheduleTiersConfigurationWithId
) & { id: string };

export interface Configuration {
  id: number;
  platform: Platform;
  rules: Array<RuleWithId>;
  publisherId: string;
  active: boolean;
}

// type guard functions for rules of Configuration

export const isScheduleIntervalConfiguration = (arg: any): arg is ScheduleIntervalConfiguration => {
  return "interval" in arg;
};

export const isScheduleDailyConfiguration = (arg: any): arg is ScheduleDailyConfiguration => {
  return "dailyAt" in arg;
};

export const isScheduleTiersConfiguration = (arg: any): arg is ScheduleTiersConfiguration => {
  return "tiers" in arg;
};

// type guard functions for tiers of ScheduleTiersConfiguration

export const isContentIntervalConfiguration = (arg: ContentTierConfiguration): arg is ContentIntervalConfiguration => {
  return "interval" in arg && !("chunkInterval" in arg);
};

export const isContentChunkConfiguration = (arg: ContentTierConfiguration): arg is ContentChunkConfiguration => {
  return "chunkInterval" in arg;
};

export const isContentDailyConfiguration = (arg: ContentTierConfiguration): arg is ContentDailyConfiguration => {
  return "dailyAt" in arg;
};

export const getRequestType = (rule: Rule | RuleWithId) => {
  if (isScheduleIntervalConfiguration(rule)) {
    return RuleType.INTERVAL;
  }

  if (isScheduleDailyConfiguration(rule)) {
    return RuleType.DAILY;
  }

  if (isScheduleTiersConfiguration(rule)) {
    return RuleType.TIER;
  }

  return "Unknown";
};

export const getTierType = (tier: Tier) => {
  if (isContentDailyConfiguration(tier)) {
    return TierType.DAILY;
  }
  if (isContentChunkConfiguration(tier)) {
    return TierType.CHUNK;
  }

  return TierType.INTERVAL;
};
