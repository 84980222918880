import * as React from "react";
import styled from "styled-components";
import { NavLink as L } from "react-router-dom";
import { Logo } from "./ui/Logo";

const Wrapper = styled.div`
  position: relative;
  height: 65px;
  background: ${props => props.theme.background.secondary};
  font-family: ${props => props.theme.fontFamily.monospace};
  font-size: 13px;
  font-weight: 500;
  z-index: 15;
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

const NavLink = styled(L)`
  text-decoration: none;
  color: ${props => props.theme.text.primary};
`;

const ActiveDiv = styled.div<{ left: number }>`
  position: absolute;
  bottom: 0;
  left: ${props => `${props.left}px`};
  width: 40px;
  height: 3px;
  border-radius: 3px;
  background: ${props => props.theme.primary};

  transition: left 0.5s;
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fontFamily.monospace};
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-left: 33px;
  user-select: none;
`;

const Header: React.FC = () => {
  const [left, setLeft] = React.useState<number>(0);

  const onWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = e.target as Element;
    if (el.getAttribute("href")) {
      const { left, width } = el.getBoundingClientRect();
      setLeft(left + width / 2 - 20);
    }
  };

  React.useEffect(() => {
    const initActiveDiv = () => {
      const activeLink = document.querySelector("#header > .active");
      if (activeLink) {
        const { left, width } = activeLink ? activeLink.getBoundingClientRect() : { left: 0, width: 0 };
        setLeft(left + width / 2 - 20);
      }
    };

    initActiveDiv();
    window.addEventListener("resize", initActiveDiv);

    return () => window.removeEventListener("resize", initActiveDiv);
  }, []);

  return (
    <Wrapper onClick={onWrapperClick}>
      <div style={{ display: "flex", alignItems: "center", marginRight: 40 }}>
        <Logo />
        <Title>Scheduler</Title>
      </div>
      <Menu id="header">
        <ActiveDiv left={left} />
        <NavLink exact to="/" activeClassName="active">
          Publisher
        </NavLink>
        <NavLink to="/token" activeClassName="active">
          Token
        </NavLink>
        <NavLink to="/configuration" activeClassName="active">
          Configuration
        </NavLink>
        <NavLink to="/actor-state">Actor state</NavLink>
      </Menu>
    </Wrapper>
  );
};

export { Header };
