import styled from "styled-components";

const IconPlaceholder = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: var(--medium-grey);
  opacity: 50%;
`;

const TextPlaceholder = styled.div<{ width: number }>`
  height: 10px;
  width: ${props => `${props.width}%`};
  background: var(--white);
  border-radius: 5px;
  opacity: 70%;
`;

const Input = styled.div<{ flexBasis?: number }>`
  height: 38px;
  flex-basis: ${props => (props.flexBasis ? `${props.flexBasis}%` : "100%")};
  background: var(--white);
  opacity: 70%;
`;

export { IconPlaceholder, TextPlaceholder, Input };
