import * as React from "react";
import { Label, Title, PanelWrapper } from "../styles";
import { ActiveFilter } from "./ActiveFilter";
import { TokenFilterContext } from "../providers/TokenFilterProvider";
import { SelectStyled } from "./ui/Select";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import useSWR from "swr";
import { PublisherRecord } from "../types/Publisher";
import { fetchData } from "../client";
import { FilterPanelPlaceholder } from "./ui/Placeholder/FilterPanelPlaceholder";
import { toStringTokenType, TokenType } from "../types/Token";
import { orderBy, capitalize } from "lodash";

const TokenFilter: React.FC = () => {
  const {
    active,
    setActive,
    description,
    setDescription,
    publisherId,
    setPublisherId,
    tokenType,
    setTokenType,
    clearFilter,
  } = React.useContext(TokenFilterContext);

  const { data, error } = useSWR<{
    publishers: Array<PublisherRecord>;
  }>("/publishers", fetchData);

  if (error) {
    return <PanelWrapper>Oops, something went wrong!</PanelWrapper>;
  }

  if (!data) {
    return <FilterPanelPlaceholder />;
  }

  const tokenOptions = Object.values(TokenType).map((t) => ({
    value: t,
    label: toStringTokenType(t),
  }));

  const publisherOptions = orderBy(
    data.publishers.map((p) => ({
      value: p.publisherId,
      label: capitalize(p.description),
    })),
    "label"
  );

  return (
    <PanelWrapper>
      <Title>Filter</Title>
      <Input
        label="description"
        placeholder="Search by description"
        value={description}
        onChange={(e) => setDescription(e.currentTarget.value)}
        wrapperStyle={{ marginBottom: 20 }}
      />
      <Label htmlFor="active">Active</Label>
      <ActiveFilter selectedOption={active} setSelectedOption={setActive} />
      <SelectStyled
        label="Token type"
        placeholder="Select token type"
        value={tokenType && { value: tokenType, label: toStringTokenType(tokenType) }}
        onChange={(item: any) => setTokenType(item ? item.value : null)}
        options={tokenOptions}
        style={{ marginTop: 20 }}
        isClearable
      />
      <SelectStyled
        label="Publisher"
        placeholder="Select publisher"
        value={publisherOptions.find((p) => p.value === publisherId) || null}
        onChange={(item: any) => {
          setPublisherId(item ? item.value : null);
        }}
        options={publisherOptions}
        style={{ marginTop: 20 }}
        isClearable
        isSearchable
      />
      <Button style={{ marginTop: 30, alignSelf: "flex-end" }} onClick={clearFilter}>
        Clear filter
      </Button>
    </PanelWrapper>
  );
};
export { TokenFilter };
