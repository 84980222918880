import * as React from "react";
import { colorTheme } from "../../theme";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";

export const Logo: React.FC<{ size?: number }> = ({ size }) => {
  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <svg
      style={{ userSelect: "none" }}
      width={size ? `${size}px` : "40px"}
      height={size ? `${size}px` : "40px"}
      viewBox="0 0 58 58"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Login-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="login" transform="translate(-50.000000, -50.000000)">
          <g id="Group-3" transform="translate(50.000000, 50.000000)">
            <path
              d="M43.7157115,57.8781115 L3.80371151,49.4085115 C1.21171151,48.8565115 -0.444288492,46.3101115 0.105311508,43.7157115 L8.57491151,3.80371151 C9.12691151,1.21171151 11.6733115,-0.444288492 14.2677115,0.105311508 L54.1773115,8.57731151 C56.7717115,9.12691151 58.4277115,11.6757115 57.8781115,14.2677115 L49.4061115,54.1797115 C48.8589115,56.7741115 46.3077115,58.4277115 43.7157115,57.8781115"
              id="Fill-1"
              fill={colorTheme[themeOption].select.background}
            ></path>
            <path
              d="M43.2001915,51.8922715 L4.80019151,51.8922715 C2.15059151,51.8922715 0.000191507593,49.7418715 0.000191507593,47.0922715 L0.000191507593,8.69227151 C0.000191507593,6.04267151 2.15059151,3.89227151 4.80019151,3.89227151 L43.2001915,3.89227151 C45.8497915,3.89227151 48.0001915,6.04267151 48.0001915,8.69227151 L48.0001915,47.0922715 C48.0001915,49.7418715 45.8497915,51.8922715 43.2001915,51.8922715"
              id="Fill-3"
              fill={colorTheme[themeOption].primary}
            ></path>
            <path
              d="M24.0769915,12.6568315 C32.4769915,12.7000315 39.2785915,19.5688315 39.2355962,27.9688315 C39.1921915,36.3448315 32.3641915,43.1272315 24.0001915,43.1272315 L23.9233915,43.1272315 C15.5209915,43.0840315 8.72179151,36.2152315 8.76240884,27.8152315 C8.80819151,19.4392315 15.6337915,12.6568315 23.9977915,12.6568315 L24.0769915,12.6568315 Z M24.0529915,17.4568315 L23.9977915,17.4568315 C21.2305915,17.4568315 18.6265915,18.5272315 16.6561915,20.4760315 C14.6761915,22.4368315 13.5769915,25.0528315 13.5624487,27.8392315 C13.5481915,30.6256315 14.6233915,33.2536315 16.5817915,35.2336315 C18.5449915,37.2136315 21.1585915,38.3128315 23.9473915,38.3272315 L24.0001915,38.3272315 C26.7673915,38.3272315 29.3713915,37.2568315 31.3417915,35.3080315 C33.3217915,33.3496315 34.4209915,30.7336315 34.4354913,27.9472315 C34.4473915,25.1584315 33.3769915,22.5328315 31.4161915,20.5504315 C29.4553915,18.5704315 26.8417915,17.4712315 24.0529915,17.4568315 Z M23.8864315,20.7237115 C24.5512315,20.7261115 25.0864315,21.2661115 25.0816315,21.9285115 L25.0480315,28.1133115 L28.1440315,28.1301115 C28.8064315,28.1325115 29.3416315,28.6749115 29.3368635,29.3349115 C29.3344315,29.9973115 28.7968315,30.5301115 28.1368315,30.5301115 L28.1320315,30.5301115 L23.8360315,30.5085115 C23.5168315,30.5061115 23.2120315,30.3789115 22.9888315,30.1509115 C22.7656315,29.9253115 22.6408315,29.6205115 22.6431975,29.3013115 L22.6816315,21.9165115 C22.6864315,21.2565115 23.2216315,20.7237115 23.8816315,20.7237115 L23.8864315,20.7237115 Z"
              id="Combined-Shape"
              fill={colorTheme[themeOption].select.background}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
