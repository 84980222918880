export enum GraphApiTokenType {
  USER = "USER",
  PAGE = "PAGE",
}

export interface GraphApiToken {
  expiryDate: string | null;
  token: string;
  type: GraphApiTokenType;
  appSecret: string;
  appSecretProof: string;
}

export interface CredentialsToken {
  username: string;
  password: string;
}

export interface GoogleToken {
  expiryDate: string;
  accessToken: string;
  refreshToken: string;
  clientId: string;
  clientSecret: string;
  redirectUri: string;
}

export interface TikTokToken {
  accessToken: string;
  refreshToken: string;
  clientId: string;
  clientSecret: string;
  expiryDate:string;
  expiryRefreshToken?:string;
  
}


export interface TikTokMarketingToken {
  token: string;
  appId: string;
  expiryDate:string;
}

export interface ApiKeyToken {
  apiKey: string;
}

export type AvailableToken = TikTokMarketingToken | GraphApiToken | GoogleToken | CredentialsToken | ApiKeyToken | TikTokToken | TikTokMarketingToken;

export interface TokenRecord {
  id: number;
  description: string;
  active: boolean;
  // formatted date
  lastUsed: string;
  token: AvailableToken;
}

export enum TokenType {
  graph = "graph",
  google = "google",
  credentials = "credentials",
  apiKey = "apiKey",
  tiktok = "tiktok",
  tiktokMarketing="tiktokMarketing"
}

// export type TokenType = "graph" | "google" | "credentials" | "apiKey";

export const stringifiedTokenType = {
  [TokenType.graph]: "Graph api",
  [TokenType.google]: "Google|TikTok api",
  [TokenType.credentials]: "Credentials",
  [TokenType.apiKey]: "Api key",
  [TokenType.tiktok]: "TikTok|Google api ",
  [TokenType.tiktokMarketing]:"TikTok Marketing"
};

export const toStringTokenType = (tokenType: TokenType) => stringifiedTokenType[tokenType];

export const getTokenTypeNameByToken = (token: AvailableToken) => {
  

 
 
  if (isGraphApiToken(token)) {
    return stringifiedTokenType["graph"];
  }
  if (isGoogleApiToken(token)) {
    return stringifiedTokenType["google"];
  }
  if (isCredentialsToken(token)) {
    return stringifiedTokenType["credentials"];
  }
  if (isApiKeyToken(token)) {
    return stringifiedTokenType["apiKey"];
  }
  if (isTikTokToken(token)) {
    return stringifiedTokenType["tiktok"];
  }

  if (isTikTokMarketingToken(token)) {
    return stringifiedTokenType["tiktokMarketing"];
  }
  
};

// type guard functions for token


export const isTikTokMarketingToken = (token: AvailableToken): token is TikTokMarketingToken => {
  return "token" in token;
};

export const isGraphApiToken = (token: AvailableToken): token is GraphApiToken => {
  return "appSecret" in token;
};



export const isTikTokToken = (token: AvailableToken): token is TikTokToken => {
  return "accessToken" in token && "expiryRefreshToken" in token;
};

export const isGoogleApiToken = (token: AvailableToken): token is GoogleToken => {
  return "accessToken" in token;
  
};

export const isCredentialsToken = (token: AvailableToken): token is CredentialsToken => {
  return "password" in token;
};

export const isApiKeyToken = (token: AvailableToken): token is ApiKeyToken => {
  return "apiKey" in token;
};
