import * as React from "react";
import { Platform, toStringPlatform } from "../types/Platform";
import { Title, PanelWrapper } from "../styles";
import { ActorStateFilterContext } from "../providers/ActorStateFilterProvider";
import { SelectStyled } from "../components/ui/Select";
import { Input } from "./ui/Input";
import { Button } from "./ui/Button";

const ActorStateFilter: React.FC = () => {
  const { setDescription, setPlatform, clearFilter, description, platform } = React.useContext(ActorStateFilterContext);

  return (
    <PanelWrapper>
      <Title>Filter</Title>
      <Input
        label="Publisher"
        placeholder="Search by publisher"
        value={description}
        onChange={e => setDescription(e.currentTarget.value)}
        wrapperStyle={{ marginBottom: 20 }}
      />
      <SelectStyled
        label="platform"
        placeholder="Select platform"
        value={platform && { value: platform, label: toStringPlatform(platform) }}
        onChange={(item: any) => setPlatform(item.value)}
        options={Object.keys(Platform).map(p => ({ value: p, label: toStringPlatform(p as Platform) }))}
      />
      <Button style={{ marginTop: 30, alignSelf: "flex-end" }} onClick={clearFilter}>
        Clear filter
      </Button>
    </PanelWrapper>
  );
};

export { ActorStateFilter };
