import * as React from "react";
import { Platform } from "../types/Platform";
import { Option } from "../components/ActiveFilter";
import { TokenType } from "../types/Token";
import { Order } from "../components/ui/IconOrderBy";

interface TokenFilterState {
  description: string;
  active: Option;
  publisherId: string | null;
  tokenType: TokenType | null;
  order: Order;
  orderByLabel: string | null;
}

interface Callbacks {
  setDescription: (value: string) => void;
  setActive: (value: Option) => void;
  setPublisherId: (value: string | null) => void;
  setTokenType: (value: TokenType | null) => void;
  setOrder: (order: Order) => void;
  setOrderByLabel: (orderBy: string | null) => void;
  clearFilter: () => void;
}

const initialState: TokenFilterState = {
  description: "",
  active: "all",
  publisherId: null,
  tokenType: null,
  order: "asc",
  orderByLabel: "id"
};

const callbacks: Callbacks = {
  setDescription: () => {},
  setActive: () => {},
  setPublisherId: () => {},
  setTokenType: () => {},
  setOrder: () => {},
  setOrderByLabel: () => {},
  clearFilter: () => {}
};

const TokenFilterContext = React.createContext<TokenFilterState & Callbacks>({ ...initialState, ...callbacks });

const TokenFilterProvider: React.FC = ({ children }) => {
  const [description, setDescription] = React.useState<string>("");
  const [active, setActive] = React.useState<Option>("all");
  const [publisherId, setPublisherId] = React.useState<string | null>(null);
  const [tokenType, setTokenType] = React.useState<TokenType | null>(null);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderByLabel, setOrderByLabel] = React.useState<string | null>("id");

  React.useEffect(() => {
    setOrder("asc");
  }, [orderByLabel]);

  const clearFilter = () => {
    setDescription("");
    setActive("all");
    setPublisherId(null);
    setTokenType(null);
    setOrder("asc");
    setOrderByLabel("id");
  };

  return (
    <TokenFilterContext.Provider
      value={{
        description,
        setDescription,
        active,
        setActive,
        publisherId,
        setPublisherId,
        tokenType,
        setTokenType,
        order,
        setOrder,
        orderByLabel,
        setOrderByLabel,
        clearFilter
      }}
    >
      {children}
    </TokenFilterContext.Provider>
  );
};

export { TokenFilterContext, TokenFilterProvider };
