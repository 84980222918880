import * as React from "react";
import styled from "styled-components";
import { ReqType, TimeUnit, FiniteDuration, Rule, TierWithId, RuleWithId } from "../../types/Configuration";
import { RuleType } from "../../types/RuleType";
import { ActiveIndicator } from "../ui/ActiveIndicator";
import { Chip } from "../ui/Chip";
import { capitalize, set } from "lodash";
import { BuildRuleDetailPanel } from "./BuildRuleDetailPanel";
import { Button } from "../ui/Button";
import { BackButton } from "../ui/BackButton";
import { scrollbarLightH } from "../ui/ScrollbarStyle";
import { v4 } from "uuid";
import { TierType } from "../../types/TierType";
import { useKeyDownEnter } from "../../hooks/useKeyDownEnter";
import { LargePanelWrapper } from "../../styles";
import { getDisabledButtonAddEditRule } from "../../utilsConfiguration";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";

enum Step {
  SELECT_RULE_TYPE = "selectRuleType",
  SELECT_REQUEST_TYPE = "selectRequestType",
  BUILD_RULE = "buildRule",
}

const labelMap = {
  [Step.SELECT_RULE_TYPE]: "1. Select rule type",
  [Step.SELECT_REQUEST_TYPE]: "2. Select request type",
  [Step.BUILD_RULE]: "3. Build rule",
};

interface Props {
  onAddRule: (rule: Rule) => void;
  close: () => void;
  openFromConfigurationPanel?: boolean;
}

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const ActiveStepLabel = styled.span`
  font-family: ${(props) => props.theme.fontFamily.monospace};
  color: ${(props) => props.theme.primary};
  font-size: 12px;
  font-weight: 500;
`;

const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 0px 20px;
  height: calc(100% - 300px);
  margin-top: 30px;
  overflow-y: overlay;
  overflow-x: hidden;
  ${scrollbarLightH};

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export type IntervalRuleDetails = { id: string; interval: FiniteDuration };
export type DailyRuleDetails = { id: string; dailyAt: string };
export type TierRuleDetails = { id: string; tiers: Array<TierWithId> };

export type RulesDetails = {
  [RuleType.INTERVAL]: IntervalRuleDetails;
  [RuleType.DAILY]: DailyRuleDetails;
  [RuleType.TIER]: TierRuleDetails;
};

const buildRule = (ruleType: RuleType, requestType: ReqType, rulesDetails: RulesDetails) => {
  const ruleDetails = rulesDetails[ruleType];
  const rule = { request: requestType };
  
  return {
    //id:v4(),
    ...rule,
    ...ruleDetails,
  };
 
};

/**
 * This component is composed by three steps:
 * 1. Rule type selection
 * 2. Request type selection
 * 3. Fill main specific rule infos: BuildRuleDetailPanel
 */

interface Props {
  onAddRule: (rule: Rule) => void;
  close: () => void;
  openFromConfigurationPanel?: boolean;
}

const AddRulePanel: React.FC<Props> = ({ onAddRule, close, openFromConfigurationPanel }) => {
  const { themeOption } = React.useContext(ColorThemeContext);

  const [disabledButtonAddRule, setDisabledButtonAddRule] = React.useState(true);

  const [requestType, setRequestType] = React.useState<ReqType | null>(null);
  const [ruleType, setRuleType] = React.useState<RuleType | null>(null);
  const [rulesDetails, setRulesDetails] = React.useState<RulesDetails>({
    [RuleType.INTERVAL]: {
      id: v4(),
      interval: { length: 0, unit: TimeUnit.DAYS },
    },
    [RuleType.DAILY]: {
      id: v4(),
      dailyAt: "",
    },
    [RuleType.TIER]: {
      id: v4(),
      tiers: [
        {
          id: v4(),
          contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
          contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
          interval: { length: 0, unit: TimeUnit.DAYS },
        },
      ],
    },
  });
  const [step, setStep] = React.useState<Step>(Step.SELECT_RULE_TYPE);

  const updateRulesDetails = (key: string, value: any) => {
    if (ruleType) {
      setRulesDetails((rulesDetails) => ({
        ...rulesDetails,
        [ruleType]: { ...set(rulesDetails[ruleType], key, value) },
      }));
    }
  };

  const updateTier = (id: string, key: string, value: any) => {
    setRulesDetails((rulesDetails) => {
      const tiers = rulesDetails.tier.tiers.map((t) => {
        return t.id === id ? { ...set(t, key, value) } : t;
      });
      return { ...rulesDetails, [RuleType.TIER]: { ...rulesDetails[RuleType.TIER], tiers } };
    });
  };

  React.useEffect(() => {
    if (ruleType) {
      const rule = rulesDetails[ruleType] as RuleWithId;
      const disabled = getDisabledButtonAddEditRule(rule);

      setDisabledButtonAddRule(disabled);
    }
  }, [rulesDetails]);

  const addTier = () => {
    setRulesDetails((rulesDetails) => ({
      ...rulesDetails,
      [RuleType.TIER]: {
        ...rulesDetails[RuleType.TIER],
        tiers: [
          ...rulesDetails[RuleType.TIER].tiers,
          {
            id: v4(),
            contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
            contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
            interval: { length: 0, unit: TimeUnit.DAYS },
          },
        ],
      },
    }));
  };

  const removeTier = (tierId: string) => {
    setRulesDetails((rulesDetails) => ({
      ...rulesDetails,
      [RuleType.TIER]: {
        ...rulesDetails[RuleType.TIER],
        tiers: rulesDetails[RuleType.TIER].tiers.filter((t) => t.id !== tierId),
      },
    }));
  };

  const changeTierType = (id: string, type: TierType) => {
    const fn = (tier: TierWithId) => {
      if (id === tier.id) {
        if (type === TierType.DAILY) {
          return {
            id,
            contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
            contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
            chunkInterval: { length: 0, unit: TimeUnit.DAYS },
            dailyAt: "",
            maxAvailableInterval: { length: 0, unit: TimeUnit.DAYS },
          };
        }

        if (type === TierType.CHUNK) {
          return {
            id,
            contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
            contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
            chunkInterval: { length: 0, unit: TimeUnit.DAYS },
            interval: { length: 0, unit: TimeUnit.DAYS },
            maxAvailableInterval: { length: "", unit: "" },
          };
        }

        if (type === TierType.INTERVAL) {
          return {
            id,
            contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
            contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
            interval: { length: 0, unit: TimeUnit.DAYS },
          };
        }
      }

      return tier;
    };

    setRulesDetails((rulesDetails) => {
      const tiers = rulesDetails.tier.tiers.map(fn);
      return { ...rulesDetails, [RuleType.TIER]: { ...rulesDetails[RuleType.TIER], tiers } };
    });
  };

  React.useEffect(() => {
    if (step === Step.BUILD_RULE) {
      setDisabledButtonAddRule(false);
    }
  }, [step]);

  // rule type step
  const ruleChips = step === Step.SELECT_RULE_TYPE && (
    <ChipsWrapper>
      {Object.values(RuleType).map((rt) => (
        <Chip
          key={rt}
          onClick={(_) => {
            setRuleType(rt);
            setRequestType(null);
            setRulesDetails({
              [RuleType.INTERVAL]: {
                id: v4(),
                interval: { length: 0, unit: TimeUnit.DAYS },
              },
              [RuleType.DAILY]: {
                id: v4(),
                dailyAt: "",
              },
              [RuleType.TIER]: {
                id: v4(),
                tiers: [
                  {
                    id: v4(),
                    contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
                    contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
                    interval: { length: 0, unit: TimeUnit.DAYS },
                  },
                ],
              },
            });
            setStep(Step.SELECT_REQUEST_TYPE);
          }}
          selected={ruleType === rt}
        >
          {capitalize(rt)}
        </Chip>
      ))}
    </ChipsWrapper>
  );

  // request type step
  const requestTypeChips = step === Step.SELECT_REQUEST_TYPE && (
    <ChipsWrapper>
      {Object.values(ReqType).map((rt) => (
        <Chip
          key={rt}
          onClick={(_) => {
            setRequestType(rt);
            setStep(Step.BUILD_RULE);
          }}
          selected={Boolean(requestType === rt)}
        >
          {rt}
        </Chip>
      ))}
    </ChipsWrapper>
  );

  const addRule = () => {
    if (ruleType && requestType) {
      onAddRule( buildRule( ruleType, requestType, rulesDetails) );
      setRuleType(null);
      setRequestType(null);
      setRulesDetails({
        [RuleType.INTERVAL]: {
          id: v4(),
          interval: { length: 0, unit: TimeUnit.DAYS },
        },
        [RuleType.DAILY]: {
          id: v4(),
          dailyAt: "",
        },
        [RuleType.TIER]: {
          id: v4(),
          tiers: [
            {
              id: v4(),
              contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
              contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
              interval: { length: 0, unit: TimeUnit.DAYS },
            },
          ],
        },
      });
      close();
    }
  };

  const onClickEnter = () => !disabledButtonAddRule && addRule();
  useKeyDownEnter(onClickEnter);

  const additionalStyle = openFromConfigurationPanel && { zIndex: 25, right: 0, top: 65, marginTop: 3 };

  return (
    <LargePanelWrapper
      style={{
        background: colorTheme[themeOption].background.secondary,
        position: "fixed",
        zIndex: 5,
        ...additionalStyle,
      }}
    >
      <StepsWrapper>
        <div style={{ height: 35, width: "100%", marginBottom: 10 }}>
          {step !== Step.SELECT_RULE_TYPE && (
            <BackButton
              onClick={() => {
                const previousStepIndex = Object.values(Step).indexOf(step) - 1;
                setStep(Object.values(Step)[previousStepIndex]);
              }}
            />
          )}
        </div>
        <ActiveStepLabel>{labelMap[step]}</ActiveStepLabel>
        <div style={{ display: "flex", justifyContent: "space-between", width: 60, marginTop: 15 }}>
          {Object.values(Step).map((s) => (
            <ActiveIndicator key={s} active={s === step} />
          ))}
        </div>
        {ruleChips}
        {requestTypeChips}
        {step === Step.BUILD_RULE && ruleType && (
          <BuildRuleDetailPanel
            ruleType={ruleType}
            rulesDetails={rulesDetails}
            onChange={updateRulesDetails}
            tiers={rulesDetails.tier.tiers}
            onUpdateTier={updateTier}
            onAddTier={addTier}
            onRemoveTier={removeTier}
            onChangeTierType={changeTierType}
            disabledButtonAdd={disabledButtonAddRule}
          />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
            position: "fixed",
            bottom: 0,
            marginBottom: 20,
          }}
        >
          <Button undo onClick={close}>
            Undo
          </Button>
          <Button onClick={addRule} style={{ marginLeft: 10 }} disabled={disabledButtonAddRule}>
            Add rule
          </Button>
        </div>
      </StepsWrapper>
    </LargePanelWrapper>
  );
};

export { AddRulePanel };
