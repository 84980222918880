import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { PublisherActor, PublisherActorWithResponse } from "../../types/Actor";
import { headerTextStyle } from "../../styles";
import { ErrorBadge } from "./ErrorBadge";
import { colorTheme } from "../../theme";

const Wrapper = styled.div`
  border-radius: 12px;
  background: ${props => props.theme.background.primary};
  width: 100%;
  height: auto;
  padding: 20px;
  font-size: 12px;
  position: relative;
`;

const Title = styled.p`
  ${headerTextStyle};
  color: ${props => props.theme.primary};
  margin-bottom: 8px;
`;

const SubTitle = styled.span`
  font-family: ${props => props.theme.fontFamily.monospace};
  font-weight: 500;
`;

const RequestBox: React.FC<{ publisherActorWithResponse: PublisherActorWithResponse }> = ({
  publisherActorWithResponse
}) => {
  return (
    <Wrapper>
      {publisherActorWithResponse.numOfRep > 0 && <ErrorBadge rep={publisherActorWithResponse.numOfRep} />}
      <Title>{publisherActorWithResponse.requestUUID}</Title>
      <div style={{ marginBottom: 8 }}>
        <SubTitle>Request: </SubTitle>
        {publisherActorWithResponse.requestType}
      </div>
      <div style={{ marginBottom: 8 }}>
        <SubTitle>Timestamp: </SubTitle>
        {moment(publisherActorWithResponse.timestamp).format("DD/MM/YYYY, HH:mm:ss")}
      </div>
      <div>
        <SubTitle>Delay: </SubTitle>
        {moment(publisherActorWithResponse.delay).format("HH:mm:ss")}
      </div>
    </Wrapper>
  );
};

export { RequestBox };
