import * as React from "react";
import { v4 } from "uuid";
import { copyTextSuccess } from "../messages";
import { Icon } from "./ui/Icon";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { TitleKey } from "./Details&EditConfiguration/RowDetail";

interface Props {
  text: string;
  textIsVisible?: boolean;
}

const IconCopyText: React.FC<Props> = ({ text, textIsVisible }) => {
  const el = React.useRef<HTMLTextAreaElement>(document.createElement("textarea"));

  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const copyStringToClipboard = () => {
    el.current.value = text;

    document.body.appendChild(el.current);
    // Select text inside element
    el.current.select();
    // Copy text to clipboard
    document.execCommand("copy");

    document.body.removeChild(el.current);

    onOpenSnackbar(copyTextSuccess);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {text && text.length > 0 && textIsVisible && <TitleKey>{text.slice(0, 6)}...</TitleKey>}
      <Icon
        name="clone"
        onClick={() => {
          text.length > 0 && copyStringToClipboard();
        }}
        disabled={!text || text.length === 0}
      />
    </div>
  );
};

IconCopyText.defaultProps = {
  textIsVisible: true
};

export { IconCopyText };
