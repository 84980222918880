import styled, { css } from "styled-components";

const ActiveIndicator = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${props => props.theme.icon.hover};

  ${props =>
    !props.active &&
    css`
      background: ${props => props.theme.icon.normal};
      opacity: 40%;
    `}
`;

export { ActiveIndicator };
