import * as React from "react";
import styled from "styled-components";
import useSWR from "swr";

import { fetchData } from "../../client";
import { SelectStyled } from "../ui/Select";
import { Platform, toStringPlatform } from "../../types/Platform";
import { Toggle } from "../ui/Toggle";
import { Divider } from "../ui/Divider";
import { RuleWithId, Configuration } from "../../types/Configuration";
import { Checkbox } from "../ui/Checkbox";
import { Button } from "../ui/Button";
import { RuleInfoPanel } from "../RuleInfoPanel";
import { ButtonLink } from "../ui/ButtonLink";
import { TitleAndClose } from "../ui/TitleAndClose";
import { PublisherRecord } from "../../types/Publisher";
import { LargePanelWrapper, ContentLargePanel } from "../../styles";
import { AddPanelPlaceholder } from "../ui/Placeholder/AddPanelPlaceholder";
import { orderBy, capitalize, differenceBy } from "lodash";
import { colorTheme } from "../../theme";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { getAdditionalInfo } from "../Details&EditConfiguration/RowDetail";

export const NoRuleInfo = styled.span`
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.text.dida};
  margin-top: 15px;
  margin-bottom: 15px;
`;

interface Props {
  rules: Array<RuleWithId>;
  loadingCreate: boolean;
  onShowAddRule: () => void;
  onCreateConfiguration: (platform: Platform, publisherId: string, active: boolean, createAnother: boolean) => void;
  onRemoveRule: (id: string) => void;
  onEditRule: (id: string) => void;
  onClose: () => void;
}

const AddConfigurationPanel: React.FC<Props> = ({
  rules,
  onShowAddRule,
  onCreateConfiguration,
  loadingCreate,
  onRemoveRule,
  onEditRule,
  onClose,
}) => {
  const { themeOption } = React.useContext(ColorThemeContext);

  const [checkCreateAnother, setCheckCreateAnother] = React.useState<boolean>(false);

  const [platform, setPlatform] = React.useState<Platform | null>(null);
  const [active, setActive] = React.useState<boolean>(true);
  const [publisherId, setPublisherId] = React.useState<string | null>(null);

  const { data: publishersData, error: publishersError } = useSWR<{
    publishers: Array<PublisherRecord>;
  }>("/publishers", fetchData);

  const { data: configurationsData, error: configurationsError } = useSWR<{
    configurations: Array<Configuration>;
  }>("/configurations", fetchData);

  if (publishersError || configurationsError) {
    return <LargePanelWrapper>Oops, something went wrong!</LargePanelWrapper>;
  }

  if (!publishersData || !configurationsData) {
    return <AddPanelPlaceholder />;
  }

  const publishersWithoutConfiguration = differenceBy(
    publishersData.publishers,
    configurationsData.configurations,
    "publisherId"
  );

  const publisherRecords = publishersWithoutConfiguration.filter((p) => {
    let ok = true;

    if (platform !== null) {
      ok = ok && p.platform === platform;
    }

    return ok;
  });

  const publisherOptions = orderBy(
    publisherRecords.map((p) => ({
      value: p.publisherId,
      label: capitalize(p.description),
    })),
    "label"
  );

  const create = () => {
    if (platform && publisherId) {
      onCreateConfiguration(platform, publisherId, active, checkCreateAnother);

      if (checkCreateAnother) {
        setPlatform(null);
        setActive(true);
        setPublisherId(null);
      } else {
        onClose();
      }
    }
  };

  return (
    <LargePanelWrapper>
      <TitleAndClose title="Create Configuration" onClose={onClose} />
      <ContentLargePanel>
        <div style={{ display: "flex" }}>
          <SelectStyled
            label="platform"
            placeholder="Select platform"
            value={platform && { value: platform, label: toStringPlatform(platform) }}
            onChange={(item: any) => {
              setPlatform(item.value);
              setPublisherId(null);
            }}
            options={Object.keys(Platform).map((p) => ({ value: p, label: toStringPlatform(p as Platform) }))}
            style={{ flexBasis: "50%", paddingRight: 15 }}
          />
          <SelectStyled
            label="Publisher"
            placeholder="Select publisher"
            value={publisherOptions.find((p) => p.value === publisherId) || null}
            onChange={(item: any) => {
              setPublisherId(item ? item.value : null);
            }}
            options={publisherOptions}
            style={{ flexBasis: "50%", paddingLeft: 15 }}
            isClearable
            isSearchable
          />
        </div>
        <Toggle
          style={{ marginTop: 20 }}
          label="active"
          checked={active}
          onClick={() => setActive(!active)}
          size="medium"
        />
        <Divider label="Rules" style={{ marginTop: 30, marginBottom: 5 }} />
        {rules.length === 0 && (
          <NoRuleInfo>
            There are no rules here, click "Add rule" to add one or "Add Configuration" if you don't want to add rules
          </NoRuleInfo>
        )}
        {rules.length > 0 && (
          <div style={{ display: "flex", flexDirection: "column", marginTop: 20, marginBottom: 15 }}>
            {rules.map((r, i) => (
              <RuleInfoPanel
                key={i}
                rule={r}
                onDelete={() => onRemoveRule(r.id)}
                onEdit={() => onEditRule(r.id)}
                additionalInfo={getAdditionalInfo(r)}
                widthCalc={140}
              />
            ))}
          </div>
        )}
        <ButtonLink
          iconName="add"
          label="Add rule"
          color={colorTheme[themeOption].text.primary}
          onClick={onShowAddRule}
        />
      </ContentLargePanel>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-end",
          position: "fixed",
          bottom: 0,
          marginBottom: 20,
        }}
      >
        <Checkbox
          checked={checkCreateAnother}
          onClick={() => setCheckCreateAnother(!checkCreateAnother)}
          label="Create another"
        />
        <Button
          onClick={create}
          style={{ marginLeft: 10 }}
          disabled={!publisherId || !platform}
          loading={loadingCreate}
        >
          Add configuration
        </Button>
      </div>
    </LargePanelWrapper>
  );
};

export { AddConfigurationPanel };
