import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { trigger } from "swr";

import client, { mutateData } from "../../client";
import { useKeyUpEsc } from "../../hooks/useKeyUpEsc";
import { RuleWithId, ReqType } from "../../types/Configuration";
import { AddConfigurationPanel } from "./AddConfigurationPanel";
import { AddRulePanel, IntervalRuleDetails, DailyRuleDetails, TierRuleDetails } from "./AddRulePanel";
import { Platform } from "../../types/Platform";
import { SnackbarContext } from "../../providers/SnackbarProvider";
import { createConfigurationSuccess, createConfigurationError } from "../../messages";
import { EditRulePanel } from "./EditRulePanel";
import { serializeRulesRecords } from "../../utilsConfiguration";
import { omit } from "lodash";

const AddConfigurationPanelWrapper: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [rules, setRules] = React.useState<Array<RuleWithId>>([]);
  const [selectedRuleId, setSelectedRuleId] = React.useState<string | null>(null);
  const [showAddRulePanel, setShowAddRulePanel] = React.useState<boolean>(false);
  const [showEditRulePanel, setShowEditRulePanel] = React.useState<boolean>(false);

  const createConfiguration = async (
    platform: Platform,
    publisherId: string,
    active: boolean,
    checkCreateAnother: boolean
  ) => {
    try {
      setLoading(true);

      const configuration = {
        platform,
        publisherId,
        active,
        rules: serializeRulesRecords(rules),
      };

      await mutateData("POST", "/configurations", {
        ...configuration,
      });

      onOpenSnackbar(createConfigurationSuccess);
      trigger("/configurations");

      if (checkCreateAnother) {
        setRules([]);
        setLoading(false);
      } else {
        onClose();
      }
    } catch (err) {
      onOpenSnackbar(createConfigurationError, "error");
    }
  };

  const addRule = (rule: RuleWithId) => setRules((rules) => [rule, ...rules]);

  const onClose = () =>
    history.push({
      pathname: location.pathname,
    });

  useKeyUpEsc(onClose);

  const onRemoveRule = (ruleId: string) => {
    const rulesWithoutRemoved = rules.filter((t) => t.id !== ruleId);
    setRules(rulesWithoutRemoved);
  };

  const onEditRule = (ruleId: string) => {
    setSelectedRuleId(ruleId);
    setShowEditRulePanel(true);
  };

  const onApplyRuleChanges = (
    requestType: ReqType,
    ruleDetails: IntervalRuleDetails | DailyRuleDetails | TierRuleDetails
  ) => {
    const newRules = rules.map((rule) => {
      if (rule.id === ruleDetails.id) {
        return {
          ...rule,
          ...ruleDetails,
          request: requestType,
        };
      }

      return {
        ...rule,
      };
    });

    setRules(newRules);
    setShowEditRulePanel(false);
  };

  const selectedRule = rules.find((r) => r.id === selectedRuleId);

  return (
    <>
      {showEditRulePanel && selectedRule && (
        <EditRulePanel
          onResetRule={(ruleId) => {
            // const resetRule = rules.find((r) => r.id === ruleId);
            // if (resetRule) {
            //   console.log("resetRule", resetRule);
            // }
          }}
          selectedRule={selectedRule}
          close={() => setShowEditRulePanel(false)}
          applyChanges={onApplyRuleChanges}
        />
      )}
      {showAddRulePanel && <AddRulePanel onAddRule={addRule} close={() => setShowAddRulePanel(false)} />}

      <AddConfigurationPanel
        rules={rules}
        onEditRule={onEditRule}
        onRemoveRule={onRemoveRule}
        onShowAddRule={() => setShowAddRulePanel(true)}
        onCreateConfiguration={createConfiguration}
        loadingCreate={loading}
        onClose={onClose}
      />
    </>
  );
};
export { AddConfigurationPanelWrapper };
