import {
  TierWithId,
  isContentDailyConfiguration,
  Rule,
  RuleWithId,
  isScheduleTiersConfiguration,
  isContentChunkConfiguration,
  isContentIntervalConfiguration,
  isScheduleDailyConfiguration,
  FiniteDuration,
  TimeUnit,
  isScheduleIntervalConfiguration,
} from "./types/Configuration";
import { flatMap, omit } from "lodash";
import { v4 } from "uuid";

export const serializeFiniteDuration = (fd: FiniteDuration) => `${fd.length} ${fd.unit}`;

export const conversionToFiniteDuration = (value: any): FiniteDuration => {
  const fd = value.split(" ");
  return { length: Number(fd[0]), unit: fd[1] as TimeUnit };
};

export const getDisabledButtonAddEditRule = (rule: RuleWithId): boolean => {
  let disabledButton: boolean = true;

  if (isScheduleTiersConfiguration(rule)) {
    const validValues = flatMap(
      rule.tiers.map((t) => {
        let tier = omit(t, "id");

        if (isContentDailyConfiguration(tier)) {
          const dailyAtExist = Boolean(tier.dailyAt);
          tier = omit(tier, "dailyAt");
          return [
            ...Object.values(tier).map((tier) => tier.length.toString().length > 0 && Boolean(tier.unit)),
            dailyAtExist,
          ];
        }

        if (isContentChunkConfiguration(tier)) {
          if (!tier.maxAvailableInterval.length && !tier.maxAvailableInterval.unit) {
            tier = omit(tier, "maxAvailableInterval");
          }
          return Object.values(tier).map((tier) => tier.length.toString().length > 0 && Boolean(tier.unit));
        }

        return Object.values(tier).map((tier) => tier.length.toString().length > 0 && Boolean(tier.unit));
      })
    );

    disabledButton = validValues.reduce((acc, next) => {
      return acc && next;
    }, true);
  }

  if (isScheduleDailyConfiguration(rule)) {
    disabledButton = Boolean(rule.dailyAt);
  }

  if (isScheduleIntervalConfiguration(rule)) {
    const intervalRule = omit(rule, "id");
    disabledButton = intervalRule.interval.length.toString().length > 0 && Boolean(intervalRule.interval.unit);
  }

  return !disabledButton;
};

export const serializeRulesRecords = (rules: Array<Rule | RuleWithId>) =>
  rules.map((r) => {
    if (isScheduleTiersConfiguration(r)) {
      return {
        ...r,
        tiers: r.tiers.map((t) => {
          if (isContentDailyConfiguration(t)) {
            return {
              ...t,
              dailyAt: `${t.dailyAt}:00`,
              chunkInterval: serializeFiniteDuration(t.chunkInterval),
              contentLifeSince: serializeFiniteDuration(t.contentLifeSince),
              contentLifeUntil: serializeFiniteDuration(t.contentLifeUntil),
              maxAvailableInterval: serializeFiniteDuration(t.maxAvailableInterval),
            };
          }
          if (isContentChunkConfiguration(t)) {
            const maxAvailableInterval =
              t.maxAvailableInterval.length &&
              t.maxAvailableInterval.unit &&
              serializeFiniteDuration(t.maxAvailableInterval);

            return {
              ...t,
              chunkInterval: serializeFiniteDuration(t.chunkInterval),
              interval: serializeFiniteDuration(t.interval),
              contentLifeSince: serializeFiniteDuration(t.contentLifeSince),
              contentLifeUntil: serializeFiniteDuration(t.contentLifeUntil),
              maxAvailableInterval,
            };
          }
          if (isContentIntervalConfiguration(t)) {
            return {
              ...t,
              interval: serializeFiniteDuration(t.interval),
              contentLifeSince: serializeFiniteDuration(t.contentLifeSince),
              contentLifeUntil: serializeFiniteDuration(t.contentLifeUntil),
            };
          }
        }),
      };
    }

    if (isScheduleDailyConfiguration(r)) {
      return {
        ...r,
        dailyAt: `${r.dailyAt}:00`,
      };
    }

    return { ...r, interval: serializeFiniteDuration(r.interval) };
  });

export const conversionRules = (rules: Array<RuleWithId>): Array<RuleWithId> =>
  rules.map((rule) => {
    if (isScheduleDailyConfiguration(rule)) {
      return {
        ...rule,
        dailyAt: rule.dailyAt.substring(0, rule.dailyAt.length - 3),
      };
    }

    if (isScheduleTiersConfiguration(rule)) {
      return {
        ...rule,
        tiers: rule.tiers.map((t) => {
          if (isContentDailyConfiguration(t)) {
            return {
              ...t,
              dailyAt: t.dailyAt.substring(0, t.dailyAt.length - 3),
              chunkInterval: conversionToFiniteDuration(t.chunkInterval),
              contentLifeSince: conversionToFiniteDuration(t.contentLifeSince),
              contentLifeUntil: conversionToFiniteDuration(t.contentLifeUntil),
              maxAvailableInterval: conversionToFiniteDuration(t.maxAvailableInterval),
            };
          }
          if (isContentChunkConfiguration(t)) {
            const maxAvailableInterval = t.maxAvailableInterval
              ? conversionToFiniteDuration(t.maxAvailableInterval)
              : { length: null, unit: null };

            return {
              ...t,
              chunkInterval: conversionToFiniteDuration(t.chunkInterval),
              interval: conversionToFiniteDuration(t.interval),
              contentLifeSince: conversionToFiniteDuration(t.contentLifeSince),
              contentLifeUntil: conversionToFiniteDuration(t.contentLifeUntil),
              maxAvailableInterval,
            };
          }

          return {
            ...t,
            interval: conversionToFiniteDuration(t.interval),
            contentLifeSince: conversionToFiniteDuration(t.contentLifeSince),
            contentLifeUntil: conversionToFiniteDuration(t.contentLifeUntil),
          };
        }),
      };
    }

    return {
      ...rule,
      interval: conversionToFiniteDuration(rule.interval),
    };
  });
