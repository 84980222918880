import * as React from "react";
import styled from "styled-components";

import { Tier, getTierType, RuleWithId, isScheduleTiersConfiguration, TierWithId } from "../../types/Configuration";
import { Label } from "../../styles";
import { Icon } from "../ui/Icon";
import { scrollbarLightH } from "../ui/ScrollbarStyle";
import { TierType } from "../../types/TierType";
import { Chip } from "../ui/Chip";
import { capitalize } from "lodash";
import { getTierEditPanel } from "./TierRulePanel";
import { ButtonLink } from "../ui/ButtonLink";
import { Divider } from "../ui/Divider";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  ${scrollbarLightH};
`;

interface TierPanelProps {
  tier: TierWithId;
  removeIcon: boolean;
  onRemove: () => void;
  onUpdate: (id: string, key: string, value: any) => void;
  onChangeType: (id: string, type: TierType) => void;
  disabledChangeTierType: boolean;
}

const TierPanel: React.FC<TierPanelProps> = ({
  tier,
  removeIcon,
  onRemove,
  onUpdate,
  onChangeType,
  disabledChangeTierType,
}) => {
  const tierType = getTierType(tier);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: 30 }}>
        <Label style={{ margin: 0 }}>Tier type</Label>
        {removeIcon && <Icon name="remove" onClick={onRemove} style={{ right: -8, bottom: 2 }} />}
      </div>
      <div style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }}>
        {Object.values(TierType).map((tt) => (
          <Chip
            key={tt}
            selected={tt === tierType}
            onClick={(_) => !disabledChangeTierType && onChangeType(tier.id, tt)}
            disabled={disabledChangeTierType}
          >
            {capitalize(tt)}
          </Chip>
        ))}
      </div>
      {getTierEditPanel(tier, onUpdate)}
    </div>
  );
};

interface Props {
  tiers: Array<TierWithId>;
  selectedRule: RuleWithId;
  onAddTier: () => void;
  onRemoveTier: (tierId: string) => void;
  onUpdateTier: (id: string, key: string, value: any) => void;
  onChangeTierType: (id: string, type: TierType) => void;
  disabledButtonAdd: boolean;
}

const EditTiersRulePanel: React.FC<Props> = ({
  tiers,
  selectedRule,
  onAddTier,
  onRemoveTier,
  onUpdateTier,
  onChangeTierType,
  disabledButtonAdd,
}) => {
  const { themeOption } = React.useContext(ColorThemeContext);

  const removeIcon = tiers.length > 1;

  const initialTiers = (isScheduleTiersConfiguration(selectedRule) && selectedRule.tiers) as Array<TierWithId>;

  return (
    <Wrapper>
      {tiers.map((tier, i) => {
        const tierIsExist = initialTiers.find((t) => t.id === tier.id);
        return (
          <div key={i}>
            <TierPanel
              tier={tier}
              removeIcon={removeIcon}
              onRemove={() => onRemoveTier(tier.id)}
              onUpdate={onUpdateTier}
              onChangeType={onChangeTierType}
              disabledChangeTierType={Boolean(tierIsExist)}
            />
            {i < tiers.length - 1 && <Divider style={{ marginTop: 30, marginBottom: 20 }} />}
          </div>
        );
      })}
      <ButtonLink
        iconName="add"
        label="Add tier"
        style={{ marginTop: 20 }}
        color={colorTheme[themeOption].text.primary}
        disabled={disabledButtonAdd}
        onClick={onAddTier}
      />
    </Wrapper>
  );
};

export { EditTiersRulePanel };
