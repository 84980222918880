const createPublisherSuccess = "Super, added new Publisher! 🤗";
const createPublisherError = "Oops, something went wrong when try to create a Publisher! 😓";

const editPublisherSuccess = "Wow! Publisher edited correctly. 🥳";
const editPublisherError = "Oops, something went wrong when try to edit a Publisher! 😥";

const createTokenSuccess = "Super, added new Token! ⚡️";
const createTokenError = "Oops, something went wrong when try to create a Token! 😿";

const editTokenSuccess = "Wow! Token edited correctly. 💪🏼";
const editTokenError = "Oops, something went wrong when try to edit a Token! 🥺";

const bindTokenSuccess = "Wow! Publishers assigned to Token correctly. 💪🏼";
const bindTokenError = "Oops, something went wrong when try to assign Publisher! 🥺";

const createConfigurationSuccess = "Yeah, added new Configuration! 😎";
const createConfigurationError = "Oops, something went wrong when try to create a Configuration! 😩";

const editConfigurationSuccess = "Great! Configuration edited correctly. 😌";
const editConfigurationError = "Oops, something went wrong when try to edit a Configuration. 😞";

const cloneConfigurationSuccess = "Super! Configuration cloned correctly. 🤩";
const cloneConfigurationError = "Oops, something went wrong when try to clone a Configuration. 😿";

const deleteConfigurationSuccess = "Configuration deleted correctly! 🗑";
const deleteConfigurationError = "Oops, something went wrong when try to delete a Configuration! 🤔";

const copyTextSuccess = "Great, text copied correctly! 📄";

export {
  createPublisherSuccess,
  createPublisherError,
  editPublisherSuccess,
  editPublisherError,
  createTokenSuccess,
  createTokenError,
  editTokenSuccess,
  editTokenError,
  bindTokenSuccess,
  bindTokenError,
  createConfigurationSuccess,
  createConfigurationError,
  editConfigurationSuccess,
  editConfigurationError,
  deleteConfigurationSuccess,
  deleteConfigurationError,
  cloneConfigurationSuccess,
  cloneConfigurationError,
  copyTextSuccess
};
