import * as React from "react";
import styled, { css } from "styled-components";
import { v4 } from "uuid";
import { Label } from "../../styles";
import { capitalize } from "lodash";

const Input = styled.input<React.HtmlHTMLAttributes<HTMLInputElement>>`
  height: 38px;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  background: ${props => props.theme.select.background};
  color: ${props => props.theme.select.textOpacity};

  font-family: ${props => props.theme.fontFamily.sansSerif};
  font-size: 12px;
  letter-spacing: 0.5px;

  :hover {
    color: ${props => props.theme.select.text};
  }
  :active {
    color: ${props => props.theme.select.text};
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  ::-webkit-clear-button {
    font-size: 25px;
    height: 30px;
    position: relative;
    right: 4px;
    color: ${props => props.theme.select.text};
  }

  ${props =>
    props.value &&
    css`
      color: ${props.theme.select.text};
    `}
`;

type TypeInput = "datetime-local" | "date" | "time";

interface Props {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  wrapperStyle?: React.CSSProperties;
  type?: TypeInput;
}

const DateField: React.FC<Props> = ({ label, onChange, value, type, wrapperStyle }) => {
  const id = React.useRef(v4());

  return (
    <div style={{ ...wrapperStyle, display: "flex", flexDirection: "column-reverse" }}>
      <Input
        id={id.current}
        value={value}
        min="2015-01-01"
        max="2050-12-31"
        onChange={onChange}
        type={type ? type : "date"}
      />
      <Label htmlFor={id.current}>{capitalize(label)}</Label>
    </div>
  );
};

export { DateField };
