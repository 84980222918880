import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Header } from "./components/Header";
import { Switch, Route, useLocation, useHistory, NavLink as L, Redirect } from "react-router-dom";
import { PublisherPanelContainer } from "./containers/PublishersPanelContainer";
import { TokensPanelContainer } from "./containers/TokensPanelContainer";
import { PublisherFilter } from "./components/PublisherFilter";
import { TokenFilter } from "./components/TokenFilter";
import { PublisherFilterProvider } from "./providers/PublisherFilterProvider";
import { AddPublisherPanel } from "./components/AddPublisherPanel";
import { AddTokenPanel } from "./components/AddTokenPanel";
import auth from "./auth";
import { LoginPage } from "./LoginPage";
import PrivateRoute from "./PrivateRoute";
import { TokenFilterProvider } from "./providers/TokenFilterProvider";
import { Sidebar } from "./Sidebar";
import { UserPanel } from "./components/UserPanel";
import { ConfigurationFilter } from "./components/ConfigurationFilter";
import { AddConfigurationPanelWrapper } from "./components/AddConfiguration/AddConfigurationPanelWrapper";
import { ConfigurationsPanelContainer } from "./containers/ConfigurationsPanelContainer";
import { ConfigurationFilterProvider } from "./providers/ConfigurationFilterProvider";
import { ColorThemeContext } from "./providers/ColorThemeProvider";
import { ActorStatePanelContainer } from "./containers/ActorStatePanelContainer";
import { ActorStateFilter } from "./components/ActorStateFilter";
import { ActorStateFilterProvider } from "./providers/ActorStateFilterProvider";
import { BackgroundOverlay } from "./styles";
import { FeedbackSnackbar } from "./components/ui/Snackbar";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import { GlobalStyles } from "./global";
import { colorTheme } from "./theme";

const ActionsWrapper = styled.div`
  height: 100%;
  background: ${props => props.theme.background.secondary};
  margin-top: 3px;
  margin-left: 3px;
  position: fixed;
  left: 60px;
  z-index: 10;
`;

const ContentWrapper = styled.div`
  margin: 85px 100px;
  flex-grow: 1;
`;

const routes = [
  {
    path: "/",
    exact: true,
    content: PublisherPanelContainer,
    filter: <PublisherFilter />,
    add: <AddPublisherPanel />
  },
  {
    path: "/token",
    content: TokensPanelContainer,
    filter: <TokenFilter />,
    add: <AddTokenPanel />
  },
  {
    path: "/configuration",
    content: ConfigurationsPanelContainer,
    filter: <ConfigurationFilter />,
    add: <AddConfigurationPanelWrapper />
  },
  {
    path: "/actor-state",
    content: ActorStatePanelContainer,
    filter: <ActorStateFilter />
  }
];

const App: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const isAdding = location.search.indexOf("?add") >= 0;
  const isUser = location.search.indexOf("?user") >= 0;
  const currentRoute = routes.find(r => r.path === location.pathname);

  const { themeOption } = React.useContext(ColorThemeContext);

  const theme = colorTheme[themeOption];

  return (
    <ThemeProvider theme={theme}>
      <ConfigurationFilterProvider>
        <TokenFilterProvider>
          <PublisherFilterProvider>
            <ActorStateFilterProvider>
              <SnackbarProvider>
                <GlobalStyles theme={theme} />
                <FeedbackSnackbar />
                <Switch>
                  <Route exact path="/login">
                    {auth.isAuthenticated() ? <Redirect to="/" /> : <LoginPage login={auth.login} />}
                  </Route>
                  <>
                    <Header />
                    <div style={{ height: "calc(100% - 68px)", display: "flex" }}>
                      <div style={{ display: "flex", flexBasis: 275 }}>
                        {auth.isAuthenticated() && <Sidebar addPanel={Boolean(currentRoute && currentRoute.add)} />}
                      </div>
                      {routes.map((route, index) => (
                        <PrivateRoute
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          isAuthenticated={auth.isAuthenticated}
                        >
                          <ActionsWrapper>
                            {isUser && <UserPanel />}
                            {!isAdding && !isUser && route.filter}
                            {isAdding && route.add}
                          </ActionsWrapper>
                          {isAdding && (
                            <BackgroundOverlay
                              onClick={() =>
                                history.push({
                                  pathname: location.pathname
                                })
                              }
                            />
                          )}
                          <ContentWrapper>
                            <route.content />
                          </ContentWrapper>
                        </PrivateRoute>
                      ))}
                    </div>
                  </>
                </Switch>
              </SnackbarProvider>
            </ActorStateFilterProvider>
          </PublisherFilterProvider>
        </TokenFilterProvider>
      </ConfigurationFilterProvider>
    </ThemeProvider>
  );
};

export { App };
