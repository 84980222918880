import { DefaultTheme } from "styled-components";
const loginDark = require("../assets/bkg-loginpage-dark.png");
const loginLight = require("../assets/bkg-loginpage-light.png");

export type ThemeOption = "lightTheme" | "darkTheme";

type ThemeType = { [k: string]: DefaultTheme };

const fontFamily = {
  monospace: `"Roboto Mono", monospace`,
  sansSerif: `"Roboto", sans-serif`
};

const colorDarkTheme = {
  primary: {
    700: "#1EAEB8",
    500: "#2DCDD8",
    300: "#88F1F7",
    100: "#CCFCFF"
  },
  secondary: {
    700: "#272C35",
    500: "#4B5056",
    300: "#8E929A",
    100: "#E4E6E8"
  },
  tertiary: {
    700: "#000000",
    500: "#8F8F8F",
    300: "#CDCDCD",
    100: "#F2F2F2"
  }
};

const darkTheme = {
  loginBackground: loginDark,
  fontFamily,
  text: {
    primary: "#FFFFFF",
    dida: "#7E828A"
  },
  select: {
    text: colorDarkTheme.secondary[700],
    textOpacity: colorDarkTheme.secondary[300],
    hover: colorDarkTheme.primary[500],
    background: colorDarkTheme.secondary[100],
    details: colorDarkTheme.secondary[300]
  },
  item: {
    actions: colorDarkTheme.tertiary[700],
    background: colorDarkTheme.secondary[500],
    backgroundDark: colorDarkTheme.secondary[700],
    newItemBackground: colorDarkTheme.tertiary[500]
  },
  toggle: {
    background: colorDarkTheme.secondary[100],
    active: colorDarkTheme.primary[500],
    inactive: colorDarkTheme.tertiary[500]
  },
  background: {
    primary: colorDarkTheme.secondary[700],
    secondary: colorDarkTheme.secondary[500],
    portal: colorDarkTheme.tertiary[700]
  },
  chip: {
    default: colorDarkTheme.secondary[100],
    hover: colorDarkTheme.secondary[300],
    selected: colorDarkTheme.primary[500],
    text: colorDarkTheme.secondary[700]
  },
  button: {
    undo: colorDarkTheme.secondary[100],
    submit: colorDarkTheme.primary[500],
    text: colorDarkTheme.secondary[700],
    textOpacity: colorDarkTheme.secondary[300]
  },

  icon: {
    normal: colorDarkTheme.secondary[100],
    hover: colorDarkTheme.primary[500]
  },
  primary: colorDarkTheme.primary[500],
  primaryLight: colorDarkTheme.primary[100],
  error: "#E04726",
  caution: "#FFD300"
};

const colorLightTheme = {
  primary: {
    700: "#43675B",
    500: "#75AD8F",
    300: "#AECDBC",
    100: "#C9DBD7"
  },
  secondary: {
    700: "#D5C5AE",
    500: "#E1D6C6",
    300: "#EEE6DB",
    100: "#F1ECE4"
  },
  tertiary: {
    700: "#3E3A39",
    500: "#686463",
    300: "#969392",
    100: "#BEBEBC"
  }
};

const lightTheme = {
  loginBackground: loginLight,
  fontFamily,
  text: {
    primary: colorLightTheme.tertiary[700],
    dida: colorLightTheme.tertiary[300]
  },
  select: {
    text: colorLightTheme.tertiary[700],
    textOpacity: colorLightTheme.tertiary[300],
    hover: colorLightTheme.primary[500],
    background: colorLightTheme.secondary[500],
    details: colorLightTheme.tertiary[300]
  },
  toggle: {
    background: colorLightTheme.tertiary[100],
    active: colorLightTheme.primary[500],
    inactive: colorLightTheme.tertiary[300]
  },
  item: {
    actions: colorLightTheme.secondary[700],
    background: colorLightTheme.secondary[100],
    backgroundDark: colorLightTheme.secondary[500],
    newItemBackground: "#FFFFFF"
  },
  background: {
    primary: colorLightTheme.secondary[300],
    secondary: colorLightTheme.secondary[100],
    portal: "#FFFFFF"
  },
  chip: {
    default: colorLightTheme.secondary[300],
    hover: colorLightTheme.secondary[500],
    selected: colorLightTheme.primary[500],
    text: colorLightTheme.tertiary[700]
  },
  button: {
    undo: colorLightTheme.secondary[500],
    submit: colorLightTheme.primary[500],
    text: colorLightTheme.tertiary[700],
    textOpacity: colorLightTheme.tertiary[300]
  },
  icon: {
    normal: colorLightTheme.tertiary[300],
    hover: colorLightTheme.primary[500]
  },
  primary: colorLightTheme.primary[500],
  primaryLight: colorLightTheme.primary[100],
  error: "#E86137",
  caution: "#F2C85C"
};

export const colorTheme: ThemeType = {
  lightTheme,
  darkTheme
};
