import * as React from "react";
import { Title } from "../../styles";
import { Icon } from "./Icon";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";

export const ActionTitle: React.FC<{ title: string; style?: React.CSSProperties }> = ({ title, style }) => {
  const { themeOption } = React.useContext(ColorThemeContext);
  return (
    <div style={{ ...style, display: "flex", alignItems: "center", position: "relative", left: -14, top: -9 }}>
      <Icon name="arrow" style={{ fontSize: 30, cursor: "default" }} color={colorTheme[themeOption].primary} />
      <Title style={{ margin: 0, userSelect: "none" }}>{title}</Title>
    </div>
  );
};
