import * as React from "react";
import styled from "styled-components";
import { Input } from "./ui/Input";
import { GraphApiTokenDetails } from "./AddTokenPanel";
import { SelectStyled } from "./ui/Select";
import { GraphApiTokenType } from "../types/Token";
import { capitalize } from "lodash";
import { DateField } from "./ui/DateField";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = GraphApiTokenDetails & {
  updateValue: (key: string, value: any) => void;
};

export const GraphApiTokenEditPanel: React.FC<Props> = ({ appSecret, token, expiryDate, type, updateValue }) => {
  return (
    <Wrapper>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flexBasis: "50%", paddingRight: 15 }}>
          <SelectStyled
            placeholder="Type"
            label="type"
            options={Object.values(GraphApiTokenType).map((t) => ({
              value: t,
              label: capitalize(t),
            }))}
            value={type && { value: type, label: capitalize(type) }}
            onChange={(e) => updateValue("type", e.value)}
          />
        </div>
        <div style={{ width: "50%", paddingLeft: 15 }}>
          <DateField
            label="Expiry date"
            onChange={(e) => {
              updateValue("expiryDate", e.currentTarget.value);
            }}
            value={expiryDate ? expiryDate : ""}
          />
        </div>
        <div style={{ flexBasis: "50%", paddingRight: 15, marginTop: 25 }}>
          <Input
            placeholder="App secret"
            label="app secret"
            value={appSecret}
            onChange={(e) => updateValue("appSecret", e.currentTarget.value)}
          />
        </div>
        <div style={{ flexBasis: "50%", paddingLeft: 15, marginTop: 25 }}>
          <Input
            placeholder="Token"
            label="token"
            value={token}
            onChange={(e) => updateValue("token", e.currentTarget.value)}
          />
        </div>
      </div>
    </Wrapper>
  );
};
