import * as React from "react";
import useSWR from "swr";
import { pick, difference, includes, omit, flatMap, every, orderBy, groupBy } from "lodash";

import { fetchData } from "../client";
import { InvocationManagerActorState, ResponseActorState, PublisherActor, PublisherActorStates } from "../types/Actor";
import { ErrorPage } from "../components/ui/ErrorPage";
import { NoItemFound } from "../components/ui/NoItemFound";
import { ItemsPanel } from "../components/ItemsPanel";
import { ListItemsPlaceholder } from "../components/ui/Placeholder/ListItems";
import { PublisherRecord } from "../types/Publisher";
import { FullActorState } from "../types/views";
import { ActorStateFilterContext } from "../providers/ActorStateFilterProvider";
import { Icon } from "../components/ui/Icon";
import { ActorStateDetails } from "../components/ActorStateDetails/ActorStateDetails";
import { Order } from "../components/ui/IconOrderBy";
import { colorTheme } from "../theme";
import { ColorThemeContext } from "../providers/ColorThemeProvider";

const columns = [
  { accessor: "invocationManager.publisher.description", label: "Publisher", percentage: 35, sortable: true },
  { accessor: "invocationManager.publisher.platform", label: "Platform", percentage: 35, sortable: true },
  { accessor: "pStatus", label: "Status", percentage: 20, sortable: true },
  { accessor: "error", label: "Error", percentage: 10, sortable: false },
];

const ActorStatePanelContainer: React.FC = () => {
  const { themeOption } = React.useContext(ColorThemeContext);

  const { platform, description, order, orderByLabel, setOrder, setOrderByLabel } = React.useContext(
    ActorStateFilterContext
  );
  const [openPanel, setOpenPanel] = React.useState(false);
  const [selectedPublisherActor, setSelectedPublisherActor] = React.useState<PublisherActorStates | null>(null);

  const { data: publishersData, error: publishersError } = useSWR<{
    publishers: Array<PublisherRecord>;
  }>("/publishers", fetchData);

  const { data: responseActorData, error: responseActorError } = useSWR<{
    states: Array<ResponseActorState>;
  }>("/monitoring/response-actor/state", fetchData);

  const { data: publisherActorData, error: publisherActorError } = useSWR<
    Array<{
      invocationManager: InvocationManagerActorState;
      publisherActorStates: Array<PublisherActor>;
    }>
  >("/invocationManager-with-publisherActorState", async () => {
    const invocationManagerData: { states: Array<InvocationManagerActorState> } = await fetchData(
      "/monitoring/invocation-manager-actor/state"
    );

    return Promise.all(
      invocationManagerData.states.map(async (item) => {
        const id = item.publisherActorPath;
        const publisherActorStates: { states: Array<PublisherActor> } = await fetchData(
          `/monitoring/publisher-actor/${id}`
        );

        return { invocationManager: item, publisherActorStates: publisherActorStates.states };
      })
    );
  });

  if (publishersError || publisherActorError || responseActorError) {
    return <ErrorPage />;
  }

  if (!publishersData || !publisherActorData || !responseActorData) {
    return <ListItemsPlaceholder />;
  }

  const buildStateRecords: () => Array<{
    invocationManager: FullActorState;
    publisherActorStates: PublisherActorStates;
  }> = () => {
    const buildStateRecord = (pA: {
      invocationManager: InvocationManagerActorState;
      publisherActorStates: PublisherActor[];
    }) => {
      const publisherActorPath = pick(pA.invocationManager, "publisherActorPath");

      let publisherActorWithResponse: PublisherActorStates = {};
      if (pA.publisherActorStates.length > 0) {
        publisherActorWithResponse = groupBy(
          pA.publisherActorStates.map((pAs) => {
            const response = responseActorData.states.find((rA) => rA.reqUUID === pAs.requestUUID);

            if (response) {
              return {
                ...pAs,
                numOfRep: response.numOfRep,
              };
            }
            return {
              ...pAs,
              numOfRep: 0,
            };
          }),
          "pStatus"
        );
      }

      return {
        ...pA,
        publisherActorStates: publisherActorWithResponse,
        invocationManager: {
          ...publisherActorPath,
          publisher: publishersData.publishers.find((p) => p.publisherId === pA.invocationManager.publisherId)!,
        },
      };
    };

    return publisherActorData.map(buildStateRecord).filter((s) => {
      let ok = true;

      if (platform !== null && Boolean(s.invocationManager.publisher)) {
        ok = ok && s.invocationManager.publisher.platform === platform;
      }

      if (description && s.invocationManager.publisher) {
        ok = ok && s.invocationManager.publisher.description.toLowerCase().includes(description.toLowerCase());
      }

      return ok;
    });
  };

  const stateRecords = buildStateRecords();

  let orderedStates: Array<{
    invocationManager: FullActorState;
    publisherActorStates: PublisherActorStates;
  }>;
  if (order && orderByLabel) {
    orderedStates = orderBy(stateRecords, orderByLabel, order);
  } else {
    orderedStates = stateRecords;
  }

  if (stateRecords.length === 0) {
    return <NoItemFound missingElement="actor state" />;
  }

  const items = orderedStates.map((s) => {
    const requests = Object.values(s.publisherActorStates)[0];
    const publisherStatus = Object.keys(s.publisherActorStates)[0];

    const isError = Boolean(requests && requests.find((pA) => pA.numOfRep > 0));

    return {
      ...s,
      error: isError ? (
        <Icon name="snackbar-caution" color={colorTheme[themeOption].error} style={{ left: -5 }} />
      ) : null,
      pStatus:
        publisherStatus === "Probing" ? (
          <Icon name="snackbar-caution" color={colorTheme[themeOption].caution} style={{ left: -5 }} />
        ) : null,
    };
  });

  return (
    <>
      {openPanel && selectedPublisherActor !== null && (
        <ActorStateDetails selectedPublisherActor={selectedPublisherActor} onClose={() => setOpenPanel(false)}>
          open panel
        </ActorStateDetails>
      )}
      <ItemsPanel
        columns={columns}
        idField="publisher.publisherId"
        items={items}
        disabledShowIcons={orderedStates.map((s) => Object.values(s.publisherActorStates).length === 0)}
        onShow={(item: { invocationManager: FullActorState; publisherActorStates: PublisherActorStates }) => {
          const selectedActor = orderedStates.find(
            (s) => s.invocationManager.publisher.publisherId === item.invocationManager.publisher.publisherId
          );

          if (selectedActor) {
            setSelectedPublisherActor(item.publisherActorStates);
            setOpenPanel(true);
          }
        }}
        order={order}
        orderByLabel={orderByLabel}
        onChangeOrderBy={(orderBy: string) => setOrderByLabel(orderBy)}
        onChangeOrder={(order: Order) => setOrder(order)}
      />
    </>
  );
};

export { ActorStatePanelContainer };
