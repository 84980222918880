import * as React from "react";

const onKeyDown = (e: KeyboardEvent, onSubmit: () => any) => {
  if (e.keyCode === 13) {
    onSubmit();
  }
};

const useKeyDownEnter = (onSubmit: () => any) => {
  React.useEffect(() => {
    const onClickEnter = (e: KeyboardEvent) => {
      onKeyDown(e, onSubmit);
    };

    window.addEventListener("keydown", onClickEnter, true);

    return () => {
      window.removeEventListener("keydown", onClickEnter, true);
    };
  }, [onSubmit]);
};

export { useKeyDownEnter };
