import * as React from "react";
import styled, { css } from "styled-components";
import {
  Rule,
  isScheduleIntervalConfiguration,
  isScheduleDailyConfiguration,
  isScheduleTiersConfiguration,
  getRequestType
} from "../types/Configuration";
import { headerTextStyle } from "../styles";
import { Icon } from "./ui/Icon";
import { capitalize } from "lodash";
import { CSSTransition } from "react-transition-group";
import { Divider } from "./ui/Divider";
import { colorTheme } from "../theme";
import { ColorThemeContext } from "../providers/ColorThemeProvider";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`;

const InfoWrapper = styled.div<{ showAdditionalInfo: boolean; widthCalc?: number }>`
  max-height: ${props => (props.showAdditionalInfo ? "1000px" : "82px")};
  font-size: 12px;
  border-radius: 12px;
  background: ${props => props.theme.item.backgroundDark};
  padding: 20px;
  width: ${props => (props.widthCalc ? `calc(100% - ${props.widthCalc}px)` : "calc(100% - 105px)")};
  z-index: 1;
  transition: all 0.3s;
`;

const Title = styled.p`
  ${headerTextStyle};
  margin-bottom: 10px;
`;

const Action = styled.div<{ hover: boolean; showAdditionalInfo: boolean }>`
  background: ${props => props.theme.item.actions};
  opacity: ${props => (props.hover ? "100%" : "20%")};
  transition: opacity 0.3s;
  height: 82px;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0px 12px 12px 0px;
  width: 155px;
  padding: 0 15px;
  padding-left: 25px;

  & > i:not(:last-child) {
    padding-right: 5px;
  }

  ${props =>
    props.showAdditionalInfo &&
    css`
      opacity: 1;
    `}
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 0.2s;
  opacity: 1;

  &.wrapper-entering {
    opacity: 0.5;
  }
  &.wrapper-entered {
    opacity: 1;
  }
  &.wrapper-exiting {
    opacity: 0;
  }
  &.wrapper-exited {
    opacity: 0;
  }
`;

interface Props {
  rule: Rule;
  onDelete?: () => void;
  onEdit?: () => void;
  additionalInfo?: React.ReactElement;
  widthCalc?: number;
}

const RuleInfoPanel: React.FC<Props> = ({ rule, onDelete, onEdit, additionalInfo, widthCalc }) => {
  const [hover, setHover] = React.useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = React.useState(false);

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <Wrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <InfoWrapper showAdditionalInfo={showAdditionalInfo} widthCalc={widthCalc}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: showAdditionalInfo ? 20 : 0,
            transition: "all 0.3s"
          }}
        >
          <Title>{capitalize(getRequestType(rule))}</Title>
          <div>
            <span style={{ fontFamily: colorTheme[themeOption].fontFamily.monospace, fontWeight: 500 }}>Request: </span>
            {rule.request}
          </div>
        </div>
        <CSSTransition in={showAdditionalInfo} timeout={{ enter: 100, exit: 300 }} unmountOnExit>
          {status => (
            <AdditionalInfo className={`wrapper-${status}`}>
              <Divider style={{ marginBottom: 15 }} />
              {additionalInfo}
            </AdditionalInfo>
          )}
        </CSSTransition>
      </InfoWrapper>
      <Action hover={hover} showAdditionalInfo={showAdditionalInfo}>
        {additionalInfo && (
          <Icon
            name={showAdditionalInfo ? "reduce" : "show"}
            color={showAdditionalInfo ? colorTheme[themeOption].primary : colorTheme[themeOption].text.primary}
            onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
          />
        )}
        {onEdit && <Icon name="edit" onClick={onEdit} color={colorTheme[themeOption].text.primary} />}
        {onDelete && <Icon name="remove" onClick={onDelete} color={colorTheme[themeOption].text.primary} />}
      </Action>
    </Wrapper>
  );
};

export { RuleInfoPanel };
