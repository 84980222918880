import * as React from "react";
import { Icon } from "./Icon";
import styled, { css } from "styled-components";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";
import { IconNames } from "../../types/IconNames";

const Link = styled.span<{ color?: string; underline?: boolean }>`
  font-size: 12px;
  letter-spacing: 1px;
  font-family: ${props => props.theme.fontFamily.monospace};
  color: ${props => (props.color ? props.color : props.theme.primary)};
  ${props =>
    props.underline &&
    css`
      border-bottom-style: solid;
      border-bottom-width: 1px;
      top: 2px;
      padding-bottom: 2px;
    `};
  border-bottom-color: ${props => (props.color ? props.color : props.theme.primary)};
  position: relative;
  user-select: none;
`;

interface Props {
  label: string;
  iconName: IconNames;
  color?: string;
  onClick: () => void;
  underline?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const ButtonLink: React.FC<Props> = ({ label, iconName, color, underline, onClick, style, disabled }) => {
  const { themeOption } = React.useContext(ColorThemeContext);
  return (
    <div
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        left: -8,
        position: "relative",
        cursor: !disabled ? "pointer" : "not-allowed",
        opacity: !disabled ? 1 : 0.5
      }}
      onClick={() => !disabled && onClick()}
    >
      <Icon name={iconName} color={color ? color : colorTheme[themeOption].primary} />
      <Link underline={underline} color={color}>
        {label}
      </Link>
    </div>
  );
};
