import * as React from "react";
import styled, { css } from "styled-components";
import { capitalize } from "lodash";

const Wrapper = styled.div`
  display: flex;
  height: 38px;

  & > div:not(:last-child) {
    margin-right: 3px;
  }
`;

const ChooseBox = styled.div<{ selected: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.button.undo};
  font-size: 12px;
  color: ${props => props.theme.button.textOpacity};
  padding: 20px 10px;
  cursor: pointer;

  &:hover {
    background: ${props => (props.selected ? props.theme.button.submit : props.theme.primaryLight)};
    color: ${props => props.theme.button.text};
  }

  ${props =>
    props.selected &&
    css`
      background: ${props.theme.button.submit};
      color: ${props.theme.button.text};
    `}
`;

export type Option = "all" | "active" | "inactive";
const activeFilterLabel = ["all", "active", "inactive"];

interface Props {
  selectedOption: "all" | "active" | "inactive";
  setSelectedOption: (option: Option) => void;
}

const ActiveFilter: React.FC<Props> = ({ selectedOption, setSelectedOption }) => (
  <Wrapper>
    {activeFilterLabel.map(label => (
      <ChooseBox key={label} selected={selectedOption === label} onClick={() => setSelectedOption(label as Option)}>
        {capitalize(label)}
      </ChooseBox>
    ))}
  </Wrapper>
);

export { ActiveFilter };
