import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --medium-grey: #7e828a;
    --white: #ffffff;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${(props) => props.theme.fontFamily.sansSerif};
    color: ${(props) => props.theme.text.primary};
    background: ${(props) => props.theme.background.primary};
    letter-spacing: 0.5px;
  }

  html,
  body,
  #root {
    height: 100vh;
  }

  .input-text {
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    height: 38px;
    font-family: ${(props) => props.theme.fontFamily.sansSerif};
    letter-spacing: 0.5px;
    font-size: 12px;
    background: ${(props) => props.theme.select.background}!important;
    color: ${(props) => props.theme.select.text}!important
  }

  .input-text:focus {
    outline: 2px solid ${(props) => props.theme.primary}
  }


  .input-text:required ~ label:after {
    content: " *";
  }

  .input-text::placeholder {
    color: ${(props) => props.theme.select.text};
    opacity: 0.4;
  }

  .icon-s {
    font-size: 15px;
  }

  .icon-m {
    font-size: 25px;
  }

  .icon-l {
    font-size: 35px;
  }
`;
