import * as React from "react";
import { PanelWrapper } from "../../../styles";
import { SelectPlaceholder } from "./SelectPlaceholder";
import { TextPlaceholder } from "./Placeholder";

export const FilterPanelPlaceholder: React.FC = () => {
  return (
    <PanelWrapper style={{ opacity: "20%", display: "block" }}>
      <TextPlaceholder width={80} style={{ marginBottom: 35, marginTop: 5 }} />
      <SelectPlaceholder style={{ marginBottom: 25 }} />
      <SelectPlaceholder style={{ marginBottom: 25 }} />
      <SelectPlaceholder style={{ marginBottom: 25 }} />
      <SelectPlaceholder />
    </PanelWrapper>
  );
};
