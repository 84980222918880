import * as React from "react";
import { Dialog, DialogableProps, TextDialog } from "./ui/Dialog";
import { Button } from "./ui/Button";
import { capitalize } from "lodash";
import { useKeyDownEnter } from "../hooks/useKeyDownEnter";

type Props = DialogableProps & {
  onRemove: () => void;
};

const DialogDelete: React.FC<Props> = ({ open, onClose, onRemove }) => {
  const pathname = location.pathname.substring(1);

  const removingElement = pathname.length > 0 ? capitalize(pathname) : "Publisher";

  useKeyDownEnter(onRemove);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleDialog={`Delete ${removingElement}`}
      buttonSection={
        <>
          <Button onClick={onClose} undo>
            Undo
          </Button>
          <Button onClick={onRemove} style={{ marginLeft: 10 }}>
            Delete it
          </Button>
        </>
      }
    >
      <div style={{ marginBottom: 30, width: 260 }}>
        <TextDialog>{`Are you sure to delete this ${removingElement}? You will not be able to recover it.`}</TextDialog>
      </div>
    </Dialog>
  );
};

export { DialogDelete };
