import * as React from "react";
import { omit, differenceBy, capitalize, sortBy, pick } from "lodash";
import useSWR, { trigger, mutate } from "swr";

import client, { fetchData, mutateData } from "../client";
import { Configuration } from "../types/Configuration";
import { ListConfigurationPlaceholder } from "../components/ui/Placeholder/ListConfigurationPlaceholder";
import { PublisherRecord } from "../types/Publisher";
import { ConfigurationFilterContext } from "../providers/ConfigurationFilterProvider";
import { ConfigurationsPanel } from "../components/ConfigurationsPanel";
import { FullConfiguration } from "../types/views";
import { NoItemFound } from "../components/ui/NoItemFound";
import { ErrorPage } from "../components/ui/ErrorPage";
import { DialogDelete } from "../components/DialogDelete";
import { SnackbarContext } from "../providers/SnackbarProvider";
import {
  deleteConfigurationError,
  deleteConfigurationSuccess,
  editConfigurationSuccess,
  editConfigurationError,
} from "../messages";
import { ConfigurationDetailsPanel } from "../components/Details&EditConfiguration/ConfigurationDetailsPanel";
import { useKeyUpEsc } from "../hooks/useKeyUpEsc";
import { ConfigurationCloneDialog } from "../components/ConfigurationCloneDialog";
import { serializeRulesRecords, conversionRules } from "../utilsConfiguration";
import { useHighlightLastAddedItem, comparatorById } from "../hooks/useHighlightLastAddedItem";
import { v4 } from "uuid";

const ConfigurationsPanelContainer: React.FC = () => {
  const { platform, active, clearFilter } = React.useContext(ConfigurationFilterContext);
  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDetailsPanel, setOpenDetailsPanel] = React.useState(false);
  const [openEditPanel, setOpenEditPanel] = React.useState(false);
  const [openClonePanel, setOpenClonePanel] = React.useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = React.useState<FullConfiguration | null>(null);

  const orderedConfiguration = React.useRef<Array<FullConfiguration>>([]);

  const onCloseAllPanel = () => {
    setOpenDetailsPanel(false);
    setOpenEditPanel(false);
  };

  useKeyUpEsc(onCloseAllPanel);

  const { data: publishersData, error: publishersError } = useSWR<{
    publishers: Array<PublisherRecord>;
  }>("/publishers", fetchData);

  const { data: configurationsData, error: configurationsError } = useSWR<{
    configurations: Array<Configuration>;
  }>("/configurations", fetchData);

  const { lastAddedItemId } = useHighlightLastAddedItem(configurationsData?.configurations, comparatorById, ["id"]);

  React.useEffect(() => {
    const configurationExist = orderedConfiguration.current.find((c) => lastAddedItemId[0] === c.id);

    if (!configurationExist) {
      clearFilter();
    }
  }, [lastAddedItemId]);

  if (publishersError || configurationsError) {
    return <ErrorPage />;
  }

  if (!publishersData || !configurationsData) {
    return <ListConfigurationPlaceholder />;
  }

  const configurations: Array<FullConfiguration> = configurationsData.configurations
    .map((c) =>
      omit({ ...c, publisher: publishersData.publishers.find((p) => p.publisherId === c.publisherId)! }, "publisherId")
    )
    .filter((c) => {
      let ok = true;

      if (platform !== null) {
        ok = ok && c.platform === platform;
      }

      if (active !== "all") {
        if (active === "active") {
          ok = ok && c.active;
        } else {
          ok = ok && !c.active;
        }
      }

      return ok;
    });

  orderedConfiguration.current = sortBy(
    configurations.map((c) => ({
      ...c,
      publisher: { ...c.publisher, publisherId: capitalize(c.publisher.publisherId) },
    })),
    "publisher.publisherId"
  );

  if (configurations.length === 0) {
    return <NoItemFound missingElement="configuration" />;
  }

  const onCloseDeleteDialog = () => setOpenDeleteDialog(false);
  const onCloseDetailsPanel = () => setOpenDetailsPanel(false);
  const onCloseEditPanel = () => setOpenEditPanel(false);

  const onRemove = async () => {
    try {
      await mutateData("DELETE", `/configurations/${selectedConfiguration?.id}`);
      onOpenSnackbar(deleteConfigurationSuccess);

      mutate("/configurations", {
        configurations: configurationsData.configurations.filter((config) => config.id !== selectedConfiguration?.id),
      });
    } catch (err) {
      onOpenSnackbar(deleteConfigurationError, "error");
    }
  };

  const onEditConfiguration = async (configuration: FullConfiguration) => {
    const active = openDetailsPanel || openEditPanel ? configuration.active : !configuration.active;
    const rules = openDetailsPanel || openEditPanel ? serializeRulesRecords(configuration.rules) : configuration.rules;
    try {
      await mutateData("PUT", `/configurations/${configuration.id}`, {
        rules,
        active,
      });
      onCloseEditPanel();
      onCloseDetailsPanel();
      onOpenSnackbar(editConfigurationSuccess);

      const updatedConfigurations = configurationsData.configurations.map((config) => {
        if (config.id === configuration.id) {
          return { ...pick(configuration, ["id", "platform"]), publisherId: config.publisherId, rules, active };
        }
        return config;
      });

      mutate("/configurations", { configurations: updatedConfigurations });
    } catch (err) {
      onOpenSnackbar(editConfigurationError, "error");
    }
  };

  const publishersWithoutConfiguration = differenceBy(
    publishersData.publishers,
    configurationsData.configurations,
    "publisherId"
  );

  return (
    <>
      {openDeleteDialog && (
        <DialogDelete
          open={openDeleteDialog}
          onClose={onCloseDeleteDialog}
          onRemove={async () => {
            await onRemove();
            onCloseDeleteDialog();
          }}
        />
      )}
      {(openDetailsPanel || openEditPanel) && selectedConfiguration && (
        <ConfigurationDetailsPanel
          onClose={openEditPanel ? onCloseEditPanel : onCloseDetailsPanel}
          configuration={selectedConfiguration}
          onSaveChanges={onEditConfiguration}
          onOpenEditPanel={(open) => setOpenEditPanel(open)}
          openEditPanel={openEditPanel}
        />
      )}
      {openClonePanel && selectedConfiguration && (
        <ConfigurationCloneDialog
          open={openClonePanel}
          onClose={() => setOpenClonePanel(false)}
          publishers={publishersWithoutConfiguration}
          selectedRules={selectedConfiguration.rules}
        >
          clone panel
        </ConfigurationCloneDialog>
      )}
      <ConfigurationsPanel
        configurations={orderedConfiguration.current}
        lastAddedItemId={Number(lastAddedItemId[0])}
        onDelete={(configuration) => {
          setSelectedConfiguration(configuration);
          setOpenDeleteDialog(true);
        }}
        onOpenEditPanel={(configuration) => {
          setSelectedConfiguration(configuration);
          setOpenEditPanel(true);
        }}
        onChangeActive={onEditConfiguration}
        onOpenDetailsPanel={(configuration) => {
          setSelectedConfiguration(configuration);
          setOpenDetailsPanel(true);
        }}
        onOpenCloneDialog={(configuration) => {
          setSelectedConfiguration(configuration);
          setOpenClonePanel(true);
        }}
      />
    </>
  );
};

export { ConfigurationsPanelContainer };
