import * as React from "react";
import { ConfigurationPlaceholder } from "./ConfigurationPlaceholder";
import styled from "styled-components";
import { TextPlaceholder } from "./Placeholder";
import { Divider } from "../Divider";
import { CountInfo } from "../../../styles";
import { ColorThemeContext } from "../../../providers/ColorThemeProvider";
import { colorTheme } from "../../../theme";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  row-gap: 30px;
  overflow-y: overlay;
  padding: 0px 10px;
  justify-items: center;
  height: 100%;

  @media (max-width: 1600px) {
    grid-template-columns: 25% 25% 25% 25%;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 33% 33% 33%;
  }

  @media (max-width: 1150px) {
    grid-template-columns: 50% 50%;
  }
`;

export const ListConfigurationPlaceholder: React.FC = () => {
  const listItem = [...Array(20).keys()];

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <>
      <div style={{ height: "100%", overflowY: "scroll" }}>
        <Grid>
          {listItem.map((item, i) => (
            <ConfigurationPlaceholder key={i} />
          ))}
        </Grid>
      </div>
      <div style={{ padding: "0px 10px" }}>
        <Divider color={colorTheme[themeOption].text.primary} style={{ marginBottom: 3 }} />
        <CountInfo>Searching...</CountInfo>
      </div>
    </>
  );
};
