import * as React from "react";
import { Input } from "./ui/Input";
import styled from "styled-components";
import { DateField } from "./ui/DateField";
import { GoogleToken } from "../types/Token";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = GoogleToken & {
  updateValue: (key: string, value: any) => void;
};

export const GoogleTokenEditPanel: React.FC<Props> = ({
  accessToken,
  clientId,
  clientSecret,
  expiryDate,
  redirectUri,
  refreshToken,
  updateValue,


}) => (
  <Wrapper>
    <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 20 }}>
      <div style={{ flexBasis: "50%", paddingRight: 15 }}>
        <Input
          placeholder="Access token"
          label="access token"
          value={accessToken}
          onChange={(e) => updateValue("accessToken", e.currentTarget.value)}
        />
      </div>
      <div style={{ flexBasis: "50%", paddingLeft: 15 }}>
        <Input
          placeholder="Refresh token"
          label="refresh token"
          value={refreshToken}
          onChange={(e) => updateValue("refreshToken", e.currentTarget.value)}
        />
      </div>
      <div style={{ flexBasis: "50%", paddingRight: 15, marginTop: 25 }}>
        <Input
          placeholder="Client id"
          label="client id"
          value={clientId}
          onChange={(e) => updateValue("clientId", e.currentTarget.value)}
        />
      </div>
      <div style={{ flexBasis: "50%", paddingLeft: 15, marginTop: 25 }}>
        <Input
          placeholder="Client secret"
          label="client secret"
          value={clientSecret}
          onChange={(e) => updateValue("clientSecret", e.currentTarget.value)}
        />
      </div>
      <div style={{ width: "50%", paddingRight: 15, marginTop: 25 }}>
        <DateField
          label="Expiry date"
          onChange={(e) => {
            updateValue("expiryDate", e.currentTarget.value);
          }}
          value={expiryDate}
        />
      </div>
      <div style={{ flexBasis: "50%", paddingLeft: 15, marginTop: 25 }}>
        <Input
          placeholder="Redirect uri"
          label="redirect uri"
          value={redirectUri}
          onChange={(e) => updateValue("redirectUri", e.currentTarget.value)}
        />
      </div>
    </div>
  </Wrapper>
);
