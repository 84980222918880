import * as React from "react";
import { Label } from "../../styles";
import { capitalize } from "lodash";
import { v4 } from "uuid";

interface Props {
  label: string;
  type?: "text" | "password" | "number";
  wrapperStyle?: React.CSSProperties;
}

export const Input: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({ label, type, wrapperStyle, ...rest }) => {
  const { placeholder } = rest;
  const id = React.useRef(v4());

  return (
    <div style={{ ...wrapperStyle, display: "flex", flexDirection: "column-reverse" }}>
      <input
        type={type}
        id={id.current}
        className="input-text"
        placeholder={capitalize(placeholder)}
        {...rest}
        style={{ position: "relative", left: 1 }}
      />
      <Label htmlFor={id.current}>{capitalize(label)}</Label>
    </div>
  );
};

Input.defaultProps = {
  type: "text"
};
