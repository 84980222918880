import * as React from "react";
import useSWR from "swr";
import moment from "moment";
import { fetchData } from "../client";
import { PublisherRecord } from "../types/Publisher";
import { ItemsPanel } from "../components/ItemsPanel";
import { PublisherFilterContext } from "../providers/PublisherFilterProvider";
import { NoItemFound } from "../components/ui/NoItemFound";
import { ErrorPage } from "../components/ui/ErrorPage";
import { ListItemsPlaceholder } from "../components/ui/Placeholder/ListItems";
import { PublisherAdditionalInfo } from "../components/PublisherAdditionalInfo";
import { DialogEditPublisher } from "../components/DialogEditPublisher";
import { Order } from "../components/ui/IconOrderBy";
import { orderBy, capitalize } from "lodash";
import { useHighlightLastAddedItem, publisherComparator } from "../hooks/useHighlightLastAddedItem";

const columns = [
  { accessor: "publisherId", label: "Id", percentage: 25, sortable: true },
  { accessor: "description", label: "Description", percentage: 25, sortable: true },
  { accessor: "platform", label: "Platform", percentage: 25, sortable: true },
  { accessor: "publishedAt", label: "Published at", percentage: 25, sortable: true }
];

const PublisherPanelContainer: React.FC = () => {
  const { description, platform, order, setOrder, orderByLabel, setOrderByLabel, clearFilter } = React.useContext(
    PublisherFilterContext
  );
  const [selectedPublisher, setSelectedPublisher] = React.useState<PublisherRecord | null>(null);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const orderedPublisher = React.useRef<Array<PublisherRecord>>([]);

  const { data, error } = useSWR<{
    publishers: Array<PublisherRecord>;
  }>("/publishers", fetchData);

  const { lastAddedItemId } = useHighlightLastAddedItem(data?.publishers, publisherComparator, [
    "publisherId",
    "platform"
  ]);

  React.useEffect(() => {
    const publisherExist = orderedPublisher.current.find(p => lastAddedItemId[0] === `${p.publisherId}_${p.platform}`);

    if (!publisherExist) {
      clearFilter();
    }
  }, [lastAddedItemId]);

  if (error) {
    return <ErrorPage />;
  }

  if (!data) {
    return <ListItemsPlaceholder />;
  }

  const publisherRecords = data.publishers.filter(p => {
    let ok = true;

    if (platform !== null) {
      ok = ok && p.platform === platform;
    }

    if (description) {
      ok = ok && p.description.toLowerCase().includes(description.toLowerCase());
    }

    return ok;
  });

  if (order && orderByLabel) {
    orderedPublisher.current = orderBy(
      publisherRecords.map(p => ({ ...p, description: capitalize(p.description) })),
      orderByLabel,
      order
    );
  } else {
    orderedPublisher.current = publisherRecords;
  }

  if (publisherRecords.length === 0) {
    return <NoItemFound missingElement="publisher" />;
  }

  const onCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedPublisher(null);
  };

  return (
    <>
      {openEditDialog && selectedPublisher && (
        <DialogEditPublisher
          open={openEditDialog}
          onClose={() => onCloseEditDialog()}
          onSave={() => onCloseEditDialog()}
          selectedPublisher={selectedPublisher}
        />
      )}
      <ItemsPanel
        columns={columns}
        idField="publisherId"
        items={orderedPublisher.current.map(p => ({
          ...p,
          platform: p.platform,
          publishedAt: moment(p.publishedAt).format("DD/MM/YYYY, HH:mm:ss"),
          new: Boolean(lastAddedItemId.find(newP => newP === `${p.publisherId}_${p.platform}`)),
          id: `${p.publisherId}_${p.platform}`
        }))}
        onEdit={item => {
          const selectedPublisher = orderedPublisher.current.find(p => p.publisherId === item.publisherId);
          if (selectedPublisher) {
            setSelectedPublisher(selectedPublisher);
            setOpenEditDialog(true);
          }
        }}
        renderAdditionalInfo={(publisherRecord: PublisherRecord) => (
          <PublisherAdditionalInfo publisherRecord={publisherRecord} />
        )}
        order={order}
        orderByLabel={orderByLabel}
        onChangeOrderBy={(orderBy: string) => setOrderByLabel(orderBy)}
        onChangeOrder={(order: Order) => setOrder(order)}
      />
    </>
  );
};

export { PublisherPanelContainer };
