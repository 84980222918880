import * as React from "react";
import useSWR, { trigger } from "swr";

import { fetchData, mutateData } from "../client";
import { Dialog, DialogableProps } from "./ui/Dialog";
import { Button } from "./ui/Button";
import { TokenRecord } from "../types/Token";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { bindTokenError, bindTokenSuccess } from "../messages";
import { useKeyDownEnter } from "../hooks/useKeyDownEnter";
import { PublisherRecord } from "../types/Publisher";
import { orderBy, capitalize, differenceBy } from "lodash";
import { SelectStyled } from "./ui/Select";
import { RemovedChip } from "./ui/Chip";
import styled from "styled-components";
import { Divider } from "./ui/Divider";
import { scrollbarLightH } from "./ui/ScrollbarStyle";

const WrapperChip = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 88px;
  overflow-y: overlay;
  ${scrollbarLightH};

  & > div {
    margin-bottom: 8px;
  }
  & > div:not(:last-child) {
    margin-right: 8px;
  }
`;

export const NoPublisherInfo = styled.span`
  font-size: 11px;
  line-height: 16px;
  color: ${props => props.theme.text.dida};

  margin-bottom: 15px;
`;

type Props = DialogableProps & {
  selectedToken: TokenRecord & { publishers: Array<PublisherRecord> };
};

const DialogPlaceholder = (err?: boolean) => (
  <Dialog
    open={true}
    onClose={() => {}}
    titleDialog="Bind Token"
    wrapperStyle={{ width: 400 }}
    buttonSection={
      <>
        <Button disabled undo>
          Undo
        </Button>
        <Button style={{ marginLeft: 10 }} disabled>
          save
        </Button>
      </>
    }
  >
    <SelectStyled
      label="Select new Publisher"
      placeholder="Select publisher"
      value={null}
      onChange={i => {}}
      options={[]}
      isClearable
      isSearchable
    />
    <Divider style={{ margin: "20px 0px" }} />
    <WrapperChip>
      <NoPublisherInfo>{err ? "Oops, something went wrong" : "Loading Publisher..."}</NoPublisherInfo>
    </WrapperChip>
  </Dialog>
);

const DialogBindToken: React.FC<Props> = ({ open, onClose, selectedToken }) => {
  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
  const [addedPublisher, setAddedPublisher] = React.useState<Array<{ value: string; label: string }>>([]);
  const [removedPublisherIds, setRemovedPublisherIds] = React.useState<Array<string>>([]);

  const { data: publishersData, error } = useSWR<{
    publishers: Array<PublisherRecord>;
  }>("/publishers", fetchData);

  if (error) {
    return DialogPlaceholder(error);
  }

  if (!publishersData) {
    return DialogPlaceholder();
  }

  const save = async () => {
    try {
      setLoadingSave(true);
      const addedIds = addedPublisher.map(p => p.value).join(",");
      const removedIds = removedPublisherIds.join(",");

      if (addedPublisher.length > 0) {
        await mutateData("POST", `/tokens/${selectedToken.id}/publisher?publisherIds=${addedIds}`, {});
      }
      if (removedPublisherIds.length > 0) {
        await mutateData("DELETE", `/tokens/${selectedToken.id}/publisher?publisherIds=${removedIds}`, {});
      }
      onOpenSnackbar(bindTokenSuccess);
      trigger("/token-with-publishers");
    } catch (err) {
      onOpenSnackbar(bindTokenError, "error");
    }

    onClose();
  };

  useKeyDownEnter(save);

  const initialPublishers = selectedToken.publishers.map(p => ({ value: p.publisherId, label: p.description }));

  const publishersWithoutAdded = differenceBy(initialPublishers, addedPublisher, "value");

  const publishersWithoutRemoved = differenceBy(
    publishersWithoutAdded,
    removedPublisherIds.map(id => ({ value: String(id) })),
    "value"
  );

  const updatedPublishers = [...addedPublisher, ...publishersWithoutRemoved];

  const allPublisherOptions = orderBy(
    publishersData.publishers.map(p => ({
      value: p.publisherId,
      label: capitalize(p.description)
    })),
    "label"
  );

  const publisherOptionsWithoutSelected = differenceBy(allPublisherOptions, updatedPublishers, "value");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleDialog="Bind Token"
      wrapperStyle={{ width: 400 }}
      buttonSection={
        <>
          <Button onClick={onClose} undo>
            Undo
          </Button>
          <Button onClick={save} style={{ marginLeft: 10 }} loading={loadingSave}>
            Save
          </Button>
        </>
      }
    >
      <div>
        <SelectStyled
          label="Select new Publisher"
          placeholder="Select publisher"
          value={null}
          onChange={(item: any) => {
            const publisherWasRemoved = removedPublisherIds.find(pId => pId === item.value);
            if (publisherWasRemoved) {
              setRemovedPublisherIds(removedIds => removedIds.filter(id => id !== item.value));
            } else {
              setAddedPublisher(addedPublisher => [...addedPublisher, { value: item.value, label: item.label }]);
            }
          }}
          options={publisherOptionsWithoutSelected}
          isClearable
          isSearchable
        />
        <Divider style={{ margin: "20px 0px" }} />
        <WrapperChip style={{ marginBottom: 20 }}>
          {updatedPublishers.length === 0 && (
            <NoPublisherInfo>There are no publishers here, select publisher to add one!</NoPublisherInfo>
          )}
          {updatedPublishers.map((t, i) => (
            <RemovedChip
              onRemove={() => {
                const publisherWasAdded = addedPublisher.find(p => p.value === t.value);
                if (publisherWasAdded) {
                  setAddedPublisher(addedPublisher => addedPublisher.filter(p => p.value !== t.value));
                } else {
                  setRemovedPublisherIds(removedPublisherIds => [...removedPublisherIds, t.value]);
                }
              }}
              label={t.label}
              key={i}
            />
          ))}
        </WrapperChip>
      </div>
    </Dialog>
  );
};

export { DialogBindToken };
