import * as React from "react";
import { Route, Redirect, RouteComponentProps, useLocation } from "react-router";

const PrivateRoute: React.FC<{
  isAuthenticated: Function;
  exact?: boolean;
  path: string;
}> = ({ isAuthenticated, children, ...rest }) => {
  const location = useLocation();

  return (
    <Route {...rest}>
      {isAuthenticated() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location }
          }}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;
