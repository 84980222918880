import * as React from "react";
import styled, { css } from "styled-components";
import { Icon } from "./ui/Icon";
import { Toggle } from "./ui/Toggle";
import { Platform, toStringPlatform } from "../types/Platform";
import { colorTheme } from "../theme";
import { ColorThemeContext } from "../providers/ColorThemeProvider";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 200px;
  position: relative;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 20px;
  border-radius: 12px;
  background: ${props => props.theme.item.background};
  z-index: 1;

  & > div:first-child {
    margin-bottom: 5px;
    font-family: ${props => props.theme.fontFamily.monospace};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Actions = styled.div<{ hover: boolean; new: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: ${props => props.theme.item.actions};
  opacity: ${props => (props.hover ? 1 : 0.3)};
  transition: all 0.3s;

  ${props =>
    props.new &&
    css`
      opacity: 0.6;
      background: ${props => props.theme.item.newItemBackground};
    `}
`;

interface Props {
  publisherDescription: string;
  platform: Platform;
  active: boolean;
  isNewConfiguration: boolean;
  idConfiguration: string;
  onDelete: () => void;
  onChangeActive: () => void;
  onOpenDetailsPanel: () => void;
  onOpenEditPanel: () => void;
  onOpenCloneDialog: () => void;
}

const ConfigurationInfo: React.FC<Props> = ({
  publisherDescription,
  platform,
  active,
  isNewConfiguration,
  idConfiguration,
  onDelete,
  onChangeActive,
  onOpenDetailsPanel,
  onOpenEditPanel,
  onOpenCloneDialog
}) => {
  const [hover, setHover] = React.useState(false);
  const { themeOption } = React.useContext(ColorThemeContext);

  const colorToggleActive = active ? 0.8 : 0.3;

  return (
    <Wrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} id={idConfiguration}>
      <Toggle
        checked={active}
        onClick={onChangeActive}
        size="small"
        style={{
          position: "absolute",
          right: 20,
          top: 32,
          zIndex: 1,
          opacity: hover || isNewConfiguration ? 1 : colorToggleActive
        }}
      />
      <Actions
        hover={hover}
        style={{ borderRadius: "12px 12px 0 0", paddingBottom: 25, bottom: -10, height: 69 }}
        new={isNewConfiguration}
      >
        <Icon name="settings" onClick={onOpenDetailsPanel} color={colorTheme[themeOption].text.primary} />
        {/* <Icon name="drag-drop" color={colorTheme[themeOption].text.primary} /> */}
      </Actions>
      <Info>
        <div>{publisherDescription}</div>
        <div>{toStringPlatform(platform)}</div>
      </Info>
      <Actions
        hover={hover}
        style={{ borderRadius: "0 0 12px 12px", paddingTop: 25, top: -10 }}
        new={isNewConfiguration}
      >
        <Icon name="clone" onClick={onOpenCloneDialog} color={colorTheme[themeOption].text.primary} />
        <Icon name="edit" onClick={onOpenEditPanel} color={colorTheme[themeOption].text.primary} />
        <Icon name="remove" onClick={onDelete} color={colorTheme[themeOption].text.primary} />
      </Actions>
    </Wrapper>
  );
};

export { ConfigurationInfo };
