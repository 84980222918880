import * as React from "react";
import { ActionTitle } from "./ActionTitle";
import { Icon } from "./Icon";
import { colorTheme } from "../../theme";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";

interface Props {
  onClose: () => void;
  title: string;
  styleWrapper?: React.CSSProperties;
}

const TitleAndClose: React.FC<Props> = ({ title, onClose, styleWrapper }) => {
  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...styleWrapper,
        marginBottom: 10
      }}
    >
      <ActionTitle title={title} />
      <Icon
        name="close"
        onClick={onClose}
        style={{
          color: colorTheme[themeOption].text.primary,
          position: "relative",
          right: -10,
          top: -9,
          cursor: "pointer",
          fontSize: 26
        }}
      />
    </div>
  );
};

export { TitleAndClose };
