import * as React from "react";
import styled, { css } from "styled-components";
import { IconNames } from "../../types/IconNames";

interface Props {
  name: IconNames;
  color?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const I = styled.i<{ color?: string; onClick?: () => void; disabled?: boolean }>`
  position: relative;
  color: ${props => (props.color ? props.color : props.theme.icon.normal)};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      :hover {
        color: ${props.theme.icon.hover};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      :hover {
        color: ${props.theme.text.primary}};
      }
    `}
`;

export const Icon: React.FC<Props & React.HTMLProps<HTMLLIElement>> = ({
  name,
  onClick,
  style,
  color,
  disabled,
  className
}) => {
  return (
    <I
      className={`icon-${name} icon-m ${className}`}
      onClick={onClick}
      style={style}
      color={color}
      disabled={disabled}
    />
  );
};
