import * as React from "react";
import {
  ContentIntervalConfiguration,
  ContentChunkConfiguration,
  ContentDailyConfiguration,
  Tier,
  isContentDailyConfiguration,
  isContentChunkConfiguration,
  isContentIntervalConfiguration,
  getTierType,
  TierWithId,
} from "../../types/Configuration";
import { Label } from "../../styles";
import { TierType } from "../../types/TierType";
import { Chip } from "../ui/Chip";
import { capitalize } from "lodash";
import { Divider } from "../ui/Divider";
import { FiniteDurationPanel } from "../ui/FiniteDurationPanel";
import styled from "styled-components";
import { scrollbarLightH } from "../ui/ScrollbarStyle";
import { ButtonLink } from "../ui/ButtonLink";
import { Icon } from "../ui/Icon";
import { DateField } from "../ui/DateField";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";
import { v4 } from "uuid";

type UpdaterFn = (id: string, key: string, value: any) => void;

const IntervalTierPanel: React.FC<{
  id: string;
  value: ContentIntervalConfiguration;
  onChange: UpdaterFn;
}> = ({ id, value, onChange }) => {
  const { contentLifeSince, contentLifeUntil, interval } = value;

  console.log('IntervalTierPanel', id)
  return (
    <>
      <Divider label="Content life since" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={contentLifeSince}
          onChangeLength={(value) => onChange(id, "contentLifeSince.length", value)}
          onChangeUnit={(value) => onChange(id, "contentLifeSince.unit", value)}
        />
      </div>
      <Divider label="Content life until" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={contentLifeUntil}
          onChangeLength={(value) => onChange(id, "contentLifeUntil.length", value)}
          onChangeUnit={(value) => onChange(id, "contentLifeUntil.unit", value)}
        />
      </div>
      <Divider label="Interval" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={interval}
          onChangeLength={(value) => onChange(id, "interval.length", value)}
          onChangeUnit={(value) => onChange(id, "interval.unit", value)}
        />
      </div>
    </>
  );
};

const ChunkTierPanel: React.FC<{
  id: string;
  value: ContentChunkConfiguration;
  onChange: UpdaterFn;
}> = ({ id, value, onChange }) => {
  const { contentLifeSince, contentLifeUntil, interval, chunkInterval, maxAvailableInterval } = value;

  //console.log('ChunkTierPanel', id)
  return (
    <>
      <Divider label="Content life since" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={contentLifeSince}
          onChangeLength={(value) => onChange(id, "contentLifeSince.length", value)}
          onChangeUnit={(value) => onChange(id, "contentLifeSince.unit", value)}
        />
      </div>
      <Divider label="Content life until" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={contentLifeUntil}
          onChangeLength={(value) => onChange(id, "contentLifeUntil.length", value)}
          onChangeUnit={(value) => onChange(id, "contentLifeUntil.unit", value)}
        />
      </div>
      <Divider label="Interval" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={interval}
          onChangeLength={(value) => onChange(id, "interval.length", value)}
          onChangeUnit={(value) => onChange(id, "interval.unit", value)}
        />
      </div>
      <Divider label="Chunk interval" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={chunkInterval}
          onChangeLength={(value) => onChange(id, "chunkInterval.length", value)}
          onChangeUnit={(value) => onChange(id, "chunkInterval.unit", value)}
        />
      </div>
      <Divider label="Max available interval" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={maxAvailableInterval}
          onChangeLength={(value) => onChange(id, "maxAvailableInterval.length", value)}
          onChangeUnit={(value) => onChange(id, "maxAvailableInterval.unit", value)}
        />
      </div>
    </>
  );
};

const DailyTierPanel: React.FC<{
  id: string;
  value: ContentDailyConfiguration;
  onChange: UpdaterFn;
}> = ({ id, value, onChange }) => {
  const { contentLifeSince, contentLifeUntil, chunkInterval, dailyAt, maxAvailableInterval } = value;
  //console.log('DailyTierPanel', id)
  return (
    <>
      <Divider label="Content life since" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={contentLifeSince}
          onChangeLength={(value) => onChange(id, "contentLifeSince.length", value)}
          onChangeUnit={(value) => onChange(id, "contentLifeSince.unit", value)}
        />
      </div>
      <Divider label="Content life until" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={contentLifeUntil}
          onChangeLength={(value) => onChange(id, "contentLifeUntil.length", value)}
          onChangeUnit={(value) => onChange(id, "contentLifeUntil.unit", value)}
        />
      </div>
      <DateField
        label="Daily at"
        onChange={(e) => onChange(id, "dailyAt", e.currentTarget.value)}
        value={dailyAt}
        wrapperStyle={{ marginTop: 15, width: "100%" }}
        type="time"
      />
      <Divider label="Chunk interval" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={chunkInterval}
          onChangeLength={(value) => onChange(id, "chunkInterval.length", value)}
          onChangeUnit={(value) => onChange(id, "chunkInterval.unit", value)}
        />
      </div>
      <Divider label="Max available interval" style={{ marginTop: 20 }} />
      <div style={{ display: "flex", marginTop: 15 }}>
        <FiniteDurationPanel
          duration={maxAvailableInterval}
          onChangeLength={(value) => onChange(id, "maxAvailableInterval.length", value)}
          onChangeUnit={(value) => onChange(id, "maxAvailableInterval.unit", value)}
        />
      </div>
    </>
  );
};

export const getTierEditPanel = (tier: TierWithId, updateField: UpdaterFn) => {
  
  tier.id = v4()
  
  if (isContentDailyConfiguration(tier)) {
    return <DailyTierPanel id={tier.id} value={tier} onChange={updateField} />;
  }

  if (isContentChunkConfiguration(tier)) {
    return <ChunkTierPanel id={tier.id} value={tier} onChange={updateField} />;
  }

  if (isContentIntervalConfiguration(tier)) {
    return <IntervalTierPanel id={tier.id} value={tier} onChange={updateField} />;
  }
};

const TierRulePanel: React.FC<{
  tier: TierWithId;
  removeIcon: boolean;
  onChangeType: (id: string, type: TierType) => void;
  onRemove: () => void;
  onUpdate: (id: string, key: string, value: any) => void;
}> = ({ tier, removeIcon, onChangeType, onRemove, onUpdate }) => {
  const tierType = getTierType(tier);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: 30 }}>
        <Label style={{ margin: 0 }}>Tier type</Label>
        {removeIcon && <Icon name="remove" onClick={onRemove} style={{ right: -8, bottom: 2 }} />}
      </div>
      <div style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }}>
        {Object.values(TierType).map((tt) => (
          <Chip key={tt} selected={tt === tierType} onClick={(_) => onChangeType(tier.id, tt)}>
            {capitalize(tt)}
          </Chip>
        ))}
      </div>
      {getTierEditPanel(tier, onUpdate)}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 300px);
  overflow-y: overlay;
  overflow-x: hidden;
  margin-top: 30px;
  ${scrollbarLightH};
  padding-right: 20px;
`;

interface Props {
  tiers: Array<TierWithId>;
  onAddTier: () => void;
  onRemoveTier: (tierId: string) => void;
  onUpdateTier: (id: string, key: string, value: any) => void;
  onChangeTierType: (id: string, type: TierType) => void;
  disabledButtonAdd: boolean;
}

export const TiersRulePanel: React.FC<Props> = ({
  tiers,
  onRemoveTier,
  onAddTier,
  onUpdateTier,
  onChangeTierType,
  disabledButtonAdd,
}) => {
  const { themeOption } = React.useContext(ColorThemeContext);
  return (
    <Wrapper>
      {tiers.map((tier, i) => (
        <div key={tier.id}>
          <TierRulePanel
            tier={tier}
            removeIcon={tiers.length > 1}
            onChangeType={onChangeTierType}
            onRemove={() => onRemoveTier(tier.id)}
            onUpdate={onUpdateTier}
          />
          {i < tiers.length - 1 && <Divider style={{ marginTop: 30, marginBottom: 20 }} />}
        </div>
      ))}
      <ButtonLink
        iconName="add"
        label="Add tier"
        style={{ marginTop: 20 }}
        color={colorTheme[themeOption].text.primary}
        disabled={disabledButtonAdd}
        onClick={onAddTier}
      />
    </Wrapper>
  );
};

export { TierRulePanel };
