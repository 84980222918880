import * as React from "react";
import { v4 } from "uuid";
import styled, { css } from "styled-components";
import { createPortal } from "react-dom";
import { useKeyUpEsc } from "../../hooks/useKeyUpEsc";
import { TitleAndClose } from "./TitleAndClose";

const Bkg = styled.div<{ open: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
`;

const Wrapper = styled.div<{ fullScreen?: boolean }>`
  min-width: 260px;
  min-height: 180px;
  padding: 20px;
  background: ${props => props.theme.background.portal};
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  touch-action: none;
  display: flex;
  flex-direction: column;

  ${props =>
    props.fullScreen &&
    css`
      width: 100vw;
      height: 100vh;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TextDialog = styled.p`
  font-size: 12px;
  line-height: 16px;
`;

export type DialogableProps = {
  open: boolean;
  onClose: () => void;
};

type Props = DialogableProps & {
  titleDialog?: string;
  buttonSection: JSX.Element;
  wrapperStyle?: React.CSSProperties;
};

export const Dialog: React.FC<Props> = ({ open, onClose, titleDialog, buttonSection, wrapperStyle, children }) => {
  const el = React.useRef<HTMLDivElement>(document.createElement("div"));

  React.useEffect(() => {
    el.current.setAttribute("id", v4());

    document.body.appendChild(el.current);

    return () => {
      document.body.removeChild(el.current);
    };
  }, []);

  useKeyUpEsc(onClose);

  return createPortal(
    <Bkg open={open} onClick={onClose}>
      <Wrapper onClick={(e: any) => e.stopPropagation()} style={wrapperStyle}>
        {titleDialog && <TitleAndClose title={titleDialog} onClose={onClose} />}
        {children}
        <ButtonWrapper>{buttonSection}</ButtonWrapper>
      </Wrapper>
    </Bkg>,
    el.current
  );
};
