import { css } from "styled-components";

const scrollbarDarkH = css`
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 15px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  ::-webkit-scrollbar-button:vertical {
    background-color: transparent;
    display: block;
    height: 8px;
  }

  ::-webkit-scrollbar-button:horizontal {
    background-color: transparent;
    display: block;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.item.background};
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 5px ${props => props.theme.item.actions};
  }
`;

const scrollbarLightH = css`
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 15px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  ::-webkit-scrollbar-button:vertical {
    background-color: transparent;
    display: block;
    height: 8px;
  }

  ::-webkit-scrollbar-button:horizontal {
    background-color: transparent;
    display: block;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.chip.hover};
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 5px ${props => props.theme.icon.normal};
  }
`;

export { scrollbarDarkH, scrollbarLightH };
