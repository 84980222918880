import * as React from "react";
import { ThemeOption } from "../theme";

const ColorThemeContext = React.createContext<{
  themeOption: ThemeOption;
  setThemeOption: (theme: ThemeOption) => void;
}>({
  themeOption: localStorage.theme || "darkTheme",
  setThemeOption: (theme: ThemeOption) => {}
});

const ColorThemeProvider: React.FC = ({ children }) => {
  const [themeOption, setThemeOption] = React.useState<ThemeOption>(localStorage.theme || "darkTheme");

  React.useEffect(() => {
    localStorage.setItem("theme", themeOption);
  }, [themeOption]);

  return <ColorThemeContext.Provider value={{ themeOption, setThemeOption }}>{children}</ColorThemeContext.Provider>;
};

export { ColorThemeContext, ColorThemeProvider };
