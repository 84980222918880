import * as React from "react";
import { Input } from "./ui/Input";
import styled from "styled-components";
import { DateField } from "./ui/DateField";
import { TikTokToken } from "../types/Token";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = TikTokToken & {
  updateValue: (key: string, value: any) => void;
};

export const TikTokTokenEditPanel: React.FC<Props> = ({
  accessToken,
  clientId,
  clientSecret,
  refreshToken,
  expiryDate,
  expiryRefreshToken,
  updateValue,


}) => (
  <Wrapper>
    <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 20 }}>
      <div style={{ flexBasis: "50%", paddingRight: 15 }}>
        <Input
          placeholder="Access token"
          label="access token"
          value={accessToken}
          onChange={(e) => updateValue("accessToken", e.currentTarget.value)}
        />
      </div>
      <div style={{ flexBasis: "50%" , paddingRight: 15, marginTop: 10}}>
        <Input
          placeholder="Refresh token"
          label="refresh token"
          value={refreshToken}
          onChange={(e) => updateValue("refreshToken", e.currentTarget.value)}
        />
      </div>
      <div style={{ flexBasis: "50%", paddingRight: 15, marginTop: 10}}>
        <Input
          placeholder="Client id"
          label="client id"
          value={clientId}
          onChange={(e) => updateValue("clientId", e.currentTarget.value)}
        />
      </div>
      <div style={{ flexBasis: "50%", paddingRight: 15, marginTop: 10 }}>
        <Input
          placeholder="Client secret"
          label="client secret"
          value={clientSecret}
          onChange={(e) => updateValue("clientSecret", e.currentTarget.value)}
        />
      </div>
      <div style={{ width: "50%", paddingRight: 15, marginTop: 10 }}>
        <DateField
          label="Expiry date"
          onChange={(e) => {
            updateValue("expiryDate", e.currentTarget.value);
          }}
          value={expiryDate}
        />
      </div>
      <div style={{ width: "50%",  marginTop: 10 }}>
        <DateField
          label="Expiry Refresh Token"
          onChange={(e) => {
            updateValue("expiryRefreshToken", e.currentTarget.value);
          }}
          value={expiryRefreshToken!}
        />
      </div>
     
    </div>
  </Wrapper>
);
