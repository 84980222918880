import * as React from "react";
import { ConfigurationInfo } from "./ConfigurationInfo";
import { FullConfiguration } from "../types/views";
import styled from "styled-components";
import { Divider } from "./ui/Divider";
import { CountInfo } from "../styles";
import { capitalize } from "lodash";
import { scrollbarDarkH } from "./ui/ScrollbarStyle";
import { colorTheme } from "../theme";
import { ColorThemeContext } from "../providers/ColorThemeProvider";

interface Props {
  configurations: Array<FullConfiguration>;
  onDelete: (configuration: FullConfiguration) => void;
  onChangeActive: (configuration: FullConfiguration) => void;
  onOpenDetailsPanel: (configuration: FullConfiguration) => void;
  onOpenEditPanel: (configuration: FullConfiguration) => void;
  onOpenCloneDialog: (configuration: FullConfiguration) => void;
  lastAddedItemId: number;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  row-gap: 10px;
  overflow-y: scroll;
  justify-items: center;

  @media (max-width: 1600px) {
    grid-template-columns: 25% 25% 25% 25%;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 33% 33% 33%;
  }

  @media (max-width: 1150px) {
    grid-template-columns: 50% 50%;
  }
`;

const WrapperGrid = styled.div`
  height: 100%;
  overflow-y: overlay;
  padding: 0px 10px;
  ${scrollbarDarkH};
`;

const ConfigurationsPanel: React.FC<Props> = ({
  configurations,
  onDelete,
  onChangeActive,
  onOpenDetailsPanel,
  onOpenEditPanel,
  onOpenCloneDialog,
  lastAddedItemId
}) => {
  const pathname = location.pathname.substring(1);

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <>
      <WrapperGrid>
        <Grid data-testid="configurations">
          {configurations.map((c, i) => (
            <ConfigurationInfo
              key={i}
              publisherDescription={c.publisher.description}
              platform={c.platform}
              onDelete={() => onDelete(c)}
              active={c.active}
              idConfiguration={String(c.id)}
              isNewConfiguration={lastAddedItemId === c.id}
              onChangeActive={() => onChangeActive(c)}
              onOpenDetailsPanel={() => onOpenDetailsPanel(c)}
              onOpenEditPanel={() => onOpenEditPanel(c)}
              onOpenCloneDialog={() => onOpenCloneDialog(c)}
            />
          ))}
        </Grid>
      </WrapperGrid>
      <div>
        <Divider color={colorTheme[themeOption].text.primary} style={{ marginBottom: 3 }} />
        <CountInfo>
          {configurations.length} {pathname.length > 0 ? capitalize(pathname) : "Publisher"}
        </CountInfo>
      </div>
    </>
  );
};

export { ConfigurationsPanel };
