import * as React from "react";
import styled from "styled-components";
import { IconPlaceholder, TextPlaceholder } from "./Placeholder";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background: var(--black);
  align-items: center;
  border-radius: 12px;
  width: calc(100% - 20px);
  left: 10px;
  padding: 0px 18px;
  opacity: 20%;
`;

const InfoWrapper = styled.div`
  height: 50px;
  width: calc(100% - 55px - 110px);
  background: var(--medium-grey);
  border-radius: 12px;
  padding: 0 20px;
  z-index: 1;
  margin-left: 18px;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 55%;
`;

export const ItemPlaceholder: React.FC = () => {
  return (
    <Wrapper>
      <IconPlaceholder />
      <InfoWrapper>
        <TextPlaceholder width={10} />
        <TextPlaceholder width={30} />
        <TextPlaceholder width={50} />
      </InfoWrapper>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconPlaceholder style={{ marginRight: 20 }} />
        <IconPlaceholder style={{ marginRight: 20 }} />
        <IconPlaceholder />
      </div>
    </Wrapper>
  );
};
