import * as React from "react";
import styled from "styled-components";
import { Icon } from "../ui/Icon";
import { colorTheme } from "../../theme";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";

const Badge = styled.div`
  position: absolute;
  right: 5px;
  top: 6px;
  border-radius: 100px;
  height: 22px;
  background: ${props => props.theme.error};
  font-size: 10px;
  font-family: ${props => props.theme.fontFamily.monospace};
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 8px;
  user-select: none;
`;

const StyledIcon = styled(Icon)`
  font-size: 24px;
  position: relative;
  top: -1px;

  &::before {
    margin: 0 !important;
    width: 24px;
  }
`;

const ErrorBadge: React.FC<{ rep: number }> = ({ rep }) => {
  const { themeOption } = React.useContext(ColorThemeContext);
  return (
    <Badge>
      <span>{rep} rep</span>
      <StyledIcon name="snackbar-caution" color={colorTheme[themeOption].background.primary} />
    </Badge>
  );
};

export { ErrorBadge };
