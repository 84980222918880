import * as React from "react";
import { Info } from "../../styles";
import styled from "styled-components";
import {
  Tier,
  TierWithId,
  isContentDailyConfiguration,
  isContentChunkConfiguration,
  Rule,
  isScheduleDailyConfiguration,
  isScheduleTiersConfiguration,
} from "../../types/Configuration";
import { capitalize } from "lodash";
import { Divider } from "../ui/Divider";
import { colorTheme } from "../../theme";

export const DetailText = styled.p`
  font-size: 12px;
`;

export const TitleKey = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
`;

export const GridInfo = styled.div<{ lastRow?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, auto));
  margin-bottom: ${(props) => (props.lastRow ? 0 : 6)};
`;

interface Props {
  label: string;
  value: string | null;
  titleSection?: string;
  lastRow?: boolean;
}

export const RowDetail: React.FC<Props> = ({ titleSection, label, value, lastRow }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: lastRow ? 0 : 10 }}>
      {titleSection && <Info style={{ marginBottom: 6 }}>{capitalize(titleSection)}</Info>}
      <GridInfo lastRow={lastRow}>
        <TitleKey>{label}</TitleKey>
        <span>{value}</span>
      </GridInfo>
    </div>
  );
};

export const getTierInfo = (tier: TierWithId) => {
  if (isContentDailyConfiguration(tier)) {
    return (
      <>
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontFamily: colorTheme[localStorage.theme].fontFamily.monospace, fontWeight: 500 }}>
            Tier type:
          </span>{" "}
          Daily
        </div>
        <RowDetail
          titleSection="Content life since"
          label="Length & unit"
          value={`${tier.contentLifeSince.length} ${tier.contentLifeSince.unit}`}
        />
        <RowDetail
          titleSection="Content life until"
          label="Length & unit"
          value={`${tier.contentLifeUntil.length} ${tier.contentLifeUntil.unit}`}
        />
        <RowDetail titleSection="Daily at" label="Daily" value={tier.dailyAt} />
        <RowDetail
          titleSection="Chunk interval"
          label="Length & unit"
          value={`${tier.chunkInterval.length} ${tier.chunkInterval.unit}`}
        />
        <RowDetail
          titleSection="Max available interval"
          label="Length & unit"
          value={`${tier.maxAvailableInterval.length} ${tier.maxAvailableInterval.unit}`}
          lastRow
        />
      </>
    );
  }

  if (isContentChunkConfiguration(tier)) {
    return (
      <>
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontFamily: colorTheme[localStorage.theme].fontFamily.monospace, fontWeight: 500 }}>
            Tier type:
          </span>{" "}
          Chunk
        </div>
        <RowDetail
          titleSection="Content life since"
          label="Length & unit"
          value={`${tier.contentLifeSince.length} ${tier.contentLifeSince.unit}`}
        />
        <RowDetail
          titleSection="Content life until"
          label="Length & unit"
          value={`${tier.contentLifeUntil.length} ${tier.contentLifeUntil.unit}`}
        />
        <RowDetail
          titleSection="Interval"
          label="Length & unit"
          value={`${tier.interval.length} ${tier.interval.unit}`}
        />
        <RowDetail
          titleSection="Chunk interval"
          label="Length & unit"
          value={`${tier.chunkInterval.length} ${tier.chunkInterval.unit}`}
          lastRow={!tier.maxAvailableInterval.length}
        />
        {tier.maxAvailableInterval.length && tier.maxAvailableInterval.unit && (
          <RowDetail
            titleSection="Max available interval"
            label="Length & unit"
            value={`${tier.maxAvailableInterval.length} ${tier.maxAvailableInterval.unit}`}
            lastRow
          />
        )}
      </>
    );
  }

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontFamily: colorTheme[localStorage.theme].fontFamily.monospace, fontWeight: 500 }}>
          Tier type:
        </span>{" "}
        Interval
      </div>
      <RowDetail
        titleSection="Content life since"
        label="Length & unit"
        value={`${tier.contentLifeSince.length} ${tier.contentLifeSince.unit}`}
      />
      <RowDetail
        titleSection="Content life until"
        label="Length & unit"
        value={`${tier.contentLifeUntil.length} ${tier.contentLifeUntil.unit}`}
      />
      <RowDetail
        titleSection="Interval"
        label="Length & unit"
        value={`${tier.interval.length} ${tier.interval.unit}`}
        lastRow
      />
    </>
  );
};

export const getAdditionalInfo = (rule: Rule) => {
  if (isScheduleDailyConfiguration(rule)) {
    return <RowDetail label="Daily" value={rule.dailyAt} />;
  }
  if (isScheduleTiersConfiguration(rule)) {
    const tiers = rule.tiers as Array<TierWithId>;
    return (
      <>
        {tiers.map((tier, i) => (
          <div key={i}>
            {getTierInfo(tier)}
            {i < rule.tiers.length - 1 && <Divider style={{ margin: "15px 0px" }} />}
          </div>
        ))}
      </>
    );
  }

  return (
    <RowDetail titleSection="Interval" label="Length & unit" value={`${rule.interval.length} ${rule.interval.unit}`} />
  );
};
