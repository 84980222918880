import * as React from "react";
import { Platform } from "../types/Platform";
import { Order } from "../components/ui/IconOrderBy";

interface ActorStateFilterState {
  description: string;
  platform: Platform | null;
  order: Order;
  orderByLabel: string | null;
}

interface Callbacks {
  setDescription: (value: string) => void;
  setPlatform: (value: Platform | null) => void;
  setOrder: (order: Order) => void;
  setOrderByLabel: (orderBy: string | null) => void;
  clearFilter: () => void;
}

const initialState: ActorStateFilterState = {
  description: "",
  platform: null,
  order: "asc",
  orderByLabel: "description"
};

const callbacks: Callbacks = {
  setDescription: () => {},
  setPlatform: () => {},
  setOrder: () => {},
  setOrderByLabel: () => {},
  clearFilter: () => {}
};

const ActorStateFilterContext = React.createContext<ActorStateFilterState & Callbacks>({
  ...initialState,
  ...callbacks
});

const ActorStateFilterProvider: React.FC = ({ children }) => {
  const [description, setDescription] = React.useState("");
  const [platform, setPlatform] = React.useState<Platform | null>(null);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderByLabel, setOrderByLabel] = React.useState<string | null>("invocationManager.publisher.description");

  const clearFilter = () => {
    setDescription("");
    setPlatform(null);
    setOrder("asc");
    setOrderByLabel("invocationManager.publisher.description");
  };

  return (
    <ActorStateFilterContext.Provider
      value={{
        description,
        setDescription,
        platform,
        setPlatform,
        clearFilter,
        order,
        setOrder,
        orderByLabel,
        setOrderByLabel
      }}
    >
      {children}
    </ActorStateFilterContext.Provider>
  );
};

export { ActorStateFilterContext, ActorStateFilterProvider };
