import * as React from "react";
import styled, { css } from "styled-components";
import { infoItemTextStyle } from "../styles";
import { CSSTransition } from "react-transition-group";
import { Icon } from "./ui/Icon";
import { ActionsItem } from "../types/ActionsItem";
import { Divider } from "./ui/Divider";
import { ColorThemeContext } from "../providers/ColorThemeProvider";
import { colorTheme } from "../theme";

// const Dnd = styled.div<{ hover: boolean; newItem: boolean; showAdditionalInfo: boolean }>`
//   position: relative;
//   display: flex;
//   align-items: center;
//   background: ${props => props.theme.item.actions};
//   opacity: ${props => (props.hover ? 1 : 0.3)};
//   transition: all 0.3s;

//   padding: 0 12px;
//   padding-right: 20px;
//   align-items: center;
//   border-radius: 12px 0 0 12px;
//   height: 50px;
//   right: -8px;
//   align-self: flex-start;

//   ${props =>
//     props.newItem &&
//     css`
//       opacity: 0.8;
//       background: ${props => props.theme.item.newItemBackground};
//     `}

//   ${props =>
//     props.showAdditionalInfo &&
//     css`
//       opacity: 1;
//     `}
// `;

const Actions = styled.div<{ hover: boolean; newItem: boolean; showAdditionalInfo: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  background: ${props => props.theme.item.actions};
  opacity: ${props => (props.hover ? 1 : 0.3)};
  transition: all 0.3s;

  padding: 0 12px;
  padding-left: 20px;
  align-items: center;
  border-radius: 0 12px 12px 0;
  height: 50px;
  left: -8px;
  align-self: flex-start;

  ${props =>
    props.newItem &&
    css`
      opacity: 0.8;
      background: ${props => props.theme.item.newItemBackground};
    `}

  ${props =>
    props.showAdditionalInfo &&
    css`
      opacity: 1;
    `}
`;

const Wrapper = styled.div`
  position: relative;
  ${infoItemTextStyle}
  display: flex;

  align-items: center;
  border-radius: 12px;
  width: 100%;
`;

const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  & > i {
    padding-right: 5px;
  }
`;

const InfoWrapper = styled.div<{ showAdditionalInfo: boolean }>`
  max-height: ${props => (props.showAdditionalInfo ? "300px" : "50px")};
  width: calc(100% - 55px);
  background: ${props => props.theme.item.background};
  border-radius: 12px;
  padding: 0 20px;
  transition: all 0.3s;
  z-index: 1;
  color: ${props => props.theme.text.primary};

  ${props =>
    props.showAdditionalInfo &&
    css`
      padding-bottom: 20px;
    `}
`;

const BasicInfo = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
`;

const Column = styled.div<{ flexBasis: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
  flex-basis: ${props => `${props.flexBasis}%`};
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 0.2s;
  opacity: 1;

  &.wrapper-entering {
    opacity: 0.5;
  }
  &.wrapper-entered {
    opacity: 1;
  }
  &.wrapper-exiting {
    opacity: 0;
  }
  &.wrapper-exited {
    opacity: 0;
  }
`;

interface Props {
  columns: Array<{ content: string | JSX.Element; percentage: number }>;
  additionalInfo?: React.ReactElement;
  onEdit?: () => void;
  onDelete?: () => void;
  onShow?: () => void;
  newItem: boolean;
  idItem: string;
  disabledShowIcon?: boolean;
  onBind?: () => void;
}

const ItemInfo: React.FC<Props> = ({
  columns,
  onEdit,
  onShow,
  onDelete,
  additionalInfo,
  newItem,
  idItem,
  disabledShowIcon,
  onBind
}) => {
  const [hover, setHover] = React.useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = React.useState(false);

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <Wrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} id={idItem}>
      {/* <Dnd hover={hover} newItem={newItem} showAdditionalInfo={showAdditionalInfo}>
        <Icon name="drag-drop" style={{ cursor: "grab" }} color={colorTheme[themeOption].text.primary} />
      </Dnd> */}
      <InfoWrapper showAdditionalInfo={showAdditionalInfo}>
        <BasicInfo data-testid="basic-info">
          {columns.map((c, i) => (
            <Column key={i} flexBasis={c.percentage}>
              {c.content}
            </Column>
          ))}
        </BasicInfo>
        <CSSTransition in={showAdditionalInfo} timeout={{ enter: 100, exit: 300 }} unmountOnExit>
          {status => (
            <AdditionalInfo className={`wrapper-${status}`}>
              <Divider style={{ marginBottom: 15 }} />
              {additionalInfo}
            </AdditionalInfo>
          )}
        </CSSTransition>
      </InfoWrapper>
      <Actions hover={hover} newItem={newItem} showAdditionalInfo={showAdditionalInfo}>
        <ActionIcons>
          {onBind && <Icon name="bind" onClick={onBind} color={colorTheme[themeOption].text.primary} />}
          {additionalInfo && (
            <Icon
              name={showAdditionalInfo ? "reduce" : "show"}
              color={showAdditionalInfo ? colorTheme[themeOption].primary : colorTheme[themeOption].text.primary}
              onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
            />
          )}
          {onEdit && (
            <Icon name="edit" style={{ top: -1 }} onClick={onEdit} color={colorTheme[themeOption].text.primary} />
          )}
          {onShow && (
            <Icon
              name="view"
              onClick={() => !disabledShowIcon && onShow()}
              disabled={disabledShowIcon}
              style={{ opacity: disabledShowIcon ? 0.3 : "" }}
              color={colorTheme[themeOption].text.primary}
            />
          )}
          {onDelete && <Icon name="remove" onClick={onDelete} color={colorTheme[themeOption].text.primary} />}
        </ActionIcons>
      </Actions>
    </Wrapper>
  );
};

export { ItemInfo };
