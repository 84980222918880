import * as React from "react";
import styled, { css } from "styled-components";
import { IconOrderBy, Order } from "../components/ui/IconOrderBy";
import { ItemInfo } from "./ItemInfo";
import { headerTextStyle, CountInfo } from "../styles";
import { Divider } from "./ui/Divider";
import { capitalize, get } from "lodash";
import { scrollbarDarkH } from "../components/ui/ScrollbarStyle";
import { ActionsItem } from "../types/ActionsItem";
import { ColorThemeContext } from "../providers/ColorThemeProvider";
import { colorTheme } from "../theme";

const Records = styled.div`
  & > div {
    margin-bottom: 5px;
  }

  height: 95%;
  overflow-y: overlay;
  padding: 0px 10px;

  ${scrollbarDarkH}
`;

const Header = styled.div<{ actions: ActionsItem }>`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: ${({ actions }) => `calc(100% - ${52 + Object.values(actions).filter(ai => Boolean(ai)).length * 36}px)`};
  padding: 0 20px;

  ${headerTextStyle};
`;

const Th = styled.div<{ flexBasis: number; sortable: boolean }>`
  flex-basis: ${props => `${props.flexBasis}%`};
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
  white-space: nowrap;
  height: 40px;
  position: relative;
  user-select: none;

  ${props =>
    props.sortable &&
    css`
      cursor: pointer;
    `}
  display: flex;
  align-items: center;
`;

const ItemsPanel: React.FC<{
  columns: Array<{
    accessor: string;
    label?: string;
    percentage: number;
    sortable: boolean;
  }>;
  items: Array<any>;
  idField: string;
  order: Order;
  orderByLabel: string | null;
  onChangeOrder: (order: Order) => void;
  onChangeOrderBy: (orderBy: string) => void;
  disabledShowIcons?: Array<boolean>;
} & ActionsItem> = ({
  columns,
  items,
  idField,
  order,
  orderByLabel,
  onChangeOrder,
  onChangeOrderBy,
  disabledShowIcons,
  ...actionItems
}) => {
  const pathname = location.pathname.substring(1);
  const { onDelete, onEdit, renderAdditionalInfo, onShow, onBind } = actionItems;

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <>
      <Header actions={{ ...actionItems }}>
        {/* <div style={{ width: 85, visibility: "hidden" }}></div> */}
        <div style={{ width: 11, visibility: "hidden" }}></div>
        {columns.map((c, i) => (
          <Th
            key={i}
            flexBasis={c.percentage}
            onClick={() => {
              if (c.sortable) {
                onChangeOrderBy(c.accessor);
                if (order === "asc") {
                  onChangeOrder("desc");
                } else {
                  onChangeOrder("asc");
                }
              }
            }}
            sortable={c.sortable}
          >
            <div style={{ display: "flex", justifyContent: "center", textOverflow: "ellipsis", overflow: "hidden" }}>
              <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{c.label}</span>
              <IconOrderBy order={order} show={c.sortable && orderByLabel === c.accessor} />
            </div>
          </Th>
        ))}
      </Header>
      <Records data-testid="items">
        {items.map((i, index) => {
          const disabledShow = disabledShowIcons ? disabledShowIcons[index] : false;
          return (
            <ItemInfo
              key={index}
              onEdit={onEdit && (() => onEdit(i))}
              onDelete={onDelete && (() => onDelete(i))}
              onShow={onShow && (() => onShow(i))}
              onBind={onBind && (() => onBind(i))}
              idItem={i.id}
              newItem={i.new}
              columns={columns.map(c => ({
                content: get(i, c.accessor),
                percentage: c.percentage
              }))}
              additionalInfo={renderAdditionalInfo && renderAdditionalInfo(i)}
              disabledShowIcon={disabledShow}
            />
          );
        })}
      </Records>
      <div>
        <Divider color={colorTheme[themeOption].text.primary} style={{ marginBottom: 3 }} />
        <CountInfo>
          {items.length} {pathname.length > 0 ? capitalize(pathname).replace("-", " ") : "Publisher"}
        </CountInfo>
      </div>
    </>
  );
};

export { ItemsPanel };
