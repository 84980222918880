import * as React from "react";
import styled from "styled-components";
import { useLocation, useHistory, NavLink as L } from "react-router-dom";
import { Icon } from "./components/ui/Icon";
import { ColorThemeContext } from "./providers/ColorThemeProvider";
import { colorTheme } from "./theme";

const NavLink = styled(L)`
  text-decoration: none;
  &:hover > i {
    color: ${props => props.theme.icon.hover};
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0px 20px;
  width: 60px;
  height: 100%;
  background: ${props => props.theme.background.secondary};
  margin-top: 3px;
  z-index: 10;
  justify-content: space-between;
`;

const UserImg = styled.div`
  font-size: 25px;
  user-select: none;
  cursor: pointer;
`;

const emoji: { [k: string]: string } = {
  "l.bianchi": "🐙",
  "a.gallitano": "👾",
  "s.perazzolo": "🐴",
  "f.frontera": "👨🏻",
  "p.bongiovanni": "💰",
  "d.pellegrini": "🏋🏻‍♂️",
  "d.greco": "🧓🏻",
  "s.armenes": "👨🏻‍🚀"
};

export const Sidebar: React.FC<{ addPanel: boolean }> = ({ addPanel }) => {
  const location = useLocation();
  const history = useHistory();

  const { themeOption } = React.useContext(ColorThemeContext);

  const isAdding = location.search.indexOf("?add") >= 0;
  const isUser = location.search.indexOf("?user") >= 0;

  return (
    <SidebarWrapper>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <NavLink style={{ marginBottom: 30 }} to={location.pathname}>
          <Icon name="filter" color={!isAdding && !isUser ? colorTheme[themeOption].primary : ""} />
        </NavLink>
        {addPanel && (
          <Icon
            name="add"
            color={isAdding ? colorTheme[themeOption].primary : ""}
            onClick={() => {
              history.push({
                pathname: location.pathname,
                search: "?add"
              });
            }}
          />
        )}
      </div>
      <UserImg
        onClick={() => {
          history.push({
            pathname: location.pathname,
            search: "?user"
          });
        }}
      >
        {emoji[localStorage.getItem("username")!] || "👨🏾‍💻"}
      </UserImg>
    </SidebarWrapper>
  );
};
