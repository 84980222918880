import * as React from "react";
import { v4 } from "uuid";
import styled, { css } from "styled-components";
import { Label } from "../../styles";
import { capitalize } from "lodash";

type Size = "small" | "medium";

interface Props {
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  size?: Size;
  label?: string;
}

const WrapperLabel = styled.label`
  & > input {
    display: none;
  }
`;

const SliderRound = styled.div<{
  disabled?: boolean;
  checked: boolean;
  size?: Size;
}>`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  background: ${props => props.theme.toggle.background};

  opacity: ${props => (props.disabled ? 0.5 : 1)};

  transition: .3s;
  border-radius: 50px;

  &:before {
    content: "";
    background: ${props => props.theme.toggle.inactive};
    position: absolute;
    transform: translateX(0px);
    transition: .4s cubic-bezier(0.640, -0.250, 0.290, 1.270);
    border-radius: 50%;
  }

  ${props =>
    props.size === "small" &&
    css`
      width: 32px;
      height: 16px;

      &:before {
        left: -2px;
        bottom: -3px;
        width: 22px;
        height: 22px;
      }
    `}

  ${props =>
    props.size === "medium" &&
    css`
      width: 38px;
      height: 19px;

      &:before {
        left: -2px;
        bottom: -3px;
        width: 26px;
        height: 26px;
      }
    `}

  ${props =>
    props.checked &&
    css`
      &:before {
        background: ${props.theme.toggle.active};
        left: ${props.size === "medium" ? "-5px" : "2px"};
        transform: ${props.size === "medium" ? "translateX(19px)" : "translateX(10px)"};
      }
    `}
`;

export const Toggle: React.FC<Props> = ({ checked, onClick, disabled, style, size, label }) => {
  const id = React.useRef(v4());

  return (
    <div style={{ ...style, display: "flex", flexDirection: "column" }}>
      {label && (
        <Label htmlFor={id.current} style={{ cursor: disabled ? "default" : "pointer" }}>
          {capitalize(label)}
        </Label>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: size === "medium" ? 38 : 16,
          position: "relative",
          left: size === "medium" ? 4 : 2
        }}
      >
        <WrapperLabel htmlFor={id.current}>
          <input type="checkbox" id={id.current} defaultChecked={checked} disabled={disabled} onClick={onClick} />
          <SliderRound checked={checked} disabled={disabled} size={size} />
        </WrapperLabel>
      </div>
    </div>
  );
};

Toggle.defaultProps = {
  size: "small"
};
