import * as React from "react";
import { Platform } from "../types/Platform";
import { Option } from "../components/ActiveFilter";

interface ConfigurationFilterState {
  platform: Platform | null;
  active: Option;
}

interface Callbacks {
  setPlatform: (value: Platform | null) => void;
  setActive: (value: Option) => void;
  clearFilter: () => void;
}

const initialState: ConfigurationFilterState = {
  platform: null,
  active: "all"
};

const callbacks: Callbacks = {
  setPlatform: () => {},
  setActive: () => {},
  clearFilter: () => {}
};

const ConfigurationFilterContext = React.createContext<ConfigurationFilterState & Callbacks>({
  ...initialState,
  ...callbacks
});

const ConfigurationFilterProvider: React.FC = ({ children }) => {
  const [platform, setPlatform] = React.useState<Platform | null>(null);
  const [publisher, setPublisher] = React.useState(null);
  const [active, setActive] = React.useState<Option>("all");

  const clearFilter = () => {
    setPlatform(null);
    setPublisher(null);
    setActive("all");
  };

  return (
    <ConfigurationFilterContext.Provider
      value={{
        platform,
        setPlatform,
        active,
        setActive,
        clearFilter
      }}
    >
      {children}
    </ConfigurationFilterContext.Provider>
  );
};

export { ConfigurationFilterContext, ConfigurationFilterProvider };
