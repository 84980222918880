import * as React from "react";
import Select, { Styles } from "react-select";
import { Label } from "../../styles";
import { capitalize } from "lodash";
import { v4 } from "uuid";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";
import { DefaultTheme } from "styled-components";

const buildSelectStyles = (theme: DefaultTheme): Styles => ({
  indicatorSeparator: (provided, _) => ({
    display: "none",
  }),
  control: (provided, _) => ({
    ...provided,
    background: theme.select.background,
    borderRadius: 0,
    border: "none",
    position: "relative",
    left: 1,
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: theme.fontFamily.sansSerif,
    border: "none",
    fontSize: 12,
    color: theme.select.text,
    opacity: state.isSelected ? 1 : 0.4,
    background: "transparent",
    padding: 12,
    "&:hover": {
      color: theme.select.hover,
      opacity: 1,
    },
  }),
  singleValue: (provided, _) => ({
    ...provided,
    fontFamily: theme.fontFamily.sansSerif,
    fontSize: 12,
  }),
  placeholder: (provided, _) => ({
    ...provided,
    color: theme.select.textOpacity,
    fontFamily: theme.fontFamily.sansSerif,
    fontSize: 12,
  }),
  menu: (provided, _) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 2,
    marginTop: 2,
    boxShadow: `0px 0px 4px -1px ${theme.select.textOpacity}`,
    left: 1,
  }),
  clearIndicator: (provided, _) => ({
    ...provided,
    color: theme.select.details,
    position: "relative",
    left: 12,
  }),
  dropdownIndicator: (provided, _) => ({
    ...provided,
    color: theme.select.details,
  }),
  menuList: (provided, _) => ({
    ...provided,
  }),
  noOptionsMessage: (provided, _) => ({}),
});

interface Option {
  value: string;
  label: string;
}

interface Props {
  value: Option | null;
  onChange: (value: { value: string; label: string }) => void;
  options: Array<Option>;
  label: string;
  placeholder: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  required?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const SelectStyled: React.FC<Props> = ({
  value,
  onChange,
  options,
  label,
  placeholder,
  isSearchable,
  required,
  isClearable,
  disabled,
  style,
}) => {
  const id = React.useRef(v4());
  const { themeOption } = React.useContext(ColorThemeContext);

  const theme = colorTheme[themeOption];

  return (
    <div style={{ display: "flex", flexDirection: "column-reverse", ...style, opacity: disabled ? 0.5 : 1 }}>
      <Select
        inputId={id.current}
        placeholder={capitalize(placeholder)}
        isSearchable={isSearchable ? isSearchable : false}
        styles={buildSelectStyles(theme)}
        value={value}
        onChange={onChange}
        options={options}
        required={required}
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {/* TODO: how to handle required? */}
      <Label htmlFor={id.current}>{capitalize(label)}</Label>
    </div>
  );
};
