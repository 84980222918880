import * as React from "react";
import { v4 } from "uuid";
import styled, { css } from "styled-components";
import { Icon } from "./Icon";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";

interface Props {
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
  label: string;
}

const Check = styled.div<
  {
    disabled?: boolean;
    checked?: boolean;
  } & React.HTMLProps<HTMLDivElement>
>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 2px;
  border-color: ${props => props.theme.icon.normal};
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: transparent;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  user-select: none;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const Label = styled.label<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  user-select: none;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  font-family: ${props => props.theme.fontFamily.sansSerif};
  font-size: 11px;
  margin-left: 8px;
  letter-spacing: 0.5px;
`;

export const Checkbox: React.FC<Props> = ({ checked, onClick, disabled, label }) => {
  const { themeOption } = React.useContext(ColorThemeContext);
  const htmlFor = React.useRef(v4());

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Check
        disabled={disabled}
        checked={checked}
        onClick={e => {
          e.stopPropagation();
          !disabled && onClick();
        }}
      >
        <input
          id={htmlFor.current}
          style={{ display: "none" }}
          type="checkbox"
          defaultChecked={checked}
          disabled={disabled}
        />
        {checked && !disabled ? (
          <Icon name="check-on" style={{ fontSize: 21 }} color={colorTheme[themeOption].primary} />
        ) : null}
      </Check>
      {label && (
        <Label disabled={disabled} htmlFor={htmlFor.current}>
          {label}
        </Label>
      )}
    </div>
  );
};
