import * as React from "react";
import { Input } from "./ui/Input";
import styled from "styled-components";
import { CredentialsToken } from "../types/Token";
import { Icon } from "./ui/Icon";
import { colorTheme } from "../theme";
import { ColorThemeContext } from "../providers/ColorThemeProvider";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VisiblePasswordIcon = styled(Icon)<{ color: string }>`
  && {
    color: ${props => props.color};

    &:hover {
      color: ${props => props.color};
    }
  }
`;

type Props = CredentialsToken & {
  updateValue: (key: string, value: any) => void;
};

export const CredentialsTokenEditPanel: React.FC<Props> = ({ username, password, updateValue }) => {
  const [visiblePasswords, setVisibilePassword] = React.useState(false);

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <Wrapper>
      <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 20 }}>
        <div style={{ flexBasis: "50%", paddingRight: 15 }}>
          <Input
            placeholder="Username"
            label="username"
            value={username}
            onChange={e => updateValue("username", e.currentTarget.value)}
            autoComplete="off"
            data-lpignore="true"
          />
        </div>
        <div style={{ flexBasis: "50%", paddingLeft: 15 }}>
          <form autoComplete="off">
            <div style={{ position: "relative" }}>
              <Input
                placeholder="Password"
                label="password"
                value={password}
                onChange={e => updateValue("password", e.currentTarget.value)}
                type={visiblePasswords ? "text" : "password"}
                autoComplete="off"
                data-lpignore="true"
              />
              <VisiblePasswordIcon
                name={visiblePasswords ? "hidden" : "view"}
                style={{ position: "absolute", right: 5, bottom: 5 }}
                color={colorTheme[themeOption].primary}
                onClick={() => setVisibilePassword(show => !show)}
              />
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};
