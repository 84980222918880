import * as React from "react";
import { TextPlaceholder, Input } from "./Placeholder";

export const SelectPlaceholder: React.FC<{ style?: React.CSSProperties }> = ({
  style
}) => {
  return (
    <div style={{ width: "100%", ...style }}>
      <TextPlaceholder width={60} style={{ marginBottom: 10 }} />
      <Input />
    </div>
  );
};
