import * as React from "react";
import { Icon } from "./Icon";
import { Title } from "../../styles";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";

interface Props {
  onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick }) => {
  const { themeOption } = React.useContext(ColorThemeContext);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        left: -14,
        top: -9,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <Icon
        name="arrow"
        style={{ fontSize: 30, transform: "rotate(180deg)" }}
        color={colorTheme[themeOption].text.primary}
      />
      <Title style={{ margin: 0, userSelect: "none" }}>Back</Title>
    </div>
  );
};

export { BackButton };
