import * as React from "react";
import { LateralPanel } from "../ui/LateralPanel";
import { Button } from "../ui/Button";
import { Divider } from "../ui/Divider";
import { FullConfiguration } from "../../types/views";
import { Label, ContentLargePanel } from "../../styles";
import { Toggle } from "../ui/Toggle";
import { RuleInfoPanel } from "../RuleInfoPanel";
import { RuleWithId, ReqType } from "../../types/Configuration";
import { DetailText, getAdditionalInfo } from "./RowDetail";
import { EditRulePanel } from "../AddConfiguration/EditRulePanel";
import { IntervalRuleDetails, DailyRuleDetails, TierRuleDetails, AddRulePanel } from "../AddConfiguration/AddRulePanel";
import { ButtonLink } from "../ui/ButtonLink";
import { NoRuleInfo } from "../AddConfiguration/AddConfigurationPanel";
import { conversionRules } from "../../utilsConfiguration";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";
import { v4 } from "uuid";

interface Props {
  onClose: () => void;
  onSaveChanges: (configuration: FullConfiguration) => void;
  configuration: FullConfiguration;
  openEditPanel: boolean;
  onOpenEditPanel: (open: boolean) => void;
}

const ConfigurationDetailsPanel: React.FC<Props> = ({
  onClose,
  onSaveChanges,
  configuration,
  openEditPanel,
  onOpenEditPanel,
}) => {
  const { themeOption } = React.useContext(ColorThemeContext);

  const rulesWithId = React.useRef<Array<RuleWithId>>(
    configuration.rules.map((r) => ({
      ...r,
      id: v4(),
    }))
  );

  const initialConfiguration: FullConfiguration = {
    ...configuration,
    rules: conversionRules(rulesWithId.current),
  };

  const [openEditRule, setOpenEditRule] = React.useState<boolean>(false);
  const [openAddRule, setOpenAddRule] = React.useState<boolean>(false);
  const [updatedConfiguration, setUpdatedConfiguration] = React.useState<FullConfiguration>(initialConfiguration);
  const [selectedRule, setSelectedRule] = React.useState<RuleWithId | null>(null);

  const rules = updatedConfiguration.rules as Array<RuleWithId>;

  const renderButtonSection = () => {
    if (!openEditPanel) {
      return <Button onClick={() => onOpenEditPanel(true)}>Edit configuration</Button>;
    } else {
      return (
        <>
          <Button
            undo
            onClick={() => {
              setUpdatedConfiguration(initialConfiguration);
              onOpenEditPanel(false);
            }}
          >
            Undo
          </Button>
          <Button onClick={() => onSaveChanges(updatedConfiguration)} style={{ marginLeft: 10 }} disabled={false}>
            Save & close
          </Button>
        </>
      );
    }
  };

  const onApplyRuleChanges = (
    requestType: ReqType,
    ruleDetails: IntervalRuleDetails | DailyRuleDetails | TierRuleDetails
  ) => {
    const updatedRules = rules.map((rule) => {
      if (rule.id === ruleDetails.id) {
        return {
          ...rule,
          ...ruleDetails,
          request: requestType,
        };
      }

      return {
        ...rule,
      };
    });

    const updatedConf: FullConfiguration = { ...updatedConfiguration, rules: updatedRules };
    setUpdatedConfiguration(updatedConf);
    setOpenEditRule(false);
  };

  const addRule = (rule: RuleWithId) => {
    const updatedRules = [rule, ...updatedConfiguration.rules];
    const updatedConf = { ...updatedConfiguration, rules: updatedRules };
    setUpdatedConfiguration(updatedConf);
  };

  return (
    <>
      {openEditRule && selectedRule && (
        <EditRulePanel
          selectedRule={selectedRule}
          onResetRule={(ruleId) => {
            const resetRule = initialConfiguration.rules.find((r) => r.id === ruleId);
            if (resetRule) {
              const resetConfiguration = {
                ...updatedConfiguration,
                rules: updatedConfiguration.rules.map((r) => {
                  if (r.id === resetRule.id) {
                    return resetRule;
                  }
                  return r;
                }),
              };
              setUpdatedConfiguration(resetConfiguration);
            }
          }}
          close={() => {
            setOpenEditRule(false);
          }}
          applyChanges={onApplyRuleChanges}
          openFromConfigurationPanel
        />
      )}
      {openAddRule && (
        <AddRulePanel onAddRule={addRule} close={() => setOpenAddRule(false)} openFromConfigurationPanel />
      )}
      <LateralPanel
        title={openEditPanel ? "Edit configuration" : "Configuration details"}
        onClose={() => (openEditPanel ? onOpenEditPanel(false) : onClose())}
        buttonSection={renderButtonSection()}
      >
        <div style={{ display: "grid", gridTemplateColumns: "33% 33% 33%", marginBottom: 25 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label>Publisher</Label>
            <DetailText>{configuration.publisher.description}</DetailText>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label>Platform</Label>
            <DetailText>{configuration.platform}</DetailText>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Toggle
              checked={updatedConfiguration.active}
              disabled={!openEditPanel}
              label="Active"
              onClick={() =>
                openEditPanel &&
                setUpdatedConfiguration({ ...updatedConfiguration, active: !updatedConfiguration.active })
              }
            />
          </div>
        </div>
        <Divider label="Rules" />
        <ContentLargePanel
          style={{ height: "calc(100% - 235px)", marginTop: rules.length === 0 ? 10 : 20, marginBottom: 30 }}
        >
          {rules.length === 0 && (
            <NoRuleInfo>
              {openEditPanel
                ? `There are no rules here, click "Add rule" to add one`
                : `There are no rules here, click "Edit configuration"`}
            </NoRuleInfo>
          )}
          {rules.map((rule, i) => {
            const editPanelProp = openEditPanel && {
              onEdit: () => {
                setSelectedRule(rule);
                setOpenEditRule(true);
              },
              onDelete: () => {
                const newRules = rules.filter((r) => r.id !== rule.id);
                const updatedConf = { ...updatedConfiguration, rules: newRules };
                setUpdatedConfiguration(updatedConf);
              },
            };

            return (
              <RuleInfoPanel
                key={i}
                rule={rule}
                {...editPanelProp}
                additionalInfo={getAdditionalInfo(rule)}
                widthCalc={openEditPanel ? 145 : 65}
              />
            );
          })}

          {openEditPanel && (
            <ButtonLink
              label="Add rule"
              iconName="add"
              color={colorTheme[themeOption].text.primary}
              onClick={() => setOpenAddRule(true)}
              style={{ marginTop: rules.length === 0 ? 0 : 10 }}
            />
          )}
        </ContentLargePanel>
      </LateralPanel>
    </>
  );
};

export { ConfigurationDetailsPanel };
