import axios from "axios";

const client = axios.create({
  baseURL: process.env.API_BASE_URL
});

export const fetchData = async (url: string) => {
  const result = await client.get(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`
    }
  });

  return result.data;
};

export const mutateData = async (method: "POST" | "PUT" | "DELETE", url: string, data?: any) => {
  const result = await client.request({
    method,
    url,
    data,
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`
    }
  });

  return result.data;
};

export default client;
