import * as React from "react";
import { ItemPlaceholder } from "./ItemPlaceholder";
import styled from "styled-components";
import { TextPlaceholder } from "./Placeholder";
import { Divider } from "../Divider";
import { CountInfo } from "../../../styles";
import { ColorThemeContext } from "../../../providers/ColorThemeProvider";
import { colorTheme } from "../../../theme";

const HeaderPlaceholder = styled.div`
  display: flex;
  margin-left: 68px;
  margin-bottom: 20px;
  width: calc(100% - 55px - 168px);
  padding: 0 18px;
  justify-content: space-between;
`;

const List = styled.div`
  & > div {
    margin-bottom: 5px;
  }

  height: 95%;
  overflow-y: overlay;
  padding: 0px 10px;
`;

export const ListItemsPlaceholder: React.FC = () => {
  const listItem = [...Array(20).keys()];

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <>
      <HeaderPlaceholder>
        <TextPlaceholder width={10} style={{ opacity: "10%" }} />
        <TextPlaceholder width={30} style={{ opacity: "10%" }} />
        <TextPlaceholder width={50} style={{ opacity: "10%" }} />
      </HeaderPlaceholder>
      <List>
        {listItem.map((item, i) => (
          <ItemPlaceholder key={i} />
        ))}
      </List>
      <div>
        <Divider color={colorTheme[themeOption].text.primary} style={{ marginBottom: 3 }} />
        <CountInfo>Searching...</CountInfo>
      </div>
    </>
  );
};
