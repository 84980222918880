import * as React from "react";
import styled from "styled-components";
import { LateralPanel } from "../ui/LateralPanel";
import { PublisherActorStates } from "../../types/Actor";
import { RequestBox } from "./RequestBox";
import { ContentLargePanel } from "../../styles";
import { Divider } from "../ui/Divider";
import { scrollbarLightH } from "../ui/ScrollbarStyle";
import { flatMap } from "lodash";

const Wrapper = styled(ContentLargePanel)`
  && {
    height: calc(100% - 70px);
    font-size: 12px;
    ${scrollbarLightH};

    & > div {
      margin-bottom: 5px;
    }
  }
`;

interface Props {
  selectedPublisherActor: PublisherActorStates;
  onClose: () => void;
}

const ActorStateDetails: React.FC<Props> = ({ onClose, selectedPublisherActor }) => {
  const requests = flatMap(Object.values(selectedPublisherActor));
  const publisherActorWithResponse = requests.filter(pA => pA.numOfRep > 0);
  const allOtherPublisherActor = requests.filter(pA => pA.numOfRep === 0);

  return (
    <LateralPanel onClose={onClose} title="Request details">
      <Wrapper>
        {requests.length === 0 && <span>This actor state hasn't requests!</span>}
        {publisherActorWithResponse.length > 0 && (
          <>
            <Divider label="Error" style={{ marginBottom: 20 }} />
            {publisherActorWithResponse.map(sPa => (
              <RequestBox publisherActorWithResponse={sPa} key={sPa.requestUUID} />
            ))}
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
          </>
        )}

        {allOtherPublisherActor.map(sPa => (
          <RequestBox publisherActorWithResponse={sPa} key={sPa.requestUUID} />
        ))}
      </Wrapper>
    </LateralPanel>
  );
};

export { ActorStateDetails };
