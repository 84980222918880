import * as React from "react";
import { Icon } from "./Icon";
import styled from "styled-components";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { colorTheme } from "../../theme";

const StyledIcon = styled(Icon)<{ selected: boolean }>`
  && {
    font-size: 30px;
    line-height: 25px;

    &:hover {
      color: ${props => props.theme.icon.hover};
      opacity: ${props => (props.selected ? 1 : 0.5)};
    }

    &::before {
      margin: 0;
      line-height: 25px;
      position: relative;
    }
  }
`;

export type Order = "asc" | "desc";

interface Props {
  order: Order;
  show: boolean;
}

const IconOrderBy: React.FC<Props> = ({ show, order }) => {
  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: 50,
        width: 30,
        position: "absolute",
        top: -5
      }}
    >
      {show && (
        <>
          <StyledIcon
            selected={order === "desc"}
            name="order-desc"
            color={
              order === "desc" && show ? colorTheme[themeOption].icon.hover : colorTheme[themeOption].toggle.background
            }
          />
          <StyledIcon
            selected={order === "asc"}
            name="order-asc"
            color={
              order === "asc" && show ? colorTheme[themeOption].icon.hover : colorTheme[themeOption].toggle.background
            }
          />
        </>
      )}
    </div>
  );
};

export { IconOrderBy };
