import * as React from "react";
import styled from "styled-components";
import { EmptyBoxSvg } from "./EmptyBoxSvg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
`;

const InfoMessage = styled.p`
  font-size: 20px;
  font-family: ${props => props.theme.fontFamily.monospace};
  color: ${props => props.theme.primary};
  line-height: 30px;
`;

export const NoItemFound: React.FC<{ missingElement: string }> = ({ missingElement }) => {
  return (
    <Wrapper>
      <EmptyBoxSvg />
      <div style={{ marginLeft: 30, position: "relative", top: 40 }}>
        <InfoMessage>Ops,</InfoMessage>
        <InfoMessage>no {missingElement} found!</InfoMessage>
      </div>
    </Wrapper>
  );
};
