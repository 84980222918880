import "../assets/fontello/css/scheduler-icon.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { App } from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ColorThemeProvider } from "./providers/ColorThemeProvider";
import auth from "./auth";

if (process.env.NODE_ENV === "production") {
  Sentry.init({ environment: process.env.NODE_ENV, dsn: "https://9ff81ff1197347c4b0a6d47c34b08860@sentry.io/2070373" });

  Sentry.configureScope(function(scope) {
    const username = localStorage.getItem("username");
    if (username) {
      scope.setUser({ username });
    }
  });
}

(async () => {
  await auth.renewToken();
  ReactDOM.render(
    <Router>
      <ColorThemeProvider>
        <App />
      </ColorThemeProvider>
    </Router>,
    document.getElementById("root")
  );
})();
