import * as React from "react";
import styled from "styled-components";
import { Icon } from "./Icon";
import { TextDialog } from "./Dialog";
import { SnackbarContext } from "../../providers/SnackbarProvider";
import { CSSTransition } from "react-transition-group";
import { useKeyUpEsc } from "../../hooks/useKeyUpEsc";
import { ThemeOption, colorTheme } from "../../theme";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";
import { TransitionStatus } from "react-transition-group/Transition";
import { IconNames } from "../../types/IconNames";

const Wrapper = styled.div`
  min-width: 230px;
  max-width: 340px;
  background: ${props => props.theme.background.portal};
  position: fixed;
  top: 85px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 10px;
  z-index: 30;

  transition: right 0.4s cubic-bezier(0.56, -0.165, 0.35, 1.2);

  &.wrapper-entering {
    right: -350px;
  }
  &.wrapper-entered {
    right: 20px;
  }
  &.wrapper-exiting {
    right: -350px;
  }
  &.wrapper-exited {
    right: -350px;
  }
`;

export type Typology = "error" | "notification" | "caution";

const generateIcon = (messageType: Typology, themeOption: ThemeOption): { name: IconNames; color: string } => {
  if (messageType === "error") {
    return { name: "remove", color: colorTheme[themeOption].error };
  }
  if (messageType === "caution") {
    return { name: "snackbar-caution", color: colorTheme[themeOption].caution };
  }

  return { name: "snackbar-ok", color: colorTheme[themeOption].primary };
};

interface Props {
  open: boolean;
  onClose: () => void;
  typology?: Typology;
  message: string;
  transitionStatus: TransitionStatus;
}

const Snackbar: React.FC<Props> = ({ open, onClose, typology, message, transitionStatus }) => {
  useKeyUpEsc(onClose);
  const { themeOption } = React.useContext(ColorThemeContext);

  React.useEffect(() => {
    document.body.addEventListener("click", onClose, true);
    return () => document.body.removeEventListener("click", onClose, true);
  }, []);

  const realTypology = typology ?? "notification";

  return (
    <Wrapper className={`wrapper-${transitionStatus}`} onClick={(e: any) => e.stopPropagation()}>
      <Icon
        name={generateIcon(realTypology, themeOption)?.name}
        color={generateIcon(realTypology, themeOption)?.color}
      />
      <TextDialog style={{ marginLeft: 5 }}>{message}</TextDialog>
    </Wrapper>
  );
};

const FeedbackSnackbar: React.FC = () => {
  const { open, onClose, message, typology } = React.useContext(SnackbarContext);
  return (
    <CSSTransition in={open} timeout={300} unmountOnExit>
      {status => (
        <Snackbar open={open} onClose={onClose} typology={typology} message={message} transitionStatus={status} />
      )}
    </CSSTransition>
  );
};

export { FeedbackSnackbar };
