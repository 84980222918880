import * as React from "react";
import { PanelWrapper, Title, Label } from "../styles";
import { SelectStyled } from "./ui/Select";
import { Button } from "./ui/Button";
import { Platform, toStringPlatform } from "../types/Platform";
import { ConfigurationFilterContext } from "../providers/ConfigurationFilterProvider";
import { ActiveFilter } from "./ActiveFilter";

const ConfigurationFilter: React.FC = () => {
  const { platform, setPlatform, active, setActive, clearFilter } = React.useContext(ConfigurationFilterContext);

  return (
    <PanelWrapper>
      <Title>Filter</Title>
      <SelectStyled
        label="platform"
        placeholder="Select platform"
        value={platform && { value: platform, label: toStringPlatform(platform) }}
        onChange={(item: any) => setPlatform(item.value)}
        options={Object.keys(Platform).map(p => ({ value: p, label: toStringPlatform(p as Platform) }))}
        style={{ marginBottom: 20 }}
      />
      <Label htmlFor="active">Active</Label>
      <ActiveFilter selectedOption={active} setSelectedOption={setActive} />
      <Button style={{ marginTop: 30, alignSelf: "flex-end" }} onClick={clearFilter}>
        Clear filter
      </Button>
    </PanelWrapper>
  );
};

export { ConfigurationFilter };
