import * as React from "react";
import { PanelWrapper, Title } from "../styles";
import { SelectStyled } from "./ui/Select";
import { Button } from "./ui/Button";
import { ButtonLink } from "./ui/ButtonLink";
import { useHistory } from "react-router-dom";
import auth from "../auth";
import { ColorThemeContext } from "../providers/ColorThemeProvider";

export const UserPanel: React.FC = () => {
  const history = useHistory();
  const { themeOption, setThemeOption } = React.useContext(ColorThemeContext);

  const themeOptions = [
    { value: "darkTheme", label: "Dark theme" },
    { value: "lightTheme", label: "Light theme" }
  ];

  const selectedTheme = themeOptions.find(item => item.value === themeOption);

  return (
    <PanelWrapper>
      <Title>Hi, {localStorage.name}!</Title>
      <SelectStyled
        label="Theme"
        placeholder="Select platform"
        value={selectedTheme ? { value: selectedTheme.value, label: selectedTheme.label } : null}
        onChange={(item: any) => setThemeOption(item.value)}
        options={themeOptions}
      />
      <Button style={{ marginTop: 30, alignSelf: "flex-end" }} onClick={() => setThemeOption("darkTheme")}>
        Restore default
      </Button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "fixed",
          bottom: 0,
          marginBottom: 20
        }}
      >
        <ButtonLink
          underline
          label="Sign out"
          iconName="logout"
          onClick={() => {
            auth.logout();
            history.push("/login");
          }}
        />
      </div>
    </PanelWrapper>
  );
};
