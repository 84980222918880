import * as React from "react";
import { Typology } from "../components/ui/Snackbar";

type State = {
  open: boolean;
  message: string;
  typology: Typology;
};

type ContextS = State & {
  onOpenSnackbar: (message: string, typology?: Typology) => void;
  onClose: () => void;
};

const SnackbarContext = React.createContext<ContextS>({
  open: false,
  message: "Lorem ipsum",
  typology: "notification",
  onOpenSnackbar: (message: string, typology?: Typology) => console.log("Please implement SnackbarProvider"),
  onClose: () => console.log("Please implement SnackbarProvider")
});

const SnackbarProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<State>({
    open: false,
    message: "",
    typology: "notification"
  });

  const onOpenSnackbar = (message: string, typology?: Typology) =>
    setState({ open: true, message, typology: typology ? typology : "notification" });

  const onClose = () => setState(state => ({ ...state, open: false }));

  return <SnackbarContext.Provider value={{ ...state, onOpenSnackbar, onClose }}>{children}</SnackbarContext.Provider>;
};

export { SnackbarProvider, SnackbarContext };
