import * as React from "react";
import styled, { css } from "styled-components";
import { Icon } from "./Icon";

export const Chip = styled.div<{ selected: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.selected ? props.theme.chip.selected : props.theme.chip.default)};
  color: ${props => props.theme.chip.text};
  min-width: 110px;
  padding: 12px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-family: ${props => props.theme.fontFamily.monospace};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  :hover {
    background: ${props => {
      const bkgColor = props.disabled ? props.theme.chip.default : props.theme.chip.hover;
      return props.selected ? props.theme.chip.selected : bkgColor;
    }};
  }
`;

const MiniChip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.chip.hover};
  color: ${props => props.theme.chip.text};
  min-width: 110px;
  padding: 10px;
  border-radius: 12px;
  font-size: 12px;
  font-family: ${props => props.theme.fontFamily.monospace};
  padding-right: 40px;
  position: relative;
  height: 36px;
`;

interface Props {
  onRemove: () => void;
  label: string;
}

export const RemovedChip: React.FC<Props> = ({ onRemove, label }) => {
  return (
    <MiniChip>
      {label}
      <Icon name="remove" style={{ position: "absolute", right: 0, bottom: 2 }} onClick={onRemove} />
    </MiniChip>
  );
};
