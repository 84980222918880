import * as React from "react";
import { v4 } from "uuid";
import { capitalize, set } from "lodash";
import styled from "styled-components";

import { LargePanelWrapper, ContentLargePanel } from "../../styles";
import { TitleAndClose } from "../ui/TitleAndClose";
import {
  RuleWithId,
  isScheduleIntervalConfiguration,
  isScheduleDailyConfiguration,
  isScheduleTiersConfiguration,
  getRequestType,
  ReqType,
  TimeUnit,
  TierWithId,
} from "../../types/Configuration";
import { Button } from "../ui/Button";
import { SelectStyled } from "../ui/Select";
import { RuleType } from "../../types/RuleType";
import { Divider } from "../ui/Divider";
import { IntervalRuleDetails, DailyRuleDetails, TierRuleDetails } from "./AddRulePanel";
import { FiniteDurationPanel } from "../ui/FiniteDurationPanel";
import { DateField } from "../ui/DateField";
import { EditTiersRulePanel } from "./EditTiersRulePanel";
import { TierType } from "../../types/TierType";
import { getDisabledButtonAddEditRule } from "../../utilsConfiguration";
import { colorTheme } from "../../theme";
import { ColorThemeContext } from "../../providers/ColorThemeProvider";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

interface Props {
  selectedRule: RuleWithId;
  close: () => void;
  onResetRule?: (ruleId: string) => void;
  applyChanges: (requestType: ReqType, ruleDetails: IntervalRuleDetails | DailyRuleDetails | TierRuleDetails) => void;
  openFromConfigurationPanel?: boolean;
}

const getRuleDetails = (rule: RuleWithId) => {
  if (isScheduleDailyConfiguration(rule)) {
    return {
      ...rule,
      dailyAt: rule.dailyAt,
    };
  }
  if (isScheduleTiersConfiguration(rule)) {
    const tiers = rule.tiers as Array<TierWithId>;

    return {
      ...rule,
      tiers,
    };
  }
  // Default interval rule
  return {
    ...rule,
    interval: rule.interval,
  };
};

const EditRulePanel: React.FC<Props> = ({
  selectedRule,
  close,
  onResetRule,
  applyChanges,
  openFromConfigurationPanel,
}) => {
  const initialRuleType = getRequestType(selectedRule);
  const initialRuleDetails = getRuleDetails(selectedRule);

  const { themeOption } = React.useContext(ColorThemeContext);

  const [disabledButtonChanges, setDisabledButtonChanges] = React.useState(false);

  const [ruleType, setRuleType] = React.useState<RuleType | string>(initialRuleType);
  const [requestType, setRequestType] = React.useState<ReqType>(selectedRule.request);
  const [ruleDetails, setRuleDetails] = React.useState<IntervalRuleDetails | DailyRuleDetails | TierRuleDetails>(
    initialRuleDetails
  );

  const updateRuleDetails = (key: string, value: any) => {
    setRuleDetails((ruleDetails) => {
      return {
        ...ruleDetails,
        ...set(ruleDetails, key, value),
      };
    });
  };

  const removeTier = (tierId: string) => {
    if (isScheduleTiersConfiguration(ruleDetails)) {
      const tiers = ruleDetails.tiers as Array<TierWithId>;

      setRuleDetails((ruleDetails) => {
        return {
          ...ruleDetails,
          tiers: tiers.filter((t) => t.id !== tierId),
        };
      });
    }
  };

  const updateTier = (tierId: string, key: string, value: any) => {
    if (isScheduleTiersConfiguration(ruleDetails)) {
      const tiers = ruleDetails.tiers as Array<TierWithId>;

      setRuleDetails((ruleDetails) => {
        return {
          ...ruleDetails,
          tiers: tiers.map((t) => (t.id === tierId ? { ...set(t, key, value) } : t)),
        };
      });
    }
  };

  React.useEffect(() => {
    const rule = ruleDetails as RuleWithId;
    const disabled = getDisabledButtonAddEditRule(rule);

    setDisabledButtonChanges(disabled);
  }, [ruleDetails]);

  const changeTierType = (id: string, tierType: TierType) => {
    if (isScheduleTiersConfiguration(ruleDetails)) {
      const tiers = ruleDetails.tiers as Array<TierWithId>;

      const fn = (tier: TierWithId) => {
        if (id === tier.id) {
          if (tierType === TierType.DAILY) {
            return {
              id,
              contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
              contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
              chunkInterval: { length: 0, unit: TimeUnit.DAYS },
              dailyAt: "",
              maxAvailableInterval: { length: 0, unit: TimeUnit.DAYS },
            };
          }

          if (tierType === TierType.CHUNK) {
            return {
              id,
              contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
              contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
              chunkInterval: { length: 0, unit: TimeUnit.DAYS },
              interval: { length: 0, unit: TimeUnit.DAYS },
              maxAvailableInterval: { length: null, unit: null },
            };
          }

          if (tierType === TierType.INTERVAL) {
            return {
              id,
              contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
              contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
              interval: { length: 0, unit: TimeUnit.DAYS },
            };
          }
        }

        return tier;
      };

      setRuleDetails((ruleDetails) => {
        return {
          ...ruleDetails,
          tiers: tiers.map(fn),
        };
      });
    }
  };

  const addTier = () => {
    if (isScheduleTiersConfiguration(ruleDetails)) {
      const tiers = ruleDetails.tiers as Array<TierWithId>;
      setRuleDetails((ruleDetails) => {
        return {
          ...ruleDetails,
          tiers: [
            ...tiers,
            {
              id: v4(),
              contentLifeSince: { length: 0, unit: TimeUnit.DAYS },
              contentLifeUntil: { length: 0, unit: TimeUnit.DAYS },
              interval: { length: 0, unit: TimeUnit.DAYS },
            },
          ],
        };
      });
    }
  };

  const editComponent = () => {
    if (isScheduleIntervalConfiguration(ruleDetails)) {
      return (
        <Wrapper>
          <FiniteDurationPanel
            duration={{ length: ruleDetails.interval.length, unit: ruleDetails.interval.unit }}
            onChangeUnit={(value) => updateRuleDetails("interval.unit", value)}
            onChangeLength={(value) => updateRuleDetails("interval.length", value)}
          />
        </Wrapper>
      );
    }
    if (isScheduleDailyConfiguration(ruleDetails)) {
      return (
        <Wrapper>
          <DateField
            label="Daily at"
            onChange={(e) => updateRuleDetails("dailyAt", e.currentTarget.value)}
            value={ruleDetails.dailyAt}
            wrapperStyle={{ width: "50%", paddingRight: 15 }}
            type="time"
          />
        </Wrapper>
      );
    }
    if (isScheduleTiersConfiguration(ruleDetails)) {
      const tiers = ruleDetails.tiers as Array<TierWithId>;

      return (
        <EditTiersRulePanel
          tiers={tiers}
          onRemoveTier={removeTier}
          onUpdateTier={updateTier}
          onChangeTierType={changeTierType}
          onAddTier={addTier}
          selectedRule={selectedRule}
          disabledButtonAdd={disabledButtonChanges}
        />
      );
    }
    return <div>Unknown rule type O_o</div>;
  };

  const additionalStyle = openFromConfigurationPanel && { zIndex: 25, right: 0, top: 65, marginTop: 3 };

  return (
    <LargePanelWrapper
      style={{
        background: colorTheme[themeOption].background.secondary,
        position: "fixed",
        zIndex: 5,
        ...additionalStyle,
      }}
    >
      <TitleAndClose title="Edit rule" onClose={close} />
      <ContentLargePanel>
        <div style={{ display: "flex", marginBottom: 20 }}>
          <SelectStyled
            label="Rule type"
            placeholder="Select rule type"
            value={{ value: ruleType, label: capitalize(ruleType) }}
            onChange={(item: any) => setRuleType(item.value)}
            options={[]}
            style={{ flexBasis: "50%", paddingRight: 15 }}
            disabled
          />
          <SelectStyled
            label="Request type"
            placeholder="Select request type"
            value={{ value: requestType, label: requestType }}
            onChange={(item: any) => setRequestType(item.value)}
            options={Object.values(ReqType).map((rT) => ({ value: rT, label: rT }))}
            style={{ flexBasis: "50%", paddingLeft: 15 }}
          />
        </div>
        <Divider />
        {editComponent()}
      </ContentLargePanel>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-end",
          position: "fixed",
          bottom: 0,
          marginBottom: 20,
        }}
      >
        {openFromConfigurationPanel && (
          <Button
            undo
            onClick={() => {
              onResetRule && onResetRule(selectedRule.id);
              close();
            }}
          >
            Undo
          </Button>
        )}
        <Button
          onClick={() => applyChanges(requestType, ruleDetails)}
          style={{ marginLeft: 10 }}
          disabled={disabledButtonChanges}
        >
          Apply changes
        </Button>
      </div>
    </LargePanelWrapper>
  );
};

export { EditRulePanel };
