import * as React from "react";
import useSWR from "swr";

import {
  TokenRecord,
  isGraphApiToken,
  isGoogleApiToken,
  isCredentialsToken,
  AvailableToken,
  toStringTokenType,
  getTokenTypeNameByToken,
  TokenType,
  isApiKeyToken,
  isTikTokToken,
  isTikTokMarketingToken
} from "../types/Token";
import { fetchData } from "../client";
import { ItemsPanel } from "../components/ItemsPanel";
import { ActiveIndicator } from "../components/ui/ActiveIndicator";
import { NoItemFound } from "../components/ui/NoItemFound";
import { ListItemsPlaceholder } from "../components/ui/Placeholder/ListItems";
import { ErrorPage } from "../components/ui/ErrorPage";
import { TokenAdditionalInfo } from "../components/TokenAdditionalInfo";
import { DialogEditToken } from "../components/DialogEditToken";
import { TokenFilterContext } from "../providers/TokenFilterProvider";
import { PublisherRecord } from "../types/Publisher";
import { includes, orderBy, capitalize } from "lodash";
import { Order } from "../components/ui/IconOrderBy";
import { useHighlightLastAddedItem, comparatorById } from "../hooks/useHighlightLastAddedItem";
import { DialogBindToken } from "../components/DialogBindToken";

const columns = [
  { accessor: "id", label: "Id", percentage: 10, sortable: true },
  { accessor: "description", label: "Description", percentage: 25, sortable: true },
  { accessor: "tokenType", label: "Token type", percentage: 25, sortable: true },
  { accessor: "publishers", label: "Publishers", percentage: 30, sortable: false },
  { accessor: "active", percentage: 10, sortable: false },
];

const TokensPanelContainer: React.FC = () => {
  const {
    description,
    active,
    tokenType,
    publisherId,
    order,
    setOrder,
    orderByLabel,
    setOrderByLabel,
    clearFilter,
  } = React.useContext(TokenFilterContext);

  const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
  const [openBindDialog, setOpenBindDialog] = React.useState<boolean>(false);
  const [selectedToken, setSelectedToken] = React.useState<
    (TokenRecord & { publishers: Array<PublisherRecord> }) | null
  >(null);

  const orderedTokens = React.useRef<Array<TokenRecord & { publishers: Array<PublisherRecord> }>>([]);

  const { data: tokenWithPublishersData, error: tokenWithPublishersError } = useSWR<
    Array<TokenRecord & { publishers: Array<PublisherRecord> }>
  >("/token-with-publishers", async () => {
    const tokenData: { tokens: Array<TokenRecord> } = await fetchData("/tokens");

    return Promise.all(
      tokenData.tokens.map(async (item) => {
        const id = item.id;
        const publishersData = await fetchData(`/publishers?tokenId=${id}`);
        return { ...item, publishers: publishersData.publishers };
      })
    );
  });

  const { lastAddedItemId } = useHighlightLastAddedItem(tokenWithPublishersData, comparatorById, ["id"]);

  React.useEffect(() => {
    const tokenExist = orderedTokens.current.find((p) => lastAddedItemId[0] === p.id);

    if (!tokenExist) {
      clearFilter();
    }
  }, [lastAddedItemId]);

  if (tokenWithPublishersError) {
    return <ErrorPage />;
  }

  if (!tokenWithPublishersData) {
    return <ListItemsPlaceholder />;
  }

  const tokenRecords: Array<
    TokenRecord & {
      publishers: Array<PublisherRecord>;
    }
  > = tokenWithPublishersData.filter((t) => {
    let ok = true;

    if (description) {
      ok = ok && t.description.toLowerCase().includes(description.toLowerCase());
    }

    if (active !== "all") {
      if (active === "active") {
        ok = ok && t.active;
      } else {
        ok = ok && !t.active;
      }
    }

    if (tokenType) {
      if (tokenType === TokenType.tiktokMarketing) {
        ok = ok && isTikTokMarketingToken(t.token) && !isGraphApiToken(t.token);
      }
      if (tokenType === TokenType.graph) {
        ok = ok && isGraphApiToken(t.token);
      }
      if (tokenType === TokenType.google) {
        ok = ok && isGoogleApiToken(t.token);
      }
      if (tokenType === TokenType.credentials) {
        ok = ok && isCredentialsToken(t.token);
      }
      if (tokenType === TokenType.apiKey) {
        ok = ok && isApiKeyToken(t.token);
      }
      if (tokenType === TokenType.tiktok) {
        ok = ok && isTikTokToken(t.token);
      }
     
    }

    if (publisherId) {
      ok =
        ok &&
        includes(
          t.publishers.map((p) => p.publisherId),
          publisherId
        );
    }

    return ok;
  });

  if (orderByLabel && order) {
    if (orderByLabel === "tokenType") {
      const tokensWithType = tokenRecords.map((t) => {
        return {
          ...t,
          tokenType: getTokenTypeNameByToken(t.token),
        };
      });

      orderedTokens.current = orderBy(tokensWithType, orderByLabel, order);
    } else {
      orderedTokens.current = orderBy(
        tokenRecords.map((t) => ({ ...t, description: capitalize(t.description) })),
        orderByLabel,
        order
      );
    }
  } else {
    orderedTokens.current = tokenRecords;
  }

  if (tokenRecords.length === 0) {
    return <NoItemFound missingElement="token" />;
  }

  const onCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedToken(null);
  };
  const onCloseBindDialog = () => {
    setOpenBindDialog(false);
    setSelectedToken(null);
  };

  return (
    <>
      {openEditDialog && selectedToken && (
        <DialogEditToken open={openEditDialog} onClose={onCloseEditDialog} selectedToken={selectedToken} />
      )}
      {openBindDialog && selectedToken && (
        <DialogBindToken open={openBindDialog} onClose={onCloseBindDialog} selectedToken={selectedToken} />
      )}
      <ItemsPanel
        columns={columns}
        idField="id"
        items={orderedTokens.current.map((t) => ({
          ...t,
          tokenType: getTokenTypeNameByToken(t.token),
          active: (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ActiveIndicator active={t.active} />
            </div>
          ),
          publishers: t.publishers.map((p) => p.description).join(", "),
          new: Boolean(lastAddedItemId.find((tokenId) => Number(tokenId) === t.id)),
        }))}
        onEdit={(token) => {
          const selectedT = orderedTokens.current.find((t) => t.id === token.id);
          if (selectedT) {
            setSelectedToken(selectedT);
            setOpenEditDialog(true);
          }
        }}
        onBind={(token) => {
          const selectedT = orderedTokens.current.find((t) => t.id === token.id);
          if (selectedT) {
            setSelectedToken(selectedT);
            setOpenBindDialog(true);
          }
        }}
        renderAdditionalInfo={(tokenRecord: TokenRecord) => <TokenAdditionalInfo tokenRecord={tokenRecord} />}
        order={order}
        orderByLabel={orderByLabel}
        onChangeOrderBy={(orderBy: string) => setOrderByLabel(orderBy)}
        onChangeOrder={(order: Order) => setOrder(order)}
      />
    </>
  );
};

export { TokensPanelContainer };
