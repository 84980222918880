import * as React from "react";
import { Input } from "./ui/Input";
import styled from "styled-components";
import { DateField } from "./ui/DateField";
import { TikTokMarketingToken } from "../types/Token";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = TikTokMarketingToken & {
  updateValue: (key: string, value: any) => void;
};

export const TikTokTokenMarketingEditPanel: React.FC<Props> = ({
  token,
  appId,
  expiryDate,
  updateValue,


}) => (
  <Wrapper>
    <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 20 }}>
      <div style={{ flexBasis: "50%", paddingRight: 15 }}>
        <Input
          placeholder="Token"
          label="token"
          value={token}
          onChange={(e) => updateValue("token", e.currentTarget.value)}
        />
      </div>
      
      <div style={{ flexBasis: "50%", paddingRight: 15, marginTop: 25 }}>
        <Input
          placeholder="App id"
          label="app id"
          value={appId}
          onChange={(e) => updateValue("appId", e.currentTarget.value)}
        />
      </div>
     
      <div style={{ width: "50%", paddingRight: 15, marginTop: 25 }}>
        <DateField
          label="Expiry date"
          onChange={(e) => {
            updateValue("expiryDate", e.currentTarget.value);
          }}
          value={expiryDate}
        />
      </div>
    </div>
  </Wrapper>
);
