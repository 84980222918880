import * as React from "react";
import styled from "styled-components";
import { TextPlaceholder, IconPlaceholder } from "./Placeholder";

const Wrapper = styled.div`
  width: 200px;
  height: 193px;
  position: relative;
  top: 10px;
  display: flex;
  background: var(--black);
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  padding: 20px 0px;
  opacity: 20%;
`;

const InfoWrapper = styled.div`
  width: 100%;
  background: var(--medium-grey);
  border-radius: 12px;
  padding: 20px;
  z-index: 1;
  opacity: 55%;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
`;

export const ConfigurationPlaceholder: React.FC = () => {
  return (
    <Wrapper>
      <IconWrapper>
        <IconPlaceholder />
        <IconPlaceholder />
      </IconWrapper>
      <InfoWrapper>
        <TextPlaceholder width={80} style={{ marginBottom: 10, width: 110 }} />
        <TextPlaceholder width={50} style={{ marginTop: 10, width: 70 }} />
      </InfoWrapper>
      <IconWrapper>
        <IconPlaceholder />
        <IconPlaceholder />
        <IconPlaceholder />
        <IconPlaceholder />
      </IconWrapper>
    </Wrapper>
  );
};
