import * as React from "react";

import client, { mutateData } from "../client";
import { Dialog, DialogableProps } from "./ui/Dialog";
import { SelectStyled } from "./ui/Select";
import { toStringPlatform, Platform } from "../types/Platform";
import { Toggle } from "./ui/Toggle";
import { PublisherRecord } from "../types/Publisher";
import { orderBy, capitalize } from "lodash";
import { Button } from "./ui/Button";
import { FullConfiguration } from "../types/views";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { cloneConfigurationSuccess, cloneConfigurationError } from "../messages";
import { trigger } from "swr";
import { Rule } from "../types/Configuration";

type Props = DialogableProps & {
  publishers: Array<PublisherRecord>;
  selectedRules: Array<Rule>;
};

const ConfigurationCloneDialog: React.FC<Props> = ({ open, onClose, publishers, selectedRules }) => {
  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const [loadingClone, setLoadingClone] = React.useState<boolean>(false);

  const [platform, setPlatform] = React.useState<Platform | null>(null);
  const [publisherId, setPublisherId] = React.useState<string | null>(null);
  const [active, setActive] = React.useState(true);

  const publisherRecords = publishers.filter(p => {
    let ok = true;

    if (platform !== null) {
      ok = ok && p.platform === platform;
    }

    return ok;
  });

  const publisherOptions = orderBy(
    publisherRecords.map(p => ({
      value: p.publisherId,
      label: capitalize(p.description)
    })),
    "label"
  );

  const onCloneConfiguration = async () => {
    try {
      setLoadingClone(true);

      await mutateData("POST", "/configurations", {
        publisherId,
        platform,
        active,
        rules: selectedRules
      });

      onOpenSnackbar(cloneConfigurationSuccess);
      trigger("/configurations");

      onClose();
    } catch (err) {
      setLoadingClone(false);
      onOpenSnackbar(cloneConfigurationError, "error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleDialog="Clone Configuration"
      buttonSection={
        <>
          <Button onClick={onClose} undo>
            Undo
          </Button>
          <Button
            onClick={() => platform && publisherId && onCloneConfiguration()}
            style={{ marginLeft: 10 }}
            loading={loadingClone}
            disabled={!platform || !publisherId}
          >
            Create
          </Button>
        </>
      }
    >
      <SelectStyled
        label="platform"
        placeholder="Select platform"
        value={platform && { value: platform, label: toStringPlatform(platform) }}
        onChange={(item: any) => setPlatform(item.value)}
        options={Object.keys(Platform).map(p => ({ value: p, label: toStringPlatform(p as Platform) }))}
        style={{ marginBottom: 20 }}
      />
      <SelectStyled
        label="Publisher"
        placeholder="Select publisher"
        value={publisherOptions.find(p => p.value === publisherId) || null}
        onChange={(item: any) => {
          setPublisherId(item ? item.value : null);
        }}
        options={publisherOptions}
        style={{ marginBottom: 20 }}
        isClearable
        isSearchable
      />
      <Toggle
        style={{ marginBottom: 20 }}
        label="active"
        checked={active}
        onClick={() => setActive(!active)}
        size="medium"
      />
    </Dialog>
  );
};

export { ConfigurationCloneDialog };
