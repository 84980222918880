import * as React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Input } from "./components/ui/Input";
import { Button } from "./components/ui/Button";
import { ActionTitle } from "./components/ui/ActionTitle";
import { Logo } from "./components/ui/Logo";
import { ColorThemeContext } from "./providers/ColorThemeProvider";
import { colorTheme } from "./theme";

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  z-index: -1;
  width: auto;
  height: 100vh;
  position: absolute;
  right: 0px;
`;

const LoginBox = styled.form`
  width: 275px;
  height: auto;
  background: ${props => props.theme.background.portal};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  left: 50px;
`;

const Title = styled.h2`
  font-family: ${props => props.theme.fontFamily.monospace};
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-top: 10px;
  user-select: none;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.error};
  font-size: 10px;
`;

const LoginPage: React.FC<{ login: Function }> = ({ login }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const history = useHistory();

  const { themeOption } = React.useContext(ColorThemeContext);

  return (
    <>
      <Wrapper>
        <LogoWrapper>
          <Logo size={58} />
          <Title>Scheduler</Title>
        </LogoWrapper>
        <LoginBox
          onSubmit={async e => {
            e.preventDefault();
            setLoading(true);
            const result = await login(username, password);
            setLoading(false);

            if (result.result === "OK") {
              history.push("/");
            } else {
              setError(true);
            }
          }}
        >
          <ActionTitle title="Login" />
          <Input
            value={username}
            onChange={e => {
              setUsername(e.currentTarget.value);
              setError(false);
            }}
            label="Username"
            placeholder="Username"
            wrapperStyle={{ marginBottom: 25 }}
          />
          <Input
            value={password}
            onChange={e => {
              setPassword(e.currentTarget.value);
              setError(false);
            }}
            label="Password"
            placeholder="Password"
            type="password"
          />
          <div
            style={{
              display: "flex",
              justifyContent: error ? "space-between" : "flex-end",
              alignItems: "center",
              marginTop: 30
            }}
          >
            {error && <ErrorMessage>Ehy, wrong username or password. Please, try again!</ErrorMessage>}
            <Button loading={loading} type="submit">
              Login
            </Button>
          </div>
        </LoginBox>
        <Image src={colorTheme[themeOption].loginBackground} />
      </Wrapper>
    </>
  );
};

export { LoginPage };
