import * as React from "react";
import { useKeyUpEsc } from "../../hooks/useKeyUpEsc";
import { BackgroundOverlay, LargePanelWrapper } from "../../styles";
import styled from "styled-components";
import { TitleAndClose } from "./TitleAndClose";
import { TransitionStatus } from "react-transition-group/Transition";

const Wrapper = styled.div`
  width: 410px;
  height: calc(100% - 65px);
  background: ${props => props.theme.background.secondary};
  margin-top: 3px;
  position: fixed;
  z-index: 20;
  right: 0;
  top: 65px;

  transition: right 0.3s;

  &.wrapper-entering {
    right: -415px;
  }
  &.wrapper-entered {
    right: 0;
  }
  &.wrapper-exiting {
    right: -415px;
  }
  &.wrapper-exited {
    right: -415px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  position: fixed;
  bottom: 0px;
  margin-bottom: 20px;
`;

interface Props {
  onClose: () => void;
  title: string;
  buttonSection?: JSX.Element;
  transitionStatus?: TransitionStatus;
}

export const LateralPanel: React.FC<Props> = ({ onClose, title, children, buttonSection, transitionStatus }) => {
  useKeyUpEsc(onClose);

  return (
    <>
      <Wrapper className={`wrapper-${transitionStatus}`}>
        <LargePanelWrapper>
          <TitleAndClose title={title} onClose={onClose} />
          {children}
          <ButtonWrapper>{buttonSection}</ButtonWrapper>
        </LargePanelWrapper>
      </Wrapper>
      <BackgroundOverlay onClick={onClose} style={{ zIndex: 10 }} />
    </>
  );
};
