import * as React from "react";
import { usePreviousData } from "./usePreviousData";
import { differenceWith, pick } from "lodash";
import { PublisherRecord } from "../types/Publisher";
import { TokenRecord } from "../types/Token";

export const publisherComparator = (a: PublisherRecord, b: PublisherRecord) => {
  return a.publisherId === b.publisherId && a.platform === b.platform;
};

export const comparatorById = <T extends any>(a: T, b: T) => {
  return a.id === b.id;
};

type Comparator = (a: any, b: any) => boolean;

const buildId = <T extends any>(idFields: Array<keyof T>) => (item: T) => Object.values(pick(item, idFields)).join("_");

const useHighlightLastAddedItem = <T extends any>(
  data: Array<T> | undefined,
  customComparator: Comparator,
  idFields: Array<keyof T>
) => {
  const emptyLastAddedItems = React.useRef<NodeJS.Timeout>();

  const [lastAddedItemId, setLastAddedItemId] = React.useState<Array<string | number>>([]);

  const prevDataFetch: Array<T> = usePreviousData(data);

  React.useEffect(() => {
    if (data && prevDataFetch) {
      const lastAddedItem = differenceWith(data, prevDataFetch, customComparator).map(buildId(idFields));

      if (lastAddedItem.length > 0) {
        setLastAddedItemId(lastItem => [...lastAddedItem, ...lastItem]);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (data && lastAddedItemId.length > 0) {
      emptyLastAddedItems.current = setTimeout(() => {
        setLastAddedItemId([]);
      }, 8000);

      const newAddedItemId = data.map(buildId(idFields)).find(itemId => itemId === lastAddedItemId[0]);

      if (newAddedItemId) {
        const itemDiv = document.getElementById(newAddedItemId);

        itemDiv?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [lastAddedItemId]);

  React.useEffect(() => {
    return () => {
      if (emptyLastAddedItems.current) {
        clearTimeout(emptyLastAddedItems.current);
      }
    };
  }, []);

  return { lastAddedItemId };
};

export { useHighlightLastAddedItem };
